import React from 'react';
import Modal from 'react-bootstrap/Modal'
import Button from 'react-bootstrap/Button'
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimes } from "@fortawesome/pro-light-svg-icons";

class PopUp extends React.Component {
    render() {
        let size = (this.props.size ? this.props.size : 'sm');

        return (
            <>
                <Modal className={this.props.className} size={size} show={this.props.open} onHide={this.props.close}>
                    <Modal.Header>
                        {this.props.header && 
                            <>
                                {this.props.header}
                            </>
                        }
                        <Button className="rounded-circle close-button" variant="light" onClick={this.props.close}>
                            <FontAwesomeIcon icon={faTimes} />
                        </Button>
                    </Modal.Header>

                    {this.props.content &&
                        <Modal.Body>
                            {this.props.content}
                        </Modal.Body>
                    }
                </Modal>
            </>
        );
    }
}

export default PopUp;
import React, { useState, useEffect } from 'react';
import { DropdownButton, Dropdown } from "react-bootstrap";

import Loading from "../Loading";

import Api from "../../Api";

const CategorySelector = props => {
    const [loading, setLoading] = useState(true);
    const [categories, setCategories] = useState([]);

    useEffect(() => {
        Api.categories()
            .then(data => {
                setCategories(data);
                setLoading(false);
            });
    }, []);

    return (
        <DropdownButton className="resource-category-selector" title={props.selected ? props.selected.title : "Select a Category"}> 
            <Dropdown.Item onClick={e => props.onChange(null)}>All Categories</Dropdown.Item>
            {categories.map(category =>
                <Dropdown.Item key={category.id} onClick={e => props.onChange(category)}>{category.title}</Dropdown.Item>
            )}
        </DropdownButton>
    );
};

export default CategorySelector;

const parseSearch = search => {
    if (!search) {
        return {};
    }

    if (search[0] === "?") {
        search = search.substring(1);
    }

    const parts = search.split("&");

    const decoded = parts.map(part => part.split("=").map(decodeURIComponent));

    const ret = {};

    for (const [key, val] of decoded) {
        ret[key] = val;
    }

    return ret;
};

export default parseSearch;

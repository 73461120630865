import React, { useState, useEffect, useContext } from 'react';
import { withRouter } from "react-router-dom";
import ReactGA from 'react-ga4';

import { WindowWidthContext } from "./ColumnManager";

const matchRoute = (route, pathname) => {
    if (!route) {
        return true;
    }

    if (route instanceof RegExp) {
        return route.test(pathname);
    }

    return pathname.startsWith(route);
};

const matchSize = (props, width) => {
    const Size = {
        SMALL: 576,
        MEDIUM: 768,
        LARGE: 992,
    };

    const sizes = [992, 768, 576, 0];
    const propNames = ["largeUp", "mediumUp", "smallUp", "default"];

    while (width < sizes[0]) {
        sizes.shift();
        propNames.shift();
    }

    for (let propName of propNames) {
        if (propName in props) {
            return props[propName];
        }
    }

    return 1;

};

const Column = props => {
    let { width, reportWidth, setDisplay } = useContext(WindowWidthContext);

    let [render, setRender] = useState(false);
    let [colWidth, setColWidth] = useState(0);
    let [offset, setOffset] = useState(0);
    let [matchTimeout, setMatchTimeout] = useState(null);
    let [noMatchTimeout, setNoMatchTimeout] = useState(null);

    let match = matchRoute(props.route, props.location.pathname);
    let scale = matchSize(props, width);

    let side = props.side || "right";

    width *= scale;

    useEffect(() => {
        if (match) {
            ReactGA.pageview(window.location.pathname + window.location.search);

            if (noMatchTimeout !== null) {
                clearTimeout(noMatchTimeout);
            }

            reportWidth(side === "right" ? -width : width);
            setColWidth(width);
            setDisplay(props.route, true);

            if (!render) {
                setRender(true);
                setColWidth(width);
                setOffset(width);
                setMatchTimeout(setTimeout(() => setOffset(0), 1));
            } else {
                setOffset(0);
            }
        } else {
            if (matchTimeout !== null) {
                clearTimeout(matchTimeout);
            }

            setDisplay(props.route, false);

            if (render) {
                setOffset(width);
                setNoMatchTimeout(setTimeout(() => setRender(false), 1000));
            }
        }
    }, [props.location.pathname, width]);

    if (!scale) {
        return null;
    }

    if (!render) {
        return null;
    }

    return (
        <div
            className={'main-column ' + (props.className ? props.className : '')}
            style={{width: `${colWidth}px`, [side]: `${-offset}px`}}
        >
            <div className="main-column-content" style={{width: `${width}px`}}>
                {props.children}
            </div>
        </div>
    );
};

export default withRouter(Column);

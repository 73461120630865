import React, { useState, useEffect } from 'react';
import { Row, Col } from "react-bootstrap";

import logo from '../assets/logo.png';
import LoginPopUp from './LoginPopUp';

const SplashScreen = props => {
    const [opacity, setOpacity] = useState(0);
    
    useEffect(() => {
       if (opacity !== props.opacity) {
           setOpacity(props.opacity);
       } 
    }, [props.opacity]);

    return <>
        <div 
            className={"splash d-flex align-items-center justify-content-center flex-wrap" + (props.offlineMessage ? ' has-message' : '' )}
            style={{ opacity }}
        >
            <img className="title-page-logo pr-3" src={logo} alt="" />
            <h1 className="text-primary text-center main-title">Global Vector Hub</h1>

            {props.isOffline === true &&
                <>
                    <div className="w-100 d-flex justify-content-center">
                        <div className="text-center splash-message" dangerouslySetInnerHTML={{ __html: props.offlineMessage }} />
                    </div>

                    <div className="w-100 d-flex justify-content-center mb-3">
                        {props.user && !props.user.isAdmin &&
                            <div className="alert alert-danger">You need to be an administrator to view the site.</div>
                        }
                    </div>

                    <div className="w-100 d-flex justify-content-center">
                        <LoginPopUp loginRefresh={props.loginRefresh} />
                    </div>
                </>
            }
        </div>
    </>;
};

export default SplashScreen;

import React from 'react';
import { withRouter } from "react-router";
import Api from "../Api";

import NewsArticle from "../profiles/NewsArticle"

class NewsPage extends React.Component {
    constructor(props) {
        super(props);


        this.state = { article: null };
    }

    componentDidMount() {
        this.getNewsArticle();
    }

    componentDidUpdate(prevProps) {
        if (prevProps.location.pathname === this.props.location.pathname) {
            return;
        }

        this.getNewsArticle();
    }

    getNewsArticle() {
        let match = this.props.location.pathname.match(/\/news\/(\d+)/);

        if (match) {
            this.articleId = match[1];
        } else {
            return;
        }

        Api.newsArticles([this.articleId])
            .then(data => {
                this.setState({ article: data[0] });
            });
    }

    render() {

        return (
            <div className="d-flex flex-column h-100">
                {this.state.article &&
                    <NewsArticle article={this.state.article} />
                }
            </div>
        )
    }
}

export default withRouter(NewsPage);

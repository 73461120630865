import React from "react";

const ESTIMATED_DATA_LENGTH = 8129971;

let countryProvider;

let globeData = {
    hasLoader: false,
    total: null,
    received: null,
    done: false,
    data: null,
};

let beingRead = false;
let chunks = [];
let updaters = [];

const updateAll = () => {
    for (const updater of updaters) {
        updater();
    }
};

const getData = () => {
    let buffer = new Uint8Array(globeData.received);

    let position = 0;
    for (let chunk of chunks) {
        buffer.set(chunk, position);
        position += chunk.length;
    }

    const result = new TextDecoder("utf-8").decode(buffer);

    tryStore(result);

    chunks = null;
    return JSON.parse(result);
}

const tryStore = data => {
    try {
        window.localStorage.setItem("globe-data-with-continents", data);
    } catch (e) {
        console.info(e);
        console.log(data.length);
        /* Deliberately silenced. */
    }
};

class CountryProvider extends React.Component {
    constructor(props) {
        super(props);

        this.master = false;
        this.state = {};
    } 

    componentDidMount() {
        if (beingRead) {
            this._updater = () => {
                this.setState({ i: this.state.i + 1  });
            };

            this.setState({ i: 0 });

            updaters.push(this._updater);
            return;
        }

        beingRead = true;
        this.master = true;

        this.setState({ ...globeData });

        const stored = window.localStorage.getItem("globe-data-with-continents");
        if (stored) {
            this.setState({ done: true, data: JSON.parse(stored) });
            return;
        }

        countryProvider = fetch("/json-data/globe-with-continents.json")
            .then(response => {
                this.setState({
                    received: 0,
                    total: +response.headers.get("Content-Length") || ESTIMATED_DATA_LENGTH,
                });

                if (response.body && response.body.getReader) {
                    this.setState({ hasLoader: true });

                    const reader = response.body.getReader();
                    const update = ({ done, value }) => {
                        if (done) {
                            const data = getData();
                            globeData.done = done;
                            globeData.data = data;
                            this.setState({ done, data });
                        } else {
                            chunks.push(value);
                            const received = this.state.received + value.length;

                            this.setState({ received });

                            reader.read().then(update);
                        }
                    }

                    reader.read().then(update);
                } else {
                    this.setState({ hasLoader: false });
                    response.json().then(data => {
                        this.setState({ done: true, data });
                        tryStore(JSON.stringify(data));
                    });
                }
            });
    }

    componentDidUpdate() {
        if (this.master) {
            globeData = { ...this.state };
            updateAll();
        }
    }

    componentWillUnmount() {
        if (this._updater) {
            updaters.filter(updater => updater !== this._updater);
        }
    }

    render() {
        return this.props.render(globeData);
    }
}

export default CountryProvider;

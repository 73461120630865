import React, { useState, useEffect } from 'react';

import { Dropdown } from "react-bootstrap";
import { Link } from "react-router-dom";

import Loading from "./Loading";
import Api from '../Api';


class ContinentSelect extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            continents: [],
        }
    }

    componentDidMount() {
        Api.profileTypes('continent')
            .then(profileTypes => {
                if (profileTypes.length) {
                    let profieTypeId = profileTypes[0].id;

                    Api.profiles({}, true, true, profileTypes[0].id)
                        .then(continents => {
                            this.setState({ continents: continents })
                        })
                    ;
                }
            })
        ;
    }

    render() {
        return (
            <Dropdown block="true">
                <Dropdown.Toggle block="true">
                    Continent Select
                </Dropdown.Toggle>
                <Dropdown.Menu>
                    {this.state.continents.map((continent, index) => (
                        <Dropdown.Item as={Link} to={`/profile/${continent.slug}`} key={continent.id}>
                            {continent.title}
                        </Dropdown.Item>
                    ))}
                </Dropdown.Menu>
            </Dropdown>
        );
    }
}

export default ContinentSelect;

import React from "react";
import { DropdownButton, Dropdown } from "react-bootstrap";
import Api from '../Api';

class LanguageSelect extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            lang: 'en',
        }
    }

    componentDidMount() {
        let lang;

        if (this.props.user) {
            lang = this.props.user.language;
        } else {
            lang = this.getCookie('lang');
        }

        this.setState({ lang });
    }
    
    setLanguage = (lang) => {
        if (this.props.user) {
            Api.setLang(lang);
        } else {
            document.cookie = 'lang=' + lang + ';max-age=604800;';
        }

        this.setState({ lang });
    }

    getCookie(name) {
        let cookie = {};

        document.cookie.split(';').forEach(function(el) {
            let [key, value] = el.split('=');

            cookie[key.trim()] = value;
        })

        return cookie[name];
      }

    render() {
        return (
            <div className="lang-button d-flex align-items-center justify-content-between mr-4">
                <DropdownButton id="dropdown-basic-button" title={(this.state.lang ? this.state.lang.toUpperCase() : 'EN')}>
                    <Dropdown.Item onClick={() => this.setLanguage('en')}>English</Dropdown.Item>
                    {/* <Dropdown.Item onClick={() => this.setLanguage('es')}>Spanish</Dropdown.Item>
                    <Dropdown.Item onClick={() => this.setLanguage('fr')}>French</Dropdown.Item>
                    <Dropdown.Item onClick={() => this.setLanguage('pt')}>Portuguese</Dropdown.Item> */}
                </DropdownButton>
            </div>
        );
    }
}

export default LanguageSelect;

import React from "react";
import { Button } from "react-bootstrap";
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSearch } from "@fortawesome/pro-regular-svg-icons";

const SearchLink = props => {
    return (
        <Button
            id="app-search-button"
            as={Link}
            variant="light"
            className="rounded-circle search-button right fade show"
            to="/search"
        >
            <FontAwesomeIcon icon={faSearch} />
        </Button>
    );
};

export default SearchLink;

import React from 'react';
import { Row } from "react-bootstrap";
import SearchInput from "../SearchInput";
import ResourcesFile from './ResourcesFile';
import ResourceBackToTop from './ResourceBackToTop';
import Loading from "../../components/Loading";
import Api from "../../Api";

class Resources extends React.Component {
    constructor(props) {
        super(props);

        this.category = null;
        this.criteria = props.profile ? [props.profile] : [];

        this.state = {
            resources: [],
            loading: false,
            hasResults: false,
            title: false,
        };

        this.scrollRef = React.createRef();

        this.onFavourite = this.onFavourite.bind(this);
    }

    componentDidUpdate(prevProps) {
        const prevId = prevProps.profile && prevProps.profile.id;
        const currId = this.props.profile && this.props.profile.id;
        if (prevId !== currId) {
            this.criteria = [this.props.profile];
            this.search();
        }
    }

    onCriteriaChange = criteria => {
        this.criteria = criteria;

        if (this.criteria.length > 0) {
            this.search();
            this.setState({ title: 'Results' })
        } else {
            this.setState({ title: false, resources: [] });
        }
    }

    onCategoryChange = category => {
        this.category = category;
        this.search();
    }

    search() {
        if (this.criteria.length > 0 || this.category) {
            const ids = this.criteria
                .filter(criteria => criteria.id)
                .map(criteria => criteria.id)
                .join(",");

            const query = {resource_type: "resource", relationship_ids: ids, and_join: '1', search_type: 'resourceSearchCount'};

            const resourceTitle = this.criteria.find(criteria => criteria.profileType.title === "Resource Title Contains");

            if (resourceTitle) {
                query.query = resourceTitle.title;
            }

            if (this.category) {
                query.category_id = this.category.id;
            }

            if (this.criteria.length === 0) {
                query.order_by_property = 'viewCount';
                query.order_by_direction = 'DESC';
            }

            this.setState({ loading: true });
            Api.search(query)
                .then(({ data, total_items }) => {

                    let title = false;

                    if (!total_items) {
                        total_items = 0;
                    }

                    if (this.criteria.length > 0 || this.category) { 
                        title = 'Found ' + total_items + ' Result';
                        if (total_items > 1 || total_items === 0) {
                            title += 's';    
                        }
                    }
                    
                    this.setState({ loading: false, resources: data, title: title });
                })
                .catch(err => console.error(err));
        } else {
            this.setState({ resources: [] });
        }
    }

    onFavourite(id) {
        Api.userFavourites(id)
            .then((response) => {
                this.props.loginRefresh();
            })
            .catch(err => console.error(err))
        ;
    }

    scrollTo() {
        this.scrollRef.current.scrollIntoView({ behavior: 'smooth' });
    }

    render() {
        let favouriteIds = [];

        if (this.props.user && this.props.user.favourites) {
            this.props.user.favourites.forEach((item, index) => {
                favouriteIds.push(item.id);
            })
        }

        return (
            <>
                <div className="flex-grow-1" ref={this.scrollRef}>
                    <SearchInput 
                        key={this.props.profile && this.props.profile.id} 
                        criteria={this.props.profile && [this.props.profile]} 
                        onChange={this.onCriteriaChange} 
                        withTitleSearch
                        withCategory
                        onCategoryChange={this.onCategoryChange}
                        user={this.props.user}
                        searchType="resource"
                        showPopularSearches={true}
                        showDataIndex={true}
                        showResourceTree={false}
                        setDataIndexClass={this.props.setDataIndexClass}
                        setResourceTreeClass={this.props.setResourceTreeClass}
                        showCriteria={true}
                        isProfile={true}
                    />
                </div>
                {this.state.hasResults === false && this.state.resources && 
                    <div>
                        {this.state.title && 
                            <h4 className="mb-4">{this.state.title}</h4>
                        }
                        {this.state.loading ? 
                            <Loading size="2x" /> :
                            <Row>
                                {this.state.resources.map((resource, index) => 
                                    <ResourcesFile 
                                        key={index} 
                                        isFavourite={(favouriteIds.includes(resource.id) ? true : false )} 
                                        onFavourite={this.onFavourite}
                                        index={index} 
                                        resource={resource} 
                                        user={this.props.user}
                                    />
                                )}

                                {this.state.resources.length > 0 && 
                                    <ResourceBackToTop onClick={() => this.scrollTo()} />
                                }
                            </Row>
                        }
                    </div>
                }
            </>
        );
    }
}

export default Resources;

import React from 'react';
import SavedSearch from './SavedSearch';
import Api from "../../Api";
import Loading from '../Loading';
import BackToAccount from './BackToAccount';

class SavedSearches extends React.Component {
    constructor(props) {
        super(props);
        
        this.state = {
            searches: [],
            loading: true,
            got: false,
            adminSearches: false
        }
    }

    componentDidMount() {
        this.getSavedSearches(true);
    }

    getSavedSearches(hideLoader = false) {
        this.setState({ searches: [], got: false, loading: true });

        const requests = [
            Api.currentUser(),
            (this.props.user.isAdmin) ? Api.savedSearches({ isAdmin: 1, noRelationships: 1 }) : null
        ];

        Promise.all(requests).then(responses => {
            const searches = responses[0]?.savedSearches;
            const adminSearches = (responses[1]) ? responses[1] : false;

            this.setState({ searches: searches || [], adminSearches });

            if (hideLoader) {
                this.setState({ loading: false, got: true })
            }
        })
    }

    onRemove = (savedSearchId) => {
        Api.removeSaveSearch(savedSearchId)
            .then(response => {
                this.getSavedSearches(true);
            })
        ;
    }

    render() {
        return (
            <div>
                {this.state.loading === true && 
                    <Loading size="2x" />
                }

                {this.state.loading === false && this.state.got === true &&
                    <>
                        {!this.state.searches.length && 
                            <p>You've not saved any searches.</p>
                        }

                        {this.state.searches && 
                            <>
                                {this.state.searches.map((search, index) => 
                                    <div key={index}>
                                        <SavedSearch 
                                            key={index} 
                                            index={index} 
                                            search={search}
                                            onRemove={this.onRemove}
                                        />
                                    </div>
                                )}
                            </>
                        }

                        {this.state.adminSearches !== false && this.state.adminSearches.length > 0 &&
                            <div className="mb-2 mt-2">
                                <h4>Search URLs</h4>

                                {this.state.adminSearches.map((search, index) => 
                                    <div key={index}>
                                        <SavedSearch 
                                            key={index} 
                                            index={index} 
                                            search={search}
                                            onRemove={this.onRemove}
                                            isAdmin={true}
                                        />
                                    </div>
                                )}
                            </div>
                        }
                    </>
                }

                <BackToAccount />
            </div>
        );
    }
}

export default SavedSearches;
import React from 'react';
import DataCsvForm from '../UserProfile/DataCsvForm';

class DataUploadCta extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            showForm: false
        }
    }

    showForm = () => {
        this.setState({ showForm: true });
    }

    render() {
        return (
            <div className={"data-upload-cta" + (!this.state.showForm ? ' no-form' : '')}>
                <div className="data-upload-cta__header">
                    Can't find what you're looking for? Upload your own data here.
                </div>
                <div className="data-upload-cta__content">
                    {this.state.showForm === false && 
                        <div className="show-form-btn" onClick={() => this.showForm()}>
                            Upload your own data
                        </div>
                    }

                    {this.state.showForm === true && 
                        <DataCsvForm />
                    }
                </div>
            </div>
        )
    }
}

export default DataUploadCta;
import React, { Component } from 'react';

import CoursePopUp from './CoursePopUp';

import { Link } from "react-router-dom";
import { Row, Col } from "react-bootstrap";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircle } from "@fortawesome/pro-solid-svg-icons";
import { faFile } from "@fortawesome/pro-light-svg-icons";
import { faUser } from "@fortawesome/pro-solid-svg-icons";
import { faGraduationCap } from "@fortawesome/pro-solid-svg-icons";

class ProfileList extends Component {
    constructor(props) {
        super(props);

        this.state = {
            modalShow: false,
            modalContent: '',
        }
    }

    showModalPopUp = (course) => {
        this.setState({
            modalShow: true,
            modalContent: course
        })
    }

    handleModalClose = () => {
        this.setState({
            modalContent: '',
            modalShow: false,
        })
    }

    render() {
        return (
            <Row className="no-gutters">
                <div className="profile-sidebar">
                    <span className="sideways-text font-smooth">{this.props.typeTitle}</span>
                </div>
                <Col>
                    <div className="profile-inner">
                        <ul className="info-list list-inline mb-0">
                            {this.props.profiles.map((item, i) => {
                                 if (this.props.profileType === 'resource') {
                                    let entrypoint = process.env.REACT_APP_API_ENTRYPOINT;

                                    let hasUrl = (item.url ? true : false);
                                    var url = (hasUrl ? item.url : entrypoint + "/resource/serve-file/" + item.id + "/" + item.file.filePath)
                                 } else {
                                    var url = '/profile/' + item.slug;
                                 }

                                return (
                                    <li key={i} className={"list-inline-item orange-item " + (this.props.profileType === 'resource' ? 'resource-search-item' : '' )}>
                                        {this.props.profileType === 'resource' && 
                                            <a href={url} target="_blank">
                                                <FontAwesomeIcon 
                                                    key={i} 
                                                    icon={faFile} 
                                                    className="mr-2" 
                                                /> {item.title}
                                            </a>
                                        }

                                        {this.props.profileType === 'user' && 
                                            <a>
                                                <FontAwesomeIcon 
                                                    key={i} 
                                                    icon={faUser} 
                                                    className="mr-2" 
                                                /> {item.firstName} {item.lastName}
                                            </a>
                                        }

                                        {this.props.profileType === 'profile' && 
                                            <Link to={url}>
                                                <FontAwesomeIcon 
                                                    key={i} 
                                                    icon={faCircle} 
                                                    className="mr-2" 
                                                /> {item.title}
                                            </Link>
                                        }

                                        {this.props.profileType === 'course' && 
                                            <a onClick={() => this.showModalPopUp(item)}>
                                                <FontAwesomeIcon 
                                                    key={i} 
                                                    icon={faGraduationCap} 
                                                    className="mr-2" 
                                                /> {item.name}
                                            </a>
                                        }
                                    </li>
                                );
                            })}
                        </ul>
                        <CoursePopUp content={this.state.modalContent} show={this.state.modalShow} close={this.handleModalClose} />
                    </div>
                </Col>
            </Row>
        );
    }
}

export default ProfileList;
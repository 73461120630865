import React from 'react';
import Api from "../../Api";
import FilterPage from './../FilterPage/FilterPage';
import DataUploadCta from './DataUploadCta';
import DataHeatmap from './DataHeatmap';
import DataHeatmapNew from './DataHeatmapNew';
import PopUp from '../PopUp';
import DataCsvForm from '../UserProfile/DataCsvForm';

class DataFilterPage extends React.Component {
    constructor(props) {
        super(props);

        this.criteria = props.profile ? [props.profile] : [];
        
        this.state = {
            loading: false,
            coords: [],
            resultCount: false,
            exportUrl: '',
            showHeatMap: false,
            searched: false,
            mapType: 'abundance',
            showForm: false,
            showIntro: true,
            selectableIds: false,
            startDate: false,
            endDate: false,
            zoom: 2,
            lat: 0,
            long: 0,
        }

        this.searchInputRef = React.createRef();
    }

    componentDidMount() {
        this.props.setFilterPageClass();
        this.setState({ showHeatMap: true })

        this.loadSessionStorage();
    }

    componentWillUnmount() {
        this.props.setFilterPageClass(true);
    }

    loadSessionStorage() {
        const urlParams = new URLSearchParams(window.location.search);
        const paramIds = urlParams.get('ids');

        if (!paramIds) {
            const startDateString = window.sessionStorage.getItem('dataStartDate');
            const startDate = (startDateString ? new Date(startDateString) : false);

            const endDateString = window.sessionStorage.getItem('dataEndDate');
            const endDate = (endDateString ? new Date(endDateString) : false);

            this.setState({ startDate, endDate }, () => {
                const sessionCriteriaString = window.sessionStorage.getItem('dataCriteria');
                const sessionCriteria = JSON.parse(sessionCriteriaString);

                if (sessionCriteria) {
                    this.addCriteria(sessionCriteria);
                }
            })
        }
    }

    addCriteria = criteria => {
        this.searchInputRef.current.addCriteria(criteria);
    }

    removeCriteria = criteria => {
        if (criteria.id == 'dateRange') {
            this.setState({ startDate: false, endDate: false }, () => {
                this.searchInputRef.current.removeCriteria(criteria);
            })
        } else {
            this.searchInputRef.current.removeCriteria(criteria);
        }        
    }

    clearCriteria = () => {
        this.searchInputRef.current.clearCriteria();
        this.setState({ startDate: false, endDate: false })
    }

    onCriteriaChange = criteria => {
        this.criteria = criteria;

        if (this.criteria.length > 0) {
            this.searchV2(true);
            this.setResultsCount();
            this.setSelectableIds();
        } else {
            this.setState({ searched: false, resultCount: false, exportUrl: '', coords: [], selectableIds: false });
        }

        window.sessionStorage.setItem('dataCriteria', JSON.stringify(this.criteria));
    }

    onProfileClick = (profile) => {
        let isCriteria = false;

        this.criteria.forEach((item, index) => {
            if (item.id === profile.id) {
                isCriteria = true;
            }
        });

        if (!isCriteria) {
            this.addCriteria(profile);
        } else {
            this.removeCriteria(profile);
        }
    }

    showHeatMap = () => {
        this.setState({ showHeatMap: (this.state.showHeatMap ? false : true) });
    }

    toggleForm = () => {
        this.setState({ showForm: (this.state.showForm ? false : true) });
    }

    showIntro = () => {
        this.setState({ showIntro: true })
    }

    closeIntro = () => {
        this.setState({ showIntro: false })
    }

    changeHeatMapType = (event) => {
        const target = event.target;
        const fieldValue = target.value;

        this.setState({
            mapType: fieldValue,
            coords: []
        }, () => {
            this.searchV2();
        });
   
    }

    setStartDate = (startDate) => {
        this.setState({ startDate });
        window.sessionStorage.setItem('dataStartDate', (startDate ?? ""));
    }
    
    setEndDate = (endDate) => {
        this.setState({ endDate });
        window.sessionStorage.setItem('dataEndDate', (endDate ?? ""));
    }

    buildDateRangeCriteria = () => {
        let dateRangeTitle = '';
        if (this.state.startDate) dateRangeTitle += this.state.startDate.toLocaleDateString("en-GB");
        if (this.state.startDate && this.state.endDate) dateRangeTitle += ' - ';
        if (this.state.endDate) dateRangeTitle += this.state.endDate.toLocaleDateString("en-GB");
        
        if (this.criteria.filter(criteria => criteria.id === 'dateRange').length === 0) {
            const dateObject = { id: 'dateRange', profileType: { id: 'dateRange', title: 'Date Range' }, title: dateRangeTitle }
            this.addCriteria(dateObject);
        } else {
            this.criteria.forEach((criteria, index) => {
                if (criteria.id === 'dateRange') {
                    this.criteria[index].title = dateRangeTitle;
                }
            })
        }        
    }

    setMapData = (zoom, lat, long) => {
        this.setState({ zoom, lat, long });
    }

    setResultsCount = () => {
        let ids = '';

        if (this.criteria.length > 0) {
            ids = this.criteria
                .filter(criteria => criteria.id && criteria.id !== 'dateRange')
                .map(criteria => criteria.id)
                .join(",")
            ;
        }

        Api.getSearchCount(ids).then(response => {
            this.setState({ resultCount: response?.count });
        });
    }

    setSelectableIds = () => {
        const profiles = {};

        const criteria = this.criteria.filter(criteria => criteria.id && criteria.id !== 'dateRange')

        criteria.forEach((item, index) => {
            if (item?.profileType?.id) {
                profiles[item?.profileType?.id] = item.id
            }
        });

        Api.getMapFilters(profiles).then(response => {
            this.setState({ selectableIds: response.ids });
        });
    }

    searchV2 = async (dontAddDate = false, zoom = false, lat = false, long = false, signal = null) => {
        if (dontAddDate === false && (this.state.startDate || this.state.endDate)) {
            this.buildDateRangeCriteria();
        }

        if (!zoom) {
            zoom = this.state.zoom;
        }

        if (!lat) {
            lat = this.state.lat;
        }

        if (!long) {
            long = this.state.long;
        }

        const query = { zoom, lat, long };

        let ids = '';

        if (this.criteria.length > 0) {
            ids = this.criteria
                .filter(criteria => criteria.id && criteria.id !== 'dateRange')
                .map(criteria => criteria.id)
                .join(",")
            ;

            query['relationship_ids'] = ids;
        }

        if (this.state.startDate) query['start_date'] = Math.round(+new Date(this.state.startDate) / 1000);
        if (this.state.endDate) query['end_date'] = Math.round(+new Date(this.state.endDate) / 1000);
        if (this.state.mapType) query['map_type'] = this.state.mapType;

        let exportUrl = process.env.REACT_APP_API_ENTRYPOINT + '/export-csv-search?resource_type=data&relationship_ids=' + ids + '&and_join=1&export_csv=1';

        if (query['start_date']) {
            exportUrl += '&start_date=' + query['start_date']
        }

        if (query['end_date']) {
            exportUrl += '&end_date=' + query['end_date']
        }

        this.setState({ loading: true, exportUrl, coords: [] });

        Api.searchV2(query, signal).then(response => {
            const state = {
                loading: false,
                zoom
            }

            if (response?.coords) {
                state.coords = response.coords;
            }

            this.setState(state);
        });
    }

    render() {
        let pageContent;

         if (this.criteria.length > 0 && !this.state.resultCount && this.state.searched === true) {
            pageContent = (
                <p>No results were found for that criteria, please try again</p>
            );
        } else if (this.state.showHeatMap === true) {
            pageContent = (
                <DataHeatmapNew 
                    show={this.state.showHeatMap} 
                    coords={this.state.coords} 
                    mapType={this.state.mapType} 
                    reloadOnMount={true}
                    search={this.searchV2}
                    setMapData={this.setMapData}
                    loading={this.state.loading}
                />
            );
        } else if (this.criteria.length > 0 && this.state.resultCount) {
            pageContent = (
                <>
                    <p>The filters you have chosen have produced <span className="result-count">{this.state.resultCount}</span> results.</p>
                    <p>You can download these as a CSV file, or view them on a world map using the icons at the top right of the screen</p>
                </>
            );
        } else {
            pageContent = (
                <>
                    {this.props.introContent && 
                        <div dangerouslySetInnerHTML={{ __html: this.props.introContent }}></div>
                    }
                </>
            );
        }

        return (
            <div className="data-filter-page">
                <FilterPage 
                    user={this.props.user} 
                    onMenuOpen={this.props.onMenuOpen}
                    pageTitle="Data"
                    loading={this.state.loading}
                    pageContent={pageContent}
                    onCriteriaChange={this.onCriteriaChange}
                    criteria={this.criteria}
                    removeCriteria={this.removeCriteria}
                    clearCriteria={this.clearCriteria}
                    searchInputRef={this.searchInputRef}
                    basket={false}
                    categories={false}
                    onProfileClick={this.onProfileClick}
                    resultCount={this.state.resultCount}
                    exportUrl={this.state.exportUrl}
                    showHeatMap={this.showHeatMap}
                    heatMapStatus={this.state.showHeatMap}
                    changeHeatMapType={this.changeHeatMapType}
                    isResourceIndex={false}
                    isDataIndex={true}
                    isNetworkIndex={false}
                    uploadCta={true}
                    uploadCtaOnClick={this.toggleForm}
                    selectableIds={this.state.selectableIds}
                    showDateFilter={true}
                    setStartDate={this.setStartDate}
                    setEndDate={this.setEndDate}
                    onDateSubmit={this.search}
                    startDate={this.state.startDate}
                    endDate={this.state.endDate}
                    showIntro={this.showIntro}
                    searchType="data"
                    additionalProfileSearch={{ 'dataCount[gt]': 0 }}
                    dataEnabled={this.props.dataEnabled}
                />

                <PopUp size="lg" content={<DataCsvForm />} open={this.state.showForm} close={this.toggleForm} />
                <PopUp size="lg" content={<div dangerouslySetInnerHTML={{ __html: this.props.introContent }}></div>} open={this.state.showIntro} close={this.closeIntro} />
            </div>
            
        )
    }
}

export default DataFilterPage;
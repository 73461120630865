import React from "react";
import { Button } from "react-bootstrap";
import { Link } from "react-router-dom";

const MenuLink = props => {
    return (
        <Button as={Link} className="menu-button p-0 m-0 mr-4" to="/menu" variant="link" onClick={props.onClick}>
            <span></span>
            <span></span>
            <span></span>
        </Button>
    );
};

export default MenuLink;

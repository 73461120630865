import React from 'react';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircleNotch } from "@fortawesome/pro-solid-svg-icons";

const Loading = props => {
    let size = props.size ? props.size : '6x';

    return <div className="fill position-relative d-flex align-items-center justify-content-center" style={props.style}>
        <FontAwesomeIcon className="algin-self-center" icon={faCircleNotch} size={size} color="#FF7442" spin />
    </div>;
};

export default Loading;

import React from 'react';
import { Editor } from '@tinymce/tinymce-react';
import { Alert, Form, Button } from "react-bootstrap";
import BackToAccount from './BackToAccount';
import Loading from '../Loading';
import RelationshipInput from '../Relationships/RelationshipInput';
import Axios from 'axios';
import { Link } from 'react-router-dom';

class CollaboratorCreate extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            title: '',
            backgroundInformation: '',
            success: null,
            loading: false,
            relationships: [],
            toAdd: [],
            selectedProfiles: [],
        }
    }

    handleInputChange = (event) => {
        const target = event.target;
        const fieldValue = target.value;
        const fieldName = target.name;

        this.setState({
            [fieldName]: fieldValue
        });
    }

    handleEditorChange = (content, editor) => {
        this.setState({ backgroundInformation: content });
    }

    setRelationships = (relationships) => {
        this.setState({ relationships: relationships })
    }

    setSelectedProfiles = (profiles) => {
        let combinedProfiles = this.state.selectedProfiles.concat(profiles);
        let uniqueProfiles = [...new Set(combinedProfiles)];
        this.setState({ setSelectedProfiles: uniqueProfiles });
    }

    setToAdd = (profiles) => {
        let combinedProfiles = this.state.toAdd.concat(profiles);
        let uniqueProfiles = [...new Set(combinedProfiles)];
        this.setState({ toAdd: uniqueProfiles });
    }

    handleSubmit = (event) => {
        const thisForm = event.currentTarget;

        event.preventDefault();
        event.stopPropagation();

        thisForm.className += " was-validated";

        if (thisForm.checkValidity() !== false) {
            const FormData = require('form-data');
            const form = new FormData();

            form.append('title', this.state.title);
            form.append('background_information', this.state.backgroundInformation);
            form.append('profile_type', process.env.REACT_APP_COLLABORATOR_PROFILE_TYPE_ID);
            form.append('relationships', this.state.relationships);

            let entryPoint = process.env.REACT_APP_API_ENTRYPOINT;
            this.setState({ loading: true });

            Axios.post(entryPoint + '/submit-profile', form, {
                headers: {
                    authorization: 'Bearer ' + window.localStorage.getItem('token')
                }
            })
                .then(response => {
                    this.setState({ 
                        title: '',
                        backgroundInformation: '',
                        success: true,
                    })
                })
                .catch(error => {
                    this.setState({ 
                        success: false, 
                        loading: false
                    });
                })
            ;
        }
    }

    render() {
        return (
            <div className="collaborators__create-form">
                {this.state.success === true &&
                    <Alert className="mb-4" variant="success" onClose={() => (this.setState({success: null}))}>
                        <Alert.Heading>Thank you!</Alert.Heading>
                        <p>Your Collaborator has been submitted and will be reviewed by our team. You will be notified when the review is complete.</p>
                        <p><Link to="/account">Back to account home</Link></p>
                    </Alert>
                }

                {this.state.success === false &&
                    <Alert className="mb-4" variant="danger" onClose={() => (this.setState({success: null}))} dismissible>
                        <Alert.Heading>Oh snap! You got an error!</Alert.Heading>
                        <p>There was a problem submitting the form</p>
                    </Alert>
                }

                {this.state.success !== true && 
                    <Form noValidate className="create-collaborator-form my-auto needs-validation" onSubmit={this.handleSubmit} autoComplete="off" id="create-collaborator-form">
                        <Form.Group controlId="create-collaborator-title">
                            <Form.Label>Title *</Form.Label>
                            <Form.Control name="title" size="md" type="text" value={this.state.title} onChange={this.handleInputChange} required />
                            <Form.Control.Feedback type="invalid">
                                Please provide a valid title.
                            </Form.Control.Feedback>
                        </Form.Group>

                        <Form.Group controlId="create-collaborator-background">
                            <Form.Label>Background Information *</Form.Label>
                            <Editor
                                init={{
                                height: 250,
                                menubar: false,
                                plugins: [
                                    'advlist autolink lists link image charmap print preview anchor',
                                    'searchreplace visualblocks code fullscreen',
                                    'insertdatetime media table paste code help wordcount'
                                ],
                                toolbar:
                                    'undo redo | formatselect | bold italic backcolor | \
                                    alignleft aligncenter alignright alignjustify | \
                                    bullist numlist outdent indent | removeformat | link'
                                }}
                                onEditorChange={this.handleEditorChange}
                            />
                        </Form.Group>

                        <RelationshipInput 
                            setRelationships={this.setRelationships} 
                            displayAssignedProfiles={false} 
                            selectedProfiles={this.state.toAdd}
                            setSelectedProfiles={this.setSelectedProfiles}
                            toAdd={this.state.toAdd}
                            setToAdd={this.setToAdd}
                        />

                        <Button className="mx-0 mt-5 mb-4 font-smooth text-white submit-btn"
                            variant="ternary"
                            size="md"
                            type="submit"
                        >
                            {this.state.loading === true && 
                                <Loading size="1x" />
                            }
                            {this.state.loading === false && 
                                <>Create New Collaborator</>
                            }
                        </Button>
                    </Form>
                }

                <BackToAccount url="/account/collaborators" />
            </div>
        )
    }
}

export default CollaboratorCreate;
import React from 'react';
import { withRouter } from "react-router";
import SearchInput from "../components/SearchInput";
import Loading from "../components/Loading";
import ProfileList from "../components/ProfileList";
import Api from "../Api";

class SearchPage extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            loading: false,
            results: false,
        };

        this.criteria = [];
    }

    search() {
        if (this.criteria.length > 0) {
            const ids = this.criteria
                .filter(criteria => criteria.id)
                .map(criteria => criteria.id)
                .join(",")
            ;

            const query = {resource_type: "global", relationship_ids: ids, and_join: '1', search_type: 'searchCount'};

            this.setState({ loading: true });

            Api.search(query)
                .then((response) => {
                    let results = [];

                    response.data.forEach((item, index) => {
                        if (item.file || item.url) {
                            if (!results[0]) {
                                results[0] = { title: 'Resources', profiles: [] };
                            }

                            results[0].profiles.push(item);
                        } else {
                            if (item['profileType']) {
                                if (!results[item['profileType'].id]) {
                                    results[item['profileType'].id] = { title: item['profileType'].title, profiles: [] };
                                }
        
                                results[item['profileType'].id].profiles.push(item);
                            }
                        }
                    });

                    this.setState({ loading: false, results: results });
                })
            ;
        }
    }

    onCriteriaChange = (criteria) => {
        this.criteria = criteria;

        if (this.criteria.length > 0) {
            this.search();
        } else {
            this.setState({ results: false })
        }
    }

    render() {
        return (
            <>
                <SearchInput 
                    placeholder="Search by country, region or disease"  
                    showPopularSearches={true}
                    onChange={this.onCriteriaChange}
                    user={this.props.user}
                    searchType="search"
                    showCriteria={true}
                />

                {this.state.loading === true && 
                    <Loading size="2x" />
                }

                {this.state.loading === false && this.state.results && 
                    <div className="global-search-wrapper">
                        {this.state.results.map((item, index) => (
                            <ProfileList 
                                key={index} 
                                typeTitle={item.title} 
                                profiles={item.profiles} 
                                profileType={(item.title === 'Resources' ? 'resource' : 'profile' )}
                            /> 
                        ))}
                    </div>
                }
            </>
        );
    }
}

export default withRouter(SearchPage);

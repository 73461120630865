import React, { useContext } from "react";
import { Link } from "react-router-dom";
import { Button } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faUser } from "@fortawesome/pro-light-svg-icons";

import { UserContext } from "../UserContext";

const ProfileLink = props => {
    const user = useContext(UserContext);
    const url = user ? "/account" : "/log-in";
    const notifications = user && user.unreadNotificationsCount ? true : false;

    return (
        <Button as={Link} className={"user-button p-0 m-0" + (notifications ? " has-notifications" : "")} to={url} variant="link">
            <FontAwesomeIcon icon={faUser} />
        </Button>
    );
};

export default ProfileLink;

import React, { Component } from 'react';
import { withRouter } from "react-router";
import { Link } from "react-router-dom";

import { Button, Row, Col, Tabs, Tab } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faCircleNotch } from "@fortawesome/pro-solid-svg-icons";

import CreateAccountForm from "../components/CreateAccountForm";
import SearchInput from "../components/SearchInput";
import Resources from "../components/Resources/Resources";
import ProfileList from "../components/ProfileList";
import SideBar from "../components/SideBar";
import Data from "../components/Data/Data";

import Api from "../Api";

class InfoPage extends Component {
    constructor(props) {
        super(props);

        this.state = {
            profile: false,
            profileSlug: '',
            types: [],
            profilesByType: [],
            loading: true,
            backgroundReadMoreClass: '',
            backgroundReadMoreText: 'Read More',
            activitiesReadMoreClass: '',
            activitiesReadMoreText: 'Read More',
            profileExists: true,
        };

        this.handleBackgroundReadMore = this.handleBackgroundReadMore.bind(this);
        this.handleActivitiesReadMore = this.handleActivitiesReadMore.bind(this);
    }

    componentDidMount() {
        let profile = this.getProfileSlug(this.props.location.pathname);

        if (!profile) {
            return;
        }

        this.loadProfile(profile);
    }

    componentDidUpdate(prevProps, prevState) {
        if (prevProps.location.pathname === this.props.location.pathname) {
            return;
        }

        let profile = this.getProfileSlug(this.props.location.pathname);

        if (!profile) {
            return;
        }

        this.loadProfile(profile);
    }

    getProfileSlug(pathname) {
        const pattern = /^\/profile\/(.+)$/;
        const found = pattern.exec(pathname);

        if (!found) {
            return;
        }

        return found[1];
    }

    loadProfile(profileSlug) {
        if (profileSlug.substring(profileSlug.length - 1) === "/") {
            profileSlug = profileSlug.substring(0, profileSlug.length - 1);
        }

        if (profileSlug !== this.state.profileSlug) {
            this.setState({ profileSlug: profileSlug, loading: true });

            Api.profiles({ slug: profileSlug })
                .then(([profile]) => {
                    if (profile) {
                        const profilesByType = [];

                        if (profile.relationships) {
                            let query = { resource_type: "profile", relationship_ids: profile.id, and_join: '1', include_users: '1', include_courses: '1' };

                            return Api.search(query)
                                .then(relationships => {
                                    let profilesByType = {
                                        users: { title: 'Users', profiles: [] },
                                        courses: { title: 'Courses', profiles: [] },
                                    };

                                    relationships.data.forEach((item, key) => {
                                        if (item.username) {
                                            profilesByType.users.profiles.push(item);
                                        } else if (item.profileType && !item.profileType.hideOnProfile) {
                                            if (item.modality) {
                                                profilesByType.courses.profiles.push(item);
                                            } else {
                                                if (!profilesByType[item['profileType'].id]) {
                                                    profilesByType[item['profileType'].id] = { title: item['profileType'].title, profiles: [] };
                                                }
    
                                                profilesByType[item['profileType'].id].profiles.push(item);
                                            }
                                        }
                                    });

                                    return [profile, profilesByType];
                                });
                        } else {
                            return [profile, profilesByType];
                        }
                    } else {
                        this.setState({ profileFound: false })
                    }
                })
                .then(([profile, profilesByType]) => {
                    this.setState({ profile, profilesByType, loading: false });
                })
                .catch(error => {
                    console.error(error);
                    if (error.name === "AuthenticationError") {
                        this.props.history.push("/log-in");
                        this.setState({
                            profile: false,
                            profileSlug: '',
                            types: [],
                            profilesByType: [],
                            loading: true,
                        });
                    }
                });
        }
    }

    handleBackgroundReadMore() {
        this.setState({
            backgroundReadMoreClass: !this.state.backgroundReadMoreClass ? 'read-more' : '', 
            backgroundReadMoreText: this.state.backgroundReadMoreText === 'Read More' ? 'Read Less' : 'Read More',
        });
    }

    handleActivitiesReadMore() {
        this.setState({
            activitiesReadMoreClass: !this.state.activitiesReadMoreClass ? 'read-more' : '', 
            activitiesReadMoreText: this.state.activitiesReadMoreText === 'Read More' ? 'Read Less' : 'Read More',
        });
    }

    onProfileClose = () => {
        this.props.setDataClass(true);
        this.props.setDataIndexClass(true);
        this.props.history.goBack();
    }

    render() { 
        if (this.state.profileFound === false) {
            return (
                <SideBar page="404">
                    <h2>Profile not found.</h2>
                    <Link to="/">Return Home</Link>
                </SideBar>
            );
        }

        const profilesHtml = [];
        const networkHtml = [];

        const profileKeys = Object.keys(this.state.profilesByType);
        if (profileKeys) {
            profileKeys.forEach(key => {
                const item = this.state.profilesByType[key];
               
                if (item.profiles.length > 0) {
                    const component = <ProfileList 
                        key={key} 
                        typeTitle={item.title} 
                        profiles={item.profiles} 
                        profileType={(item.title === 'Users' ? 'user' : 'profile' )} 
                    />
                    
                    if (item.title === 'Users' || item.title === 'Collaborator' || item.title === 'Institution') {
                        networkHtml.push(component);
                    } else {
                        profilesHtml.push(component);
                    }
                }
            });
        }

        const loadingClass = this.state.loading ? 'loading' : '';

        if (this.state.profile.backgroundInformation) {
            const length = this.state.profile.backgroundInformation.length;
            var backgroundReadMore = false;
            if (length > 300) {
                backgroundReadMore = true;
            }
        }
        
        if (this.state.profile.activities) {
            const length = this.state.profile.activities.length;
            var activitiesReadMore = false;
            if (length > 300) {
                activitiesReadMore = true;
            }
        }  

        return (
            <div className={`main-column-content-inner profile-page-wrap ${loadingClass}`}>
                <div className="loading-loader">
                    <FontAwesomeIcon icon={faCircleNotch} size="6x" color="#FF7442" spin />
                </div>
                <div className="profile-header bg-primary d-flex justify-content-between align-items-center">
                    <h3 className="profile-title font-smooth">{this.state.profile.title}</h3>
                    <Button className="close-link font-smooth" variant="link" onClick={() => this.onProfileClose()}>Close</Button>
                </div>
    
                <Tabs className="profile-tabs d-flex justify-content-around pl-4" defaultActiveKey="profile" id="profile-tabs">
                    <Tab eventKey="profile" title="Profile">
                        <Row className="no-gutters">
                            <div className="profile-sidebar">
                                <span className="sideways-text font-smooth">Background</span>
                            </div>
                            <Col>
                                <div className="profile-inner">
                                    <div 
                                        className={'profile-content-wrap ' + this.state.backgroundReadMoreClass} 
                                        dangerouslySetInnerHTML={{ __html: this.state.profile.backgroundInformation }}
                                    />
                                    
                                    {backgroundReadMore && 
                                        <div 
                                            className="profile-read-more" 
                                            onClick={this.handleBackgroundReadMore}
                                        >
                                            {this.state.backgroundReadMoreText}
                                        </div>
                                    }
                                </div>
                            </Col>
                        </Row>

                        {profilesHtml}

                        {this.state.profile.activities && 
                            <Row className="no-gutters">
                                <div className="profile-sidebar">
                                    <span className="sideways-text font-smooth">Activities</span>
                                </div>
                                <Col>
                                    <div className="profile-inner">
                                        <div className={'profile-content-wrap ' + this.state.activitiesReadMoreClass} dangerouslySetInnerHTML={{ __html: this.state.profile.activities }}></div>
                                        {activitiesReadMore && 
                                            <div className="profile-read-more" onClick={this.handleActivitiesReadMore}>{this.state.activitiesReadMoreText}</div>
                                        }
                                    </div>
                                </Col>
                            </Row>
                        }
                    </Tab>
                    <Tab eventKey="resources" title="Resources">
                        <div className="d-flex flex-column m-5">
                            <Resources 
                                profile={this.state.profile} 
                                user={this.props.user} 
                                setDataClass={this.props.setDataClass} 
                                setDataIndexClass={this.props.setDataIndexClass}
                                setResourceTreeClass={this.props.setResourceTreeClass}
                                loginRefresh={this.props.loginRefresh}
                            />
                        </div>
                    </Tab>
                    <Tab eventKey="data" title="Data">
                        <div className="d-flex flex-column h-100">
                            <div className="p-5">
                                {this.props.dataEnabled === true ?
                                    <Data 
                                        profile={this.state.profile} 
                                        setDataClass={this.props.setDataClass} 
                                        setDataIndexClass={this.props.setDataIndexClass} 
                                        setResourceTreeClass={this.props.setResourceTreeClass}
                                        user={this.props.user} 
                                    /> : 
                                    <p>The data functionality is currently unavailable. Please try again later.</p>
                                }
                                
                            </div>
                        </div>
                    </Tab>
                    <Tab eventKey="network" title="Network">
                        {networkHtml.length > 0 && networkHtml}

                        {networkHtml.length === 0 &&
                            <div className="p-5">
                                <p>No network information found.</p>
                            </div>
                        }
                    </Tab>
                </Tabs>
            </div>
        );
    }
}

export default withRouter(InfoPage);

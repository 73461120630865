import React from 'react';
import 'abortcontroller-polyfill/dist/abortcontroller-polyfill-only'

import { Form, Button } from "react-bootstrap";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import { faSearch } from "@fortawesome/pro-regular-svg-icons";
import { faTimes } from "@fortawesome/pro-light-svg-icons";
import { faRedo } from "@fortawesome/pro-solid-svg-icons";
import { faStar } from "@fortawesome/pro-solid-svg-icons";
import { faCheck } from "@fortawesome/pro-solid-svg-icons";
import { faSearchPlus } from "@fortawesome/pro-solid-svg-icons";
import { faFolderTree } from "@fortawesome/pro-solid-svg-icons";

import Api from "../Api";
import Loading from "./Loading";
import CategorySelector from "./Resources/CategorySelector";
import SearchPopular from './SearchPopular';
import DataCommonSearches from './Data/DataCommonSearches';
import DataIndex from './Data/DataIndex';
import ResourceTree from './Resources/ResourceTree';
import SearchUrlPopUp from './SearchUrlPopUp';

class SearchInput extends React.Component {
    constructor(props) {
        super(props);

        this.placeholder = (props.placeholder) ? props.placeholder : "Search by country, region or disease";
        this.inputSize = (props.inputSize) ? props.inputSize : "lg";
        this.matches = [];
        this.criteria = (props.criteria) ? props.criteria : [];
        this.activeRequest = null;
        this.timeout = null;

        this.state = {
            value: "",
            autocompleteIsOpen: false,
            loading: false,
            matchets: [],
            criteria: [],
            step: 0,
            category: null,
            searchSaved: false,
            showDataIndex: false,
            showResourceTree: false,
        };

        this.getUrlParams();
    }

    getUrlParams = () => {
        const urlParams = new URLSearchParams(window.location.search);
        const paramIds = urlParams.get('ids');
        const search = urlParams.get('search');

        if (paramIds) {
            Api.profiles({ id: paramIds.split(',') })
                .then(response => {
                    if (response && response.length > 0) {
                        response.forEach((item, index) => {
                            this.addCriteria(item);
                        })
                    }
                })
            ;
        } else if (search) {
            Api.savedSearches({ isAdmin: true, accessUrl: search, noRelationships: 1 })
                .then(response => {
                    if (response && response.length > 0) {
                        const savedSearch = response[0];

                        let idJson = JSON.parse(savedSearch.relationshipIds);
                        idJson = idJson.filter(id => id != null);

                        const ids = [];
                        const profiles = [];

                        idJson.forEach(id => {
                            if (!parseInt(id)) {
                                profiles.push({ profileType: { title: "Resource title contains the following phrase" }, title: id});
                            } else {
                                ids.push(id);
                            }
                        });

                        if (ids.length > 0) {
                            Api.profiles({ id: ids })
                                .then(response => {
                                    if (response && response.length > 0) {
                                        const criteria = [...response, ...profiles];
                                        this.addCriteria(criteria);
                                    }
                                })
                            ;
                        }
                    }
                })
            ;
        }
    }

    handleChange = (event) => {
        let value = event.target.value;

        if (this.activeRequest) {
            this.activeRequest.abort();
            this.activeRequest = null;
        }

        if (!value) {
            this.setState({
                value,
                autocompleteIsOpen: false,
                matchets: [],
            });
            return;
        }


        this.setState({
            value,
            loading: true,
            autocompleteIsOpen: true,
        });

        if (this.timeout !== null) {
            clearTimeout(this.timeout);
        }

        this.timeout = setTimeout(() => {
            this.timeout = null;
            this.activeRequest = new AbortController();
            Api.search({ resource_type: "profile", query: this.state.value }, true, true, this.activeRequest.signal)
                .then(res => {
                    let data = res.data;

                    if (this.props.withTitleSearch) {
                        const type = (this.props.titleSearchTitle ?? "Resource title");
                        data = [{title: this.state.value, profileType: {title: type + " contains the following phrase"}}, ...data];
                    }

                    const criteria = [...(this.props.criteria || []), ...this.state.criteria];
                    data = data.filter(el => !criteria.find(c => c.id === el.id));

                    this.setState({
                        loading: false,
                        matches: data,
                    });
                })
                .catch(err => {
                    if (err.name !== "AbortError") {
                        throw err;
                    }
                });
        }, 250);
    }

    removeCriteria = criteria => {
        let newCriteria = this.state.criteria.filter(c => c.id !== criteria.id);

        this.setState({
            criteria: newCriteria,
            searchSaved: false,
        });

        if (this.props.onChange) {
            this.props.onChange([...(this.props.criteria || []), ...newCriteria]);
        }
    }

    clearCriteria = () => {
        this.setState({
            criteria: ([]),
            searchSaved: false,
        })

        this.props.onChange((this.props.criteria || []));
        this.onCategoryChange(null);

        if (this.props.onReset) {
            this.props.onReset();
        }
    }

    addCriteria = match => {
        let criteria;

        // there must be a better/neater way of doing this?
        if (match instanceof Array) {
            criteria = [...this.state.criteria, ...match];
        } else {
            criteria = [...this.state.criteria, match];
        }

        this.setState({
            criteria,
            value: "",
            autocompleteIsOpen: false,
            matchets: [],
            searchSaved: false,
        });

        if (this.props.onChange) {
            this.props.onChange([...(this.props.criteria || []), ...criteria]);
        }
    }

    saveSearch = () => {
        const ids = [];
        const criteria = [...(this.props.criteria || []), ...this.state.criteria]

        criteria.forEach((item, index) => {
            ids.push(item.id);
        });

        Api.saveSearch(ids, this.props.searchType)
            .then((resp) => {
                this.setState({ searchSaved: true });
            })
        ;
    }

    saveSearchUrl = (url) => {
        const ids = [];
        const criteria = [...(this.props.criteria || []), ...this.state.criteria];

        criteria.forEach((item, index) => {
            if (item?.id) {
                ids.push(item.id);
            } else {
                ids.push(item.title);
            }
        });

        return Api.saveSearchUrl(ids, url);
    }

    highlightText = text => {
        let reg = RegExp(`(${this.state.value})`, "i");
        let parts = text.split(reg);

        return parts.map((part, index) => {
            if (part.match(reg)) {
                return <span key={index} className="highlight text-bold">{part}</span>;
            } else {
                return part;
            }
        });
    }

    onCategoryChange = category => {
        this.setState({ category: category });
        if (this.props.onCategoryChange) {
            this.props.onCategoryChange(category);
        }
    }

    toggleDataIndex = () => {
        this.setState({ showDataIndex: (this.state.showDataIndex ? false : true), showResourceTree: false });
        this.props.setDataIndexClass();
        this.props.setResourceTreeClass(true);
    }

    toggleResourceTree = () => {
        this.setState({ showResourceTree: (this.state.showResourceTree ? false : true), showDataIndex: false });
        this.props.setResourceTreeClass();
        this.props.setDataIndexClass(true);
    }

    dataIndexClick = (element) => {
        let isCriteria = false;

        this.state.criteria.forEach((item, index) => {
            if (item.id === element.id) {
                isCriteria = true;
            }
        });

        // only add new elements
        if (!isCriteria) {
            this.addCriteria(element);
        }
    }

    resourceTreeClick = (element) => {
        this.clearCriteria();
        var treeTerms = [...element.criteria];

        if (treeTerms && treeTerms.length) {

            var that = this;
            setTimeout(function() {
                that.addCriteria(treeTerms);
            }, 500)
        }
    }

    render() {
        const hasCriteria = this.state.criteria.length || (this.props.criteria && this.props.criteria.length);
        
        return (
            <>
                {this.props.showDataIndex &&
                    <div className="data-index-view">
                        <DataIndex 
                            onClick={this.dataIndexClick}
                            criteria={this.state.criteria} 
                            close={this.toggleDataIndex}
                        />
                    </div>   
                }

                {this.props.showResourceTree && this.props.user &&  
                    <div className="resource-tree-view">
                        <ResourceTree onClick={this.resourceTreeClick} />
                    </div>
                }

                <div className="search-input-wrapper">
                    <FontAwesomeIcon className="search-icon" icon={faSearch} />
                    <Form.Control
                        className="search-input"
                        id="search-input"
                        size={this.inputSize}
                        type="text"
                        placeholder={this.placeholder}
                        onChange={this.handleChange}
                        onBlur={() => {
                            this.timeout = setTimeout(() => this.setState({ autocompleteIsOpen: false }), 300)
                        }}
                        onFocus={() => {
                            if (this.timeout) {
                                clearTimeout(this.timeout);
                            }
                            if (this.state.value) {
                                this.setState({ autocompleteIsOpen: true });
                            }
                        }}
                        value={this.state.value}
                    />

                    {hasCriteria > 0 && this.props.user && 
                        <div className="search-input-save" onClick={this.saveSearch}>
                            Save Search <FontAwesomeIcon className="save-icon" icon={(this.state.searchSaved ? faCheck : faStar)} />
                        </div>
                    }

                    {hasCriteria > 0 && this.props.user?.isAdmin === true && this.props.searchType === 'resource' && !this.props.isProfile &&
                        <SearchUrlPopUp onSubmit={this.saveSearchUrl} />
                    }

                    {(hasCriteria > 0 || this.state.category) && 
                        <div className="search-input-reset" onClick={this.clearCriteria}>
                            Reset Criteria <FontAwesomeIcon className="reset-icon" icon={faRedo} />
                        </div>
                    }

                    {this.props.showDataIndex && 
                        <div className="show-data-index-btn" onClick={() => this.toggleDataIndex()}>
                            {(this.state.showDataIndex ? 'Hide' : 'View' )} Filter <FontAwesomeIcon className="view-icon" icon={faSearchPlus} />
                        </div>
                    }

                    {this.props.showResourceTree && this.props.user && 
                        <div className="show-resource-tree-btn" onClick={() => this.toggleResourceTree()}>
                            {(this.state.showResourceTree ? 'Hide' : 'View' )} Resource Tree <FontAwesomeIcon className="view-icon" icon={faFolderTree} />
                        </div>
                    }

                    {this.state.autocompleteIsOpen &&
                        <div className="search-autocomplete" id="data-search-autocomplete">
                            <ul className="search-autocomplete-list">
                                {this.state.loading ?
                                    <Loading size="3x" /> : (
                                        this.state.matches.length ?
                                        this.state.matches.map((element, index) =>
                                            <li key={index}>
                                                <Button
                                                    className="btn-flat"
                                                    onClick={() => this.addCriteria(element)}
                                                    name="submit-button"
                                                    value={element.title}
                                                    type="submit"
                                                    block
                                                >
                                                    <span className="data-type text-primary">{element.profileType.title}:</span>
                                                    <p className={"data-name"}>
                                                        {this.highlightText(element.title)}
                                                    </p>
                                                </Button>
                                            </li>
                                        ) :
                                        <div className="p-3">0 results.</div>
                                    )
                                }
                            </ul>
                        </div>
                    }
                </div>

                {this.props.withCategory && 
                    <div className="category-criteria">
                        <h4>Current Category:</h4>
                        <CategorySelector selected={this.state.category} onChange={this.onCategoryChange}/>
                    </div>
                }

                {this.props.showCriteria === true && hasCriteria > 0 &&
                    <div className="search-criteria mb-5">
                        <h4 className="mb-3">Current Filters:</h4>
                        <ul className="search-criteria-list list-inline">
                            {this.props.criteria && this.props.criteria.map(criteria =>
                                <li key={criteria.id} className={`list-inline-item py-2 px-3 m-1`}>
                                    {criteria.profileType.title}: <span>{(criteria.profileType.title === 'User' ? criteria.fullName : criteria.title )}</span>
                                </li>
                            )}
                            {this.state.criteria.map(criteria =>
                                <li key={criteria.id} className={`list-inline-item py-2 px-3 m-1`}>
                                    {criteria.profileType.title}: <span>{criteria.title}</span>
                                    <FontAwesomeIcon className="close-icon ml-3" icon={faTimes} data-filtervalue={criteria.title} onClick={() => this.removeCriteria(criteria)} />
                                </li>
                            )}
                        </ul>
                    </div>
                }

                {!hasCriteria && this.props.showPopularSearches && 
                    <SearchPopular addCriteria={this.addCriteria} searchType={this.props.searchType} />
                }  

                {!hasCriteria && this.props.showCommonSearches && 
                    <DataCommonSearches addCriteria={this.addCriteria}  />
                }         
            </>
        )
    }
}

export default SearchInput;

import React from 'react';
import MessageUser from './MessageUser';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faUser } from "@fortawesome/pro-solid-svg-icons";
import { faResearchgate, faTwitterSquare, faLinkedin, faOrcid } from "@fortawesome/free-brands-svg-icons";

const ProfileUserContent = ({ user }) =>  {
    const entrypoint = process.env.REACT_APP_API_ENTRYPOINT;

    return (
        <div className="profile-user-content">
            <div className="profile-user-content__profile-picture">
                <div className="profile-user-content__image">
                    {!user.file && 
                        <FontAwesomeIcon icon={faUser} className="no-image"/> 
                    }

                    {user.file &&
                        <div 
                            className="profile-picture" 
                            style={{ backgroundImage: "url(" + entrypoint + "/media/" + encodeURI(user.file.filePath) + ")" }} 
                        />
                    }
                </div>
                <div className="profile-user-content__message">
                    <MessageUser user={user} />
                </div>
                <div className="profile-user-content__social">
                    {user.researchGate && 
                        <div className="user-social__researchgate">
                            <FontAwesomeIcon icon={faResearchgate} />
                            <a href={user.researchGate} target="_blank">ResearchGate</a>
                        </div>
                    }

                    {user.twitter && 
                        <div className="user-social__twitter">
                            <FontAwesomeIcon icon={faTwitterSquare} />
                            <a href={user.twitter} target="_blank">Twitter</a>
                        </div>
                    }

                    {user.linkedIn && 
                        <div className="user-social__linkedin">
                            <FontAwesomeIcon icon={faLinkedin} />
                            <a href={user.linkedIn} target="_blank">LinkedIn</a>
                        </div>
                    }

                    {user.orcid && 
                        <div className="user-social__orcid">
                            <FontAwesomeIcon icon={faOrcid} />
                            <a href={user.orcid} target="_blank">ORCID</a>
                        </div>
                    }

                </div>
            </div>
            <div className="profile-user-content__content">
                {user.fullName && user.fullName !== 'null' && 
                    <><strong>Name: </strong> {user.fullName} <br /></>
                }

                {user.title && user.title !== 'null' && 
                    <><strong>Title: </strong> {user.title} <br /></>
                }

                {user.organisation && user.organisation !== 'null' && 
                    <><strong>Name of Institution/Organisation: </strong> {user.organisation} <br /></>
                }

                {user.affiliation && user.affiliation !== 'null' &&
                    <><strong>Affiliation: </strong> {user.affiliation} <br /></>
                }

                {user.organisationType && user.organisationType !== 'null' &&
                    <><strong>Organisation Type: </strong> {user.organisationType} <br /></>
                }

                {user.country && user.country !== 'null' &&
                    <><strong>Country: </strong> {user.country} <br /></>
                }

                {user.institutionInformation && user.institutionInformation !== 'null' &&
                    <><strong>Institution Information: </strong> {user.institutionInformation} <br /></>
                }

                {user.unitCapabilities && user.unitCapabilities !== 'null' &&
                    <><strong>Unit Capabilities: </strong> {user.unitCapabilities} <br /></>
                }

                {user.fieldResearchUnits && user.fieldResearchUnits !== 'null' && 
                    <><strong>Field Research Units: </strong> {user.fieldResearchUnits} <br /></>
                }   

                {user.labCapabilities && user.labCapabilities !== 'null' && 
                    <><strong>Lab capabilities for diagnosis for vector-borne diseases: </strong> {user.labCapabilities} <br /></>
                }

                {user.funder && user.funder !== 'null' && 
                    <><strong>Funder: </strong> {user.funder} <br /></>
                }

                {user.expertise && user.expertise !== 'null' && 
                    <><strong>Expertise: </strong> {user.expertise} <br /></>
                }

                {user.otherProfiles && user.otherProfiles !== 'null' &&
                    <><strong>Other Profiles: </strong> {user.otherProfiles} <br /></>
                }

                {user.url && user.url !== 'null' &&
                    <>
                        <strong>Website / URL: </strong> 
                        <a href={user.url} target="_blank" rel="noopener noreferrer">
                            {user.url}
                        </a>
                        <br />
                    </>
                }

                {user.aboutMe && user.aboutMe !== 'null' && 
                    <><strong>About Me: </strong> {user.aboutMe} <br /></>
                }

                {(user.geoLocation === 'show' || user.geoLocation === 'dynamic') &&
                    <><strong>Current Location: </strong> {user.currentLocation} <br /></>
                }

                {user.backgroundInformation && user.backgroundInformation !== 'null' &&
                    <>
                        <strong>Background Information: </strong> 
                        <div>
                            <div dangerouslySetInnerHTML={{ __html: user.backgroundInformation }} />
                        </div>
                        <br />
                    </>
                }
            </div>
        </div>
    )
}

export default ProfileUserContent;
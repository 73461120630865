//import React from 'react';
import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Accordion, Card } from 'react-bootstrap';
import Loading from '../components/Loading';

const FaqPage = props => {
    const [questions, setQuestions] = useState(null);

    useEffect(() => {
        let entryPoint = process.env.REACT_APP_API_ENTRYPOINT;

        // Get Questions from API
        axios.get(entryPoint + '/api/faqs.json')
            .then(response => {
                setQuestions(response.data);
            }, (error) => {
                console.log(error);
            });
    }, []);

    if (questions === null) {
        return <Loading />
    }

    return (
        <div className="d-flex flex-column h-100">
            <Accordion defaultActiveKey={(questions[0] ? questions[0].id : '' )}>
                {questions.map(faq => (
                    <Card key={`faq-item-${faq.id}`}>
                        <Accordion.Toggle as={Card.Header} eventKey={faq.id} style={{cursor: 'pointer'}} dangerouslySetInnerHTML={{ __html: faq.question }} />
                        <Accordion.Collapse eventKey={faq.id}>
                            <Card.Body dangerouslySetInnerHTML={{ __html: faq.answer }} />
                        </Accordion.Collapse>
                    </Card>
                ))}
            </Accordion>
        </div>
    )
}

export default FaqPage;

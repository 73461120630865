import React from 'react';
import PopUp from './PopUp';

class CoursesIntroPopUp extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            show: false,
        }
    }

    componentDidMount() {
        if (!window.sessionStorage.getItem('coursesPopUp')) {
            this.setState({ show: true });
            window.sessionStorage.setItem('coursesPopUp', true);
        }
    }

    handleClose = () => {
        this.setState({ show: false });
    }

    render() {
        let content = <div className="content" dangerouslySetInnerHTML={{__html: this.props.content}}></div>

        return (
            <PopUp size="lg" className="courses-intro-modal" content={content} open={this.state.show} close={this.handleClose} />
        );
    }
}

export default CoursesIntroPopUp;
import React from 'react';
import { Link } from 'react-router-dom';

class NetworkUserDetailList extends React.Component {
    render() {
        return (
            <div className="user-detail-list">
                <div className="user-detail-list__header">
                    {this.props.title}
                    <Link to={this.props.titleHref}>
                        <i>(manage)</i>
                    </Link>
                </div>
                <div className="user-detail-list__items">
                    {this.props.items.map(item => (
                        <div className="user-detail-list__item" key={item.id}>
                            <div className="user-detail-list__item-left"><span></span></div>
                            <div className="user-detail-list__item-title">
                                <Link to={`/profile/${item.slug}`}>
                                    {item.title}
                                </Link>
                                
                            </div>
                        </div>
                    ))}
                </div>
            </div>
        );
    }
}

export default NetworkUserDetailList;
import { faSearch } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useState } from 'react';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

const FilterPageDateFilter = props => {
    // const [startDate, setStartDate] = useState('');
    // const [endDate, setEndDate] = useState('');
    
    return (
        <div className="d-flex justify-content-between">
            <div className="mr-1">
                <DatePicker 
                    selected={props.startDate} 
                    className="form-control form-control-md" 
                    onChange={date => { props.setStartDate(date)}}
                    dateFormat="dd/MM/yyyy"
                    placeholderText="Start Date"
                    peekNextMonth
                    showMonthDropdown
                    showYearDropdown
                />
            </div>
            <div className="mr-1">
                <DatePicker 
                    selected={props.endDate} 
                    className="form-control form-control-md" 
                    onChange={date => { props.setEndDate(date)}}
                    dateFormat="dd/MM/yyyy" 
                    placeholderText="End Date"
                    peekNextMonth
                    showMonthDropdown
                    showYearDropdown
                />
            </div> 
            <div className="btn btn-primary date-filter-submit" onClick={() => props.onDateSubmit()}>
                <FontAwesomeIcon icon={faSearch} />    
            </div>         
        </div>
    );   
}

export default FilterPageDateFilter;
import React from 'react';

import inPolygon from "robust-point-in-polygon";

import { vec3, vec4, mat4 } from "gl-matrix";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircleNotch } from "@fortawesome/pro-solid-svg-icons";

import Drawer from "../Drawer";

import ToolTip from "./ToolTip";
import NewsItem from "./NewsItem";

const Loading = props => {
    return <div className="fill position-relative d-flex align-items-center justify-content-center" style={{background: "#161a2f", marginLeft: "-100%"}}>
        <FontAwesomeIcon className="algin-self-center" icon={faCircleNotch} size="6x" color="#FF7442" spin />
    </div>;
};

class Globe extends React.Component {
    fragShaderGlobe = `
        precision mediump float;

        varying vec3 _position;
        varying vec3 _modelPos;
        varying float _continent;
        varying float _selected;
        varying float _time;

        float map(
            float value,
            float inMin,
            float inMax,
            float outMin,
            float outMax
        ) {
            return outMin + (value - inMin)
                * (outMax - outMin) / (inMax - inMin);
        }

        void main(void) {
            vec3 color;

            if (_selected > -0.7) {
                color = vec3(0.4, 0.22, 0.21);
            } else {
                color = vec3(1.0, 0.45, 0.25);
            }

            if (abs(_continent - _selected) < 0.1) {
                color = vec3(1.0, 0.45, 0.25);
            }

            float val = min(1.0, max(0.0, 1.6 - length(0.5 - gl_PointCoord) / 0.5));

            gl_FragColor = val * vec4(color, 1.0);

            gl_FragColor.a *= map(
                _position.z,
                -_modelPos.z - 1.0,
                -_modelPos.z + 1.0,
                0.4,
                1.0
            );

            if (gl_FragColor.a < 0.1) {
                discard;
            }
        }
    `;

    vertShaderGlobe = `
        attribute vec3 position;
        attribute float continent;

        uniform mat4 modelMatrix;
        uniform mat4 projectionMatrix;
        uniform vec3 modelPos;
        uniform float selected;
        uniform float time;

        varying vec3 _position;
        varying vec3 _modelPos;
        varying float _continent;
        varying float _selected;
        varying float _time;

        float map(
            float value,
            float inMin,
            float inMax,
            float outMin,
            float outMax
        ) {
            return outMin + (value - inMin)
                * (outMax - outMin) / (inMax - inMin);
        }

        void main(void) {
            vec4 pos = modelMatrix * vec4(position, 1.0);
            pos -= vec4(modelPos, 0.0);
            _position = pos.xyz;

            gl_PointSize = map(
                _position.z,
                -modelPos.z - 1.0,
                -modelPos.z + 1.0,
                3.0,
                5.0
            );

            if (abs(continent - selected) < 0.1) {
                gl_PointSize += 2.0;

                /*** pos = (modelMatrix * vec4(1.01 * position, 1.0));
                pos -= vec4(modelPos, 0.0);
                _position = pos.xyz; ***/
            }

            gl_Position = projectionMatrix * pos;

            _modelPos = modelPos;
            _continent = continent;
            _selected = selected;
            _time = time;
        }
    `;

    fragShaderSphere = `
        precision mediump float;

        varying float _intensity;

        void main(void) {
            gl_FragColor = vec4(1.0, 0.45, 0.25, 1.0);

            gl_FragColor.a = pow((1.0 - _intensity), 4.0) / 10.0;
        }
    `;

    vertShaderSphere = `
        attribute vec3 position;

        uniform mat4 projectionMatrix;
        uniform vec3 modelPos;

        varying float _intensity;

        float map(
            float value,
            float inMin,
            float inMax,
            float outMin,
            float outMax
        ) {
            return outMin + (value - inMin)
                * (outMax - outMin) / (inMax - inMin);
        }

        void main(void) {
            vec4 modelP = vec4(position * 1.05, 1.0);
            vec4 cameraP = modelP - vec4(modelPos, .0);

            gl_Position = projectionMatrix * cameraP;

            _intensity = abs(dot(normalize(modelP.xyz), normalize(cameraP.xyz)));
        }
    `;

    coords = null;
    angles = [0, 0];
    spinSpeed = 0.001;
    momentum = [-this.spinSpeed, 0];
    frictionFactor = 0.99;

    positionProviders = {};

    state = {
        loading: true,
        country: null,
        showTooltip: false,
        grabState: "cursor-grab",
    };

    componentDidMount() {
        fetch("/json-data/sphere.json")
            .then(sphere => sphere.json())
            .then(sphere => {
                this.data = this.props.globe;
                this.sphere = sphere;
            })
            .then(() => {
                this.startGl();

                this.running = true;

                this.animate();

                this.registerEvents();
            })
            .then(() => {
                this.setState({
                    loading: false,
                });
            });
    }

    componentWillUnmount() {
        this.stop();
        this.unregisterEvents();
        this.endGL();
    }

    render() {
        return (
            <>
                {this.state.showTooltip && this.state.country && <ToolTip country={this.state.country} />}
                {!this.state.loading && this.props.news.map(n => this.getNewsItem(n))}
                <div ref={el => this.setCanvasWidth(el)} className="globe-wrapper" style={{left: this.props.left + "px", right: this.props.right + "px"}} >
                    <canvas ref={el => (this.canvas = el)} className={`background-canvas ${this.state.grabState}`} />
                </div>
                {this.state.loading && <Loading />}
            </>
        );
    }

    getNewsItem(newsItem) {
        let registerPositionProvider = (func) => {
            this.positionProviders[newsItem.id] = func;
        };
        let removePositionProvider = () => {
            delete this.positionProviders[newsItem.id];
        };

        return <NewsItem key={`globe-news-item-${newsItem.id}`}
            item={newsItem}
            registerPositionProvider={registerPositionProvider}
            removePositionProvider={removePositionProvider}
        />
    }

    startGl() {
        if (!this.canvas) {
            return;
        }

        let gl = this.gl = this.canvas.getContext("webgl") || this.canvas.getContext("experimental-webgl");

        this.globeDrawer = new Drawer(
            gl,
            this.vertShaderGlobe,
            this.fragShaderGlobe
        );

        this.sphereDrawer = new Drawer(
            gl,
            this.vertShaderSphere,
            this.fragShaderSphere
        );

        gl.viewport(0, 0, this.canvas.width, this.canvas.height);

        gl.clearColor(22.0 / 255, 26.0 / 255, 47.0 / 255, 1.0);

        gl.enable(gl.BLEND);
        gl.blendFunc(gl.SRC_ALPHA, gl.ONE_MINUS_SRC_ALPHA);

        this.globeBuffer = this.globeDrawer.createBuffer();
        this.continentBuffer = this.globeDrawer.createBuffer();
        this.sphereBuffer = this.sphereDrawer.createBuffer();

        this.modelMatrix = mat4.create();
        this.modelMatrixInverse = mat4.create();

        this.projectionMatrix = mat4.perspective(
            mat4.create(),
            75,
            this.canvas.width / this.canvas.height,
            1.0,
            10.0,
        );

        this.projectionMatrixInverse = mat4.invert(
            mat4.create(),
            this.projectionMatrix
        );


        this.globeDrawer.bufferData(this.globeBuffer, this.data["locationData"]);
        this.globeDrawer.bufferData(this.continentBuffer, this.data["continentData"]);
        this.globeDrawer.setPrimitives(this.data["locationData"].length / 3);

        this.globeDrawer.bufferData(this.sphereBuffer, this.sphere);
        this.sphereDrawer.setPrimitives(this.sphere.length / 3);
    }

    endGL() {
        if (this.globeDrawer) {
            this.globeDrawer.deleteBuffer(this.globeBuffer);
            this.globeDrawer.destroy();
            this.globeDrawer = null;
        }

        if (this.sphereDrawer) {
            this.sphereDrawer.deleteBuffer(this.sphereBuffer);
            this.sphereDrawer.destroy();
            this.sphereDrawer = null;
        }

        this.gl = null;
    }

    animate() {
        if (!this.canvas) {
            return;
        }

        this.setCanvasWidth(this.wrapper);

        let gl = this.gl;
        this.animateMomentum();

        this.modelPos = vec3.fromValues(0, 0, 6);

        mat4.rotateY(
            this.modelMatrix,
            mat4.fromXRotation(mat4.create(), -this.angles[1]),
            this.angles[0]
        );

        mat4.rotateX(
            this.modelMatrixInverse,
            mat4.fromYRotation(mat4.create(), this.angles[0]),
            this.angles[1]
        );

        gl.enable(gl.DEPTH_TEST);
        this.globeDrawer.use();

        this.globeDrawer.setAttribute("position", this.globeBuffer);
        this.globeDrawer.setAttribute("continent", this.continentBuffer);

        this.globeDrawer.setUniform("modelMatrix", this.modelMatrix);
        this.globeDrawer.setUniform("projectionMatrix", this.projectionMatrix);
        this.globeDrawer.setUniform("modelPos", this.modelPos);
        this.globeDrawer.setUniform("selected", this.state.continent ? this.state.continent.properties.id : -2.0);

        this.globeDrawer.setUniform("time", ((new Date()).getTime() / 1000.0) % (Math.PI * 2));

        this.globeDrawer.draw("POINTS");

        gl.disable(gl.DEPTH_TEST);
        this.sphereDrawer.use();

        this.sphereDrawer.setAttribute("position", this.sphereBuffer);

        this.sphereDrawer.setUniform("projectionMatrix", this.projectionMatrix);
        this.sphereDrawer.setUniform("modelPos", this.modelPos);

        this.sphereDrawer.draw("TRIANGLES", false);

        this.updateChildPositions();

        if (this.running) {
            this.animationFrame = window.requestAnimationFrame(this.animate.bind(this));
        }
    }

    stop() {
        this.running = false;
        window.cancelAnimationFrame(this.animationFrame);
    }

    _constrainAngles() {
        if (this.angles[1] < -Math.PI / 2) {
            this.angles[1] = -Math.PI / 2;
        } else if (this.angles[1] > Math.PI / 2) {
            this.angles[1] =  Math.PI / 2;
        }
    }

    animateMomentum() {
        if (this.state.continent) {
            this._constrainAngles();

            return;
        }

        // The world spins ccws when viewed from above.
        if (Math.abs(this.momentum[0]) < this.spinSpeed) {
            this.momentum[0] -= 0.00001;
        }

        this.angles[1] -= this.angles[1] / 1000;

        this.angles[0] += this.momentum[0];
        this.angles[1] += this.momentum[1];

        this.momentum[0] *= this.frictionFactor;
        this.momentum[1] *= this.frictionFactor;

        this._constrainAngles();
    }

    updateChildPositions() {
        for (let id in this.positionProviders) {
            let provider = this.positionProviders[id];
            provider(this.globeToScreen);
        }
    }

    registerEvents() {
        if (!this.canvas) {
            return;
        }

        this.canvas.addEventListener("touchstart", this.onMouseDown);
        this.canvas.addEventListener("mousedown", this.onMouseDown);
        this.canvas.addEventListener("mousemove", this.onMouseMoveGlobe);
        this.canvas.addEventListener("mouseout", this.onMouseOutGlobe);
        this.canvas.addEventListener("click", this.onClickGlobe);
    }

    unregisterEvents() {
        this.canvas.removeEventListener("mousedown", this.onMouseDown);
        this.canvas.removeEventListener("touchstart", this.onMouseDown);
        this.canvas.removeEventListener("mousemove", this.onMouseMoveGlobe);
        this.canvas.removeEventListener("mouseout", this.onMouseOutGlobe);
        this.canvas.removeEventListener("click", this.onClickGlobe);
    }

    _getXY = event => {
        let x, y;

        if (event.touches && event.touches.length) {
            x = event.touches[0].clientX;
            y = event.touches[0].clientY;
        } else {
            x = event.clientX;
            y = event.clientY;
        }

        return [x, y];
    }

    onMouseDown = event => {
        this.coords = this._getXY(event);
        this.dragged = false;

        window.addEventListener("mouseup", this.onMouseUp);
        window.addEventListener("mousemove", this.onMouseMove);
        window.addEventListener("touchend", this.onMouseUp);
        window.addEventListener("touchmove", this.onMouseMove);
    }

    onMouseUp = event => {
        this.coords = null;

        this.props.setSelect();
        this._setCursor("grab");

        window.removeEventListener("mouseup", this.onMouseUp);
        window.removeEventListener("mousemove", this.onMouseMove);
        window.removeEventListener("touchend", this.onMouseUp);
        window.removeEventListener("touchmove", this.onMouseMove);
    }

    onMouseMove = event => {
        if (!this.coords) {
            return;
        }

        if (!this.dragged) {
            this._setCursor("grabbing");
            this.props.setNoSelect();
            this.dragged = true;
        }

        let [x, y] = this._getXY(event);

        this.angles[0] -= (x - this.coords[0]) / 300;
        this.angles[1] += (y - this.coords[1]) / 300;

        this.momentum[0] = -(x - this.coords[0]) / 5000;
        this.momentum[1] = (y - this.coords[1]) / 5000;

        this.coords = [x, y];
    }

    onMouseMoveGlobe = event => {
        let rect = this.canvas.getBoundingClientRect();
        let x = event.clientX - rect.left;
        let y = event.clientY - rect.top;

        let globeCoords = this.screenToGlobe(x, y);

        if (globeCoords === null) {
            this.setContinent(null);
        } else {
            let continent = this.getContinent(globeCoords);
            this.setContinent(continent);
        }
    }

    onMouseOutGlobe = event => {
        this.setContinent(null);
    }

    onClickGlobe = event => {
        if (!this.state.continent || this.dragged) {
            this.dragged = false;
            return;
        }

        this.dragged = false;

        /* Replace handles north and south america. */
        let continentSlug = this.state.continent.properties.continent.replace('A', '-a').toLowerCase();
        this.props.setContinent(`/continent/${continentSlug}`);

        this.setState(() => {return {continent: null};});
    }

    screenToGlobe = (x, y) => {
        let cameraX = 2 * x / this.canvas.offsetWidth - 1;
        let cameraY = 2 * y / this.canvas.offsetHeight - 1;

        let modelCoords = this.getModelCoordinates(cameraX, cameraY);
        let intersection = this.getSphereIntersection(modelCoords);

        if (intersection === null) {
            return null;
        }

        let rotatedCoords = this.applyRotation(intersection);
        return this.cartesianToPolar(rotatedCoords);
    }

    globeToScreen = (lat, long) => {
        let theta = -Math.PI * lat / 180 + Math.PI / 2;
        let phi = Math.PI * long / 180 + Math.PI;

        let spherePosition = vec4.fromValues(
            Math.sin(theta) * Math.sin(phi),
            -Math.cos(theta),
            -Math.sin(theta) * Math.cos(phi),
            1.0,
        );
        let modelPosition = vec4.transformMat4(
            vec4.create(),
            spherePosition,
            this.modelMatrix,
        );
        let worldPosition = vec4.subtract(
            vec4.create(),
            modelPosition,
            vec4.fromValues(...this.modelPos, 0.0),
        );
        let cameraPosition = vec4.transformMat4(
            vec4.create(),
            worldPosition,
            this.projectionMatrix,
        );

        let normalisedPosition = [
            cameraPosition[0] / cameraPosition[3],
            cameraPosition[1] / cameraPosition[3],
            cameraPosition[2] / cameraPosition[3],
        ];

        let canvasRect = this.canvas.getBoundingClientRect();

        let screenCoords = [
            this.canvas.offsetWidth * (normalisedPosition[0] + 1) / 2 + canvasRect.left,
            this.canvas.offsetHeight * (-normalisedPosition[1] + 1) / 2 + canvasRect.top,
            cameraPosition[2],
        ];

        return {
            screenPosition: screenCoords,
            normalisedPosition: normalisedPosition,
        };
    }

    getModelCoordinates(cameraX, cameraY) {
        let cameraPos = vec4.create();

        cameraPos[0] = cameraX;
        cameraPos[1] = cameraY;
        cameraPos[2] = -1.0;
        cameraPos[3] = 1.0;

        let p1 = vec4.transformMat4(vec4.create(), cameraPos, this.projectionMatrixInverse);

        cameraPos[2] = 1.0;

        let p2 = vec4.transformMat4(vec4.create(), cameraPos, this.projectionMatrixInverse);

        p1[0] /= -p1[3];
        p1[1] /= -p1[3];
        p1[2] /= p1[3];
        p1[3] /= p1[3];

        p2[0] /= -p2[3];
        p2[1] /= -p2[3];
        p2[2] /= p2[3];
        p2[3] /= p2[3];

        p1[0] += this.modelPos[0];
        p1[1] += this.modelPos[1];
        p1[2] += this.modelPos[2];

        p2[0] += this.modelPos[0];
        p2[1] += this.modelPos[1];
        p2[2] += this.modelPos[2];

        return [
            [p1[0], p1[1], p1[2]],
            [p2[0], p2[1], p2[2]],
        ];
    }

    getSphereIntersection(points) {
        let dot = (u, v) => u[0] * v[0] + u[1] * v[1] + u[2] * v[2];
        let diff = (u, v) => [u[0] - v[0], u[1] - v[1], u[2] - v[2]];
        let unit = v => {
            let len = Math.sqrt(dot(v, v));
            return [
                v[0] / len,
                v[1] / len,
                v[2] / len,
            ];
        };

        let o = points[0];
        let l = unit(diff(points[1], points[0]));

        let b = dot(l, o);
        let c = dot(o, o) - 1;

        let det = b ** 2 - c;

        if (det < 0) {
            return null;
        } else if (det === 0) {
            return [
                o[0] - b * l[0],
                o[1] - b * l[1],
                o[2] - b * l[2],
            ];
        } else {
            let d1 = (-b + Math.sqrt(det));
            let d2 = (-b - Math.sqrt(det));

            let p1 = [
                o[0] + d1 * l[0],
                o[1] + d1 * l[1],
                o[2] + d1 * l[2],
            ];

            let p2 = [
                o[0] + d2 * l[0],
                o[1] + d2 * l[1],
                o[2] + d2 * l[2],
            ];

            if (p1[2] > p2[2]) {
                return p1;
            } else {
                return p2;
            }
        }
    }

    applyRotation = point => {
        let vec = vec4.fromValues(...point, 1.0);

        return vec4.transformMat4(vec4.create(), vec, this.modelMatrixInverse);
    }

    cartesianToPolar = point => {
        let phi = -(180 * Math.atan2(point[2], point[0]) / Math.PI - 90);
        let theta = 180 * Math.acos(point[1]) / Math.PI - 90;

        while (theta[0] < -90) {
            theta[0] += 360;
        }

        while (theta[0] > 90) {
            theta[0] -= 360;
        }

        while (phi < -180) {
            phi += 360;
        }

        while (phi > 180) {
            phi -= 360;
        }

        return [phi, theta];
    }

    getContinent = point => {
        for (let continent of this.data.continents) {
            for (let borders of continent.geometry.coordinates) {
                for (let border of borders) {
                    if (inPolygon(border, point) < 1) {
                        return continent;
                    }
                }
            }
        }

        return null;
    }

    setContinent(continent) {
        if (continent) {
            if (!this.state.continent || continent.properties.id !== this.state.continent.properties.id) {
                this.setState({ continent });
                if (!this.dragged) {
                    this._setCursor("pointer");
                }
            }
        } else {
            if (this.state.continent !== null) {
                this.setState({ continent: null });
            }
            if (!this.dragged) {
                this._setCursor("grab");
            }
        }
    }

    _setCursor = (cursor) => {
        /*
         * Warning!!! This sets the class name directly, outside of the react lifecycle.
         * This could lead to bugs or unexpected behaviour!
         * This is not using the react setState as this page is already very JS heavy,
         * so it is probably best to avoid react re-renders every frame.
         */
        this.canvas.className = this.canvas.className.replace(/cursor-[a-z]+/, `cursor-${cursor}`);
    }

    setCanvasWidth(wrapper) {
        if (!wrapper || !this.canvas) {
            return;
        }

        this.wrapper = wrapper;

        let width = wrapper.offsetWidth;
        let height = wrapper.offsetHeight;

        let size = Math.min(width, height);

        if (this.canvas.height === size) {
            return;
        }

        this.canvas.style.height = size + "px";
        this.canvas.style.width = size + "px";

        this.canvas.width = size;
        this.canvas.height = size;

        if (this.gl) {
            let gl = this.gl;
            gl.viewport(0, 0, this.canvas.width, this.canvas.height);

            this.projectionMatrix = mat4.perspective(
                mat4.create(),
                75,
                this.canvas.width / this.canvas.height,
                0.1,
                1000
            );
        }
    }
}

export default Globe;

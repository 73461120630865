import React from 'react';
import { Col } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFile } from "@fortawesome/pro-light-svg-icons";
import { faGlobe } from "@fortawesome/pro-light-svg-icons";
import { faStar } from "@fortawesome/pro-solid-svg-icons";

class ResourcesFile extends React.Component {
    render() {
        let entryPoint = process.env.REACT_APP_API_ENTRYPOINT;

        if (!this.props.resource.file && !this.props.resource.url) {
            return null;
        }

        let hasUrl = (this.props.resource.url ? true : false);
        let fileUrl = (hasUrl ? this.props.resource.url : entryPoint + "/resource/serve-file/" + this.props.resource.id + "/" + this.props.resource.file.filePath)

        return (
            <Col key={`resource-${this.props.index}`} className="p-2" xl="12" xs="12">
                <div className="resource mb-1 p-3">
                    <a target="_blank" className="stretched-link" href={fileUrl} rel="noopener noreferrer">
                        <FontAwesomeIcon icon={(hasUrl ? faGlobe : faFile)} className="mr-2" /> {this.props.resource.title}
                        {this.props.resource.resourceCategory && 
                            <div className="resource-category">
                                {this.props.resource.resourceCategory.title}
                            </div>
                        }
                    </a>
                    {this.props.user && 
                        <div 
                            className={'resource__user-favourite' + (this.props.isFavourite === true ? ' fav' : '' )} 
                            onClick={() => this.props.onFavourite(this.props.resource.id)}
                        >
                            <FontAwesomeIcon icon={faStar} className="mr-2" />
                        </div>
                    }
                </div>
            </Col>
        );
    }
};

export default ResourcesFile;
import React from 'react';
import { Link } from 'react-router-dom';
import { Alert, Form, Button } from "react-bootstrap";
import { Editor } from '@tinymce/tinymce-react';
import Loading from '../Loading';
import Axios from 'axios';
import RelationshipInput from '../Relationships/RelationshipInput';
import BackToAccount from './BackToAccount';
import Api from '../../Api';

class UpdateUserProfile extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            backgroundInformation: this.props.user.backgroundInformation,
            success: null,
            loading: false,
            relationships: [],
            toDelete: [],
            toAdd: [],
            selectedProfiles: [],
            userRelationships: [],
            userRelationshipsByType: [],
        }

        this.scrollRef = React.createRef();
    }

    componentDidMount() {
        this.getUserRelationships();
    }

    scrollTo = () => {
        this.scrollRef.current.scrollIntoView({ behavior: 'smooth' });
    }

    handleEditorChange = (content, editor) => {
        this.setState({ backgroundInformation: content });
    }

    setRelationships = (relationships) => {
        this.setState({ relationships: relationships })
    }

    setToAdd = (profiles) => {
        let combinedProfiles = this.state.toAdd.concat(profiles);
        let uniqueProfiles = [...new Set(combinedProfiles)];
        this.setState({ toAdd: uniqueProfiles });
    }

    setSelectedProfiles = (profiles) => {
        let combinedProfiles = this.state.selectedProfiles.concat(profiles);
        let uniqueProfiles = [...new Set(combinedProfiles)];
        this.setState({ selectedProfiles: uniqueProfiles });
    }

    toggleDelete = (id) => {
        if (this.state.toDelete.includes(id)) {
            const index = this.state.toDelete.indexOf(id);
            let toDelete = this.state.toDelete.splice(index, 1);
        } else {
            let toDelete = this.state.toDelete.push(id);
        }

        this.setState({ toDelete: this.state.toDelete })
    }

    getUserRelationships = () => {
        Api.currentUserRelationships().then(response => {
            this.setState({ 
                userRelationships: response.relationships,
                userRelationshipsByType: response.relationshipsByType
            })
        });
    }

    handleSubmit = (event) => {
        const thisForm = event.currentTarget;

        event.preventDefault();
        event.stopPropagation();

        thisForm.className += " was-validated";

        if (thisForm.checkValidity() !== false) {
            const FormData = require('form-data');
            const form = new FormData();

            form.append('background_information', this.state.backgroundInformation);
            form.append('relationships', this.state.relationships);
            form.append('toDelete', this.state.toDelete);

            let entryPoint = process.env.REACT_APP_API_ENTRYPOINT;
            this.setState({ loading: true });

            Axios.post(entryPoint + '/network/update-user-profile', form, {
                headers: {
                    authorization: 'Bearer ' + window.localStorage.getItem('token')
                }
            })
                .then(response => {
                    this.props.loginRefresh();
                    this.getUserRelationships();

                    this.setState({ 
                        success: true,
                        loading: false,
                        toDelete: [],
                        toAdd: [],
                        selectedProfiles: [],
                    });

                    this.scrollTo()
                })
                .catch(error => {
                    this.setState({ 
                        success: false, 
                        loading: false,
                    });

                    this.scrollTo()
                })
            ;
        }
    }

    render() {
        return (
            <div className="user-profile-form" ref={this.scrollRef}>
                {this.state.success === true &&
                    <Alert className="mb-4" variant="success" onClose={() => (this.setState({success: null}))}>
                        <Alert.Heading>Your Profile Has Been Updated!</Alert.Heading>
                        <p><Link to="/account">Back to account home</Link></p>
                    </Alert>
                }

                {this.state.success === false &&
                    <Alert className="mb-4" variant="danger" onClose={() => (this.setState({success: null}))} dismissible>
                        <Alert.Heading>Oh snap! You got an error!</Alert.Heading>
                        <p>There was a problem submitting the form</p>
                    </Alert>
                }

                <Form noValidate className="update-profile-form my-auto needs-validation" onSubmit={this.handleSubmit} autoComplete="off" id="update-profile-form">
                    <Form.Group controlId="update-profile-background">
                        <Form.Label>Background Information *</Form.Label>
                        <Editor
                            init={{
                                height: 250,
                                menubar: false,
                                plugins: [
                                    'advlist autolink lists link image charmap print preview anchor',
                                    'searchreplace visualblocks code fullscreen',
                                    'insertdatetime media table paste code help wordcount'
                                ],
                                toolbar:
                                    'undo redo | formatselect | bold italic backcolor | \
                                    alignleft aligncenter alignright alignjustify | \
                                    bullist numlist outdent indent | removeformat | link'
                            }}
                            initialValue={this.props.user.backgroundInformation}
                            onEditorChange={this.handleEditorChange}
                        />
                    </Form.Group>

                    <RelationshipInput 
                        user={this.props.user} 
                        setRelationships={this.setRelationships} 
                        toggleDelete={this.toggleDelete}
                        toDelete={this.state.toDelete}
                        toAdd={this.state.toAdd}
                        setToAdd={this.setToAdd}
                        selectedProfiles={this.state.selectedProfiles}
                        setSelectedProfiles={this.setSelectedProfiles}
                        displayAssignedProfiles={true}
                        userRelationships={this.state.userRelationships}
                        userRelationshipsByType={this.state.userRelationshipsByType}
                    />

                    <Button className="mx-0 mt-5 mb-4 font-smooth text-white submit-btn"
                        variant="ternary"
                        size="md"
                        type="submit"
                    >
                        {this.state.loading === true && 
                            <Loading size="1x" />
                        }
                        {this.state.loading === false && 
                            <>Update Profile</>
                        }
                    </Button>
                </Form>

                <BackToAccount />                
            </div>
        );
    }
}

export default UpdateUserProfile;
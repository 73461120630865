import React from 'react';
import Api from "../Api";

class SearchPopular extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            popular: [],
        }
    }

    componentDidMount() {
        Api.popularSearches(this.props.searchType)
        .then(response => {
            this.setState({ popular: response.results });
        });
    }

    handleClick = (searchTerm) => {
        this.props.addCriteria(searchTerm);
    }

    render() {
        return (
            <>
                {this.state.popular.length > 0 && 
                    <div className="search-popular">
                        <h4 className="mt-5 mb-4">Popular Searches</h4>
                        <ul className="pill-list list-inline mb-5">
                            {this.state.popular.map((item, index) => (
                                <li key={index} className="list-inline-item mb-2" onClick={() => this.handleClick(item)}>
                                    <span>{item.title}</span>
                                </li>
                            ))}
                        </ul>
                    </div>
                    
                }
            </>
        );
    }
}

export default SearchPopular;
import React from 'react';
import { Button, Col, Form, Alert } from "react-bootstrap";
import axios from "axios";
import { faCircleNotch } from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
    
class ContactPage extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            first_name: (this.props.user ? this.props.user.first_name : ''),
            last_name: (this.props.user ? this.props.user.surname : ''),
            email: (this.props.user ? this.props.user.email : ''),
            message: '',
            consent: false,
            success: null,
            loading: false,
            wasValidated: false,
        }
    }

    handleSubmit = (event) => {
        const thisForm = event.currentTarget;

        event.preventDefault();
        event.stopPropagation();
        
        this.setState({ wasValidated: true });

        if (thisForm.checkValidity() !== false) {
            const FormData = require('form-data');
            const form = new FormData();

            form.append('first_name', this.state.first_name);
            form.append('last_name', this.state.last_name);
            form.append('email', this.state.email);
            form.append('message', this.state.message);
            form.append('consent', this.state.consent);

            let entryPoint = process.env.REACT_APP_API_ENTRYPOINT;

            this.setState({ loading: true });
            
            axios.post(entryPoint + '/contact/send-form', form, {
                headers: {
                    'Content-Type': 'multipart/form-data',
                }
            })
                .then(() => {
                    this.setState({
                        first_name: '',
                        last_name: '',
                        email: '',
                        message: '',
                        consent: false,
                        loading: false,
                        success: true,
                        wasValidated: false
                    })       
                })
                .catch(error => {
                    this.setState({ loading: false, success: false })
                    console.log(error);
                })
            ;
        }
    }

    handleInputChange = (event) => {
        const target = event.target;
        const fieldValue = target.type === 'checkbox' ? target.checked : target.value;
        const fieldName = target.name;

        this.setState({
            [fieldName]: fieldValue
        });
    }

    render() {
        return (
            <>
                <div className="d-flex flex-column h-100">
                    {this.state.success === true &&
                        <Alert className="mb-4" variant="success" onClose={() => (this.setState({success: null}))}>
                            <Alert.Heading>Thank you!</Alert.Heading>
                            <p>Thank you for your message. We'll be in touch soon.</p>
                        </Alert>
                    }

                    {this.state.success === false &&
                        <Alert className="mb-4" variant="danger" onClose={() => (this.setState({success: null}))} dismissible>
                            <Alert.Heading>Oh snap! You got an error!</Alert.Heading>
                            <p>There was an error with your submission, please try again.</p>
                        </Alert>
                    }
                    <Form 
                        className={"contact-form my-auto needs-validation" + (this.state.wasValidated ? ' was-validated' : '' )} 
                        noValidate 
                        onSubmit={this.handleSubmit} 
                        autoComplete="off" 
                        id="contact-form"
                    >
                            <Form.Group controlId="contact-first-name">
                                <Form.Label>First Name</Form.Label>
                                <Form.Control 
                                    name="first_name" 
                                    type="text" 
                                    value={this.state.first_name} 
                                    onChange={this.handleInputChange} 
                                    required 
                                />
                                <Form.Control.Feedback type="invalid">
                                    Please provide a valid first name.
                                </Form.Control.Feedback>
                            </Form.Group>
                            <Form.Group controlId="contact-last-name">
                                <Form.Label>Last Name</Form.Label>
                                <Form.Control 
                                    name="last_name" 
                                    type="text" 
                                    value={this.state.last_name} 
                                    onChange={this.handleInputChange} 
                                    required 
                                />
                                <Form.Control.Feedback type="invalid">
                                    Please provide a valid last name.
                                </Form.Control.Feedback>
                            </Form.Group>
                        <Form.Group controlId="contact-email">
                            <Form.Label>Email address</Form.Label>
                            <Form.Control 
                                name="email" 
                                type="email" 
                                value={this.state.email} 
                                onChange={this.handleInputChange} 
                                required
                            />
                            <Form.Control.Feedback type="invalid">
                                Please provide a valid email.
                            </Form.Control.Feedback>
                        </Form.Group>
                        <Form.Group controlId="contact-message">
                            <Form.Label>Message</Form.Label>
                            <Form.Control 
                                required 
                                placeholder="Your Message"
                                as="textarea" 
                                rows="5" 
                                value={this.state.message}
                                onChange={this.handleInputChange} 
                                name="message"
                            />
                            <Form.Control.Feedback type="invalid">
                                Please provide a valid message. 
                            </Form.Control.Feedback>
                        </Form.Group>
                        
                        <div className="custom-control custom-checkbox">
                            <input name="consent" type="checkbox" className="custom-control-input" id="contact-terms-checkbox" onChange={this.handleInputChange} required feedback="You must agree before submitting" />
                            <label className="custom-control-label" htmlFor="contact-terms-checkbox">
                                By submitting I consent to the terms and conditions of data collection as stated in the <a href="/privacy-policy" target="_blank">Privacy Policy</a>
                            </label>
                        </div>

                        <Button 
                            className="mx-0 mt-5 mb-4 font-smooth text-white"
                            variant="ternary"
                            size="lg"
                            type="submit"
                            block
                        >
                            {this.state.loading === true && 
                                <FontAwesomeIcon icon={faCircleNotch} size="2x" color="#FF7442" spin />
                            }
                            {this.state.loading === false && 
                                <>Submit</>
                            }  
                        </Button>
                    </Form>
                </div>
            </>
        )
    }
}

export default ContactPage;

import React from 'react';
import { Alert, Form, Button } from "react-bootstrap";
import { faCircleNotch } from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import axios from "axios";
import Api from '../Api';

class CreateAccountForm extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            title: '',
            first_name: '',
            surname: '',
            email_address: '',
            organisation: '',
            affiliation: '',
            organisationType: 'University/Higher Education',
            postCode: '',
            city: '',
            country: '',
            institutionInformation: '',
            unitCapabilities: '',
            fieldResearchUnits: 0,
            fieldResearchUnitsAdditional: '',
            labCapabilities: '',
            funder: '',
            expertise: '',
            vectorsPathogenAccess: '',
            typeOfProfile: 'Policy maker',
            otherProfiles: '',
            website: '',
            researchgate: '',
            twitter: '',
            linkedin: '',
            orcid: '',
            aboutMe: '',
            profilePicture: '',
            password: '',
            consent: false,
            success: null,
            loading: false,
            errorMessage: 'The form could not be submitted. Please check and try again.',
            passwordRequirements: false,
            showPasswordTip: false,
            geoLocation: 'show',
            currentLocation: '',
            countries: [],
        };

        this.checkPassword = this.checkPassword.bind(this);
        this.location = (props.location) ? props.location : '' ;

        this.scrollRef = React.createRef();
    }

    componentDidMount() {
        Api.profiles({ 'order[title]': 'asc', page: 1, perPage: 1000, noRelationships: 1 }, true, true, 40)
            .then((countries) => {
                this.setState({ countries });
            })
        ;
    }

    scrollTo() {
        this.scrollRef.current.scrollIntoView({ behavior: 'smooth' });
    }

    handleInputChange = (event) => {
        const target = event.target;
        const fieldValue = target.type === 'checkbox' ? target.checked : target.value;
        const fieldName = target.name;

        this.setState({
            [fieldName]: fieldValue
        });
    }

    checkPassword() {
        let errorString = '';

        if (this.state.password.length < 8) {
            errorString += 'Must be 8 characters or more. ';
        }

        let uppercase = this.state.password.replace(/[^A-Z]/g, "").length;
        if (!uppercase) {
            errorString += 'Must contain an uppercase character. ';
        }

        let lowercase = this.state.password.replace(/[^a-z]/g, "").length;
        if (!lowercase) {
            errorString += 'Must contain a lowercase character. ';
        }

        let numbers = this.state.password.replace(/[^0-9]/g, "").length;
        if (!numbers) {
            errorString += 'Must contain a number. ';
        }

        let specialChars = this.state.password.replace(/[^!@#$%^&*(),.?":{}|<>]/g, "").length;
        if (!specialChars) {
            errorString += 'Must contain a special character. ';
        }

        this.setState({ passwordRequirements: errorString });
    }

    handleSubmit = (event) => {
        const thisForm = event.currentTarget;

        event.preventDefault();
        event.stopPropagation();

        thisForm.className += " was-validated";

        this.checkPassword();

        if (thisForm.checkValidity() !== false) {
            const FormData = require('form-data');
            const form = new FormData();
            
            form.append('title', this.state.title);
            form.append('first_name', this.state.first_name);
            form.append('surname', this.state.surname);
            form.append('email_address', this.state.email_address);
            form.append('password', this.state.password);
            form.append('consent', this.state.consent);
            form.append('organisation', this.state.organisation);
            form.append('affiliation', this.state.affiliation);
            form.append('organisationType', this.state.organisationType);
            form.append('postCode', this.state.postCode);
            form.append('city', this.state.city);
            form.append('country', this.state.country);
            form.append('institutionInformation', this.state.institutionInformation);
            form.append('unitCapabilities', this.state.unitCapabilities);
            form.append('fieldResearchUnits', this.state.fieldResearchUnits);
            form.append('fieldResearchUnitsAdditional', this.state.fieldResearchUnitsAdditional);
            form.append('labCapabilities', this.state.labCapabilities);
            form.append('funder', this.state.funder);
            form.append('expertise', this.state.expertise);
            form.append('vectorsPathogenAccess', this.state.vectorsPathogenAccess);
            form.append('typeOfProfile', this.state.typeOfProfile);
            form.append('otherProfiles', this.state.otherProfiles);
            form.append('website', this.state.website);
            form.append('researchgate', this.state.researchgate);
            form.append('twitter', this.state.twitter);
            form.append('linkedin', this.state.linkedin);
            form.append('orcid', this.state.orcid);
            form.append('aboutMe', this.state.aboutMe);
            form.append('profilePicture', this.state.profilePicture);
            form.append('geoLocation', this.state.geoLocation);
            form.append('currentLocation', this.state.currentLocation);

            let entryPoint = process.env.REACT_APP_API_ENTRYPOINT;

            this.setState({ loading: true });
            axios.post(entryPoint + '/register/validate-registration', form)
                .then(response => {
                    if (response.data.password.success && response.data.email_address.success && response.data.user) {

                        this.registerOnForum();

                        this.setState({ 
                            success: true, 
                            loading: false,
                            title: '',
                            first_name: '',
                            surname: '',
                            email_address: '',
                            password: '',
                            organisation: '',
                            affiliation: '',
                            organisationType: 'University/Higher Education',
                            postCode: '',
                            city: '',
                            country: '',
                            institutionInformation: '',
                            unitCapabilities: '',
                            fieldResearchUnits: '',
                            fieldResearchUnitsAdditional: '',
                            labCapabilities: '',
                            funder: '',
                            expertise: '',
                            typeOfProfile: 'Policy maker',
                            otherProfiles: '',
                            website: '',
                            researchgate: '',
                            twitter: '',
                            linkedin: '',
                            orcid: '',
                            aboutMe: '',
                            profilePicture: '',
                            consent: false,
                        });
                    } else {
                        this.scrollTo();
                    }

                    if (!response.data.email_address.success) {
                        this.setState({ success: false, errorMessage: response.data.email_address.message, loading: false});
                    }

                    thisForm.className = "network-create-account-form my-auto";
                })
                .catch(error => {
                    this.setState({ 
                        success: false, 
                        loading: false
                    });
                })
            ;
        } else {
            this.scrollTo();
        }

        
    }

    registerOnForum = () => {
        const forumForm = new FormData();

        forumForm.append('username', this.state.email_address);
        forumForm.append('email', this.state.email_address);
        forumForm.append('password', this.state.password);

        let forumUrl = process.env.REACT_APP_FORUM_LINK + '/gvh-register.php';

        axios.post(forumUrl, forumForm)
            .then(response => {

            })
        ;
    }

    uploadFile = (event) => {
        let file = event.target.files[0];
        
        if (file) {
            if (file.size < 1048576) { // no larger than 1mb
                this.setState({ profilePicture: file });
                event.target.setCustomValidity('');
            } else {
                event.target.setCustomValidity('File too large');
            }
        }
    }

    render() {
        return (
            <div ref={this.scrollRef}>
                {this.state.success === true &&
                    <Alert className="mb-4" variant="success" onClose={() => (this.setState({success: null}))}>
                        <Alert.Heading>Thank you!</Alert.Heading>
                        <p>Your account has been created! We've sent you a verification email.</p>
                    </Alert>
                }

                {this.state.success === false &&
                    <Alert className="mb-4" variant="danger" onClose={() => (this.setState({success: null}))} dismissible>
                        <Alert.Heading>Oh snap! You got an error!</Alert.Heading>
                        <p>{this.state.errorMessage}</p>
                    </Alert>
                }
                
                {this.state.success !== true && 
                    <Form noValidate className="network-create-account-form my-auto needs-validation" onSubmit={this.handleSubmit} autoComplete="off" id="register-form" encType="multipart/form-data">
                        <Form.Group controlId="create-account-title">
                            <Form.Label>Title *</Form.Label>
                            <Form.Control name="title" size="md" type="text" value={this.state.title} onChange={this.handleInputChange} required />
                            <Form.Control.Feedback type="invalid">
                                Please provide a valid title.
                            </Form.Control.Feedback>
                        </Form.Group>

                        <Form.Group controlId="create-account-first-name">
                            <Form.Label>First Name *</Form.Label>
                            <Form.Control name="first_name" size="md" type="text" value={this.state.first_name} onChange={this.handleInputChange} required />
                            <Form.Control.Feedback type="invalid">
                                Please provide a valid first name.
                            </Form.Control.Feedback>
                        </Form.Group>

                        <Form.Group controlId="create-account-surname">
                            <Form.Label>Surname *</Form.Label>
                            <Form.Control name="surname" size="md" type="text" value={this.state.surname} onChange={this.handleInputChange} required />
                            <Form.Control.Feedback type="invalid">
                                Please provide a valid surname.
                            </Form.Control.Feedback>
                        </Form.Group>

                        <Form.Group controlId="create-account-email-address">
                            <Form.Label>Email Address *</Form.Label>
                            <Form.Control name="email_address" size="md" type="text" value={this.state.email_address} onChange={this.handleInputChange} required />
                            <Form.Control.Feedback type="invalid">
                                Please provide a valid email.
                            </Form.Control.Feedback>
                        </Form.Group>

                        <Form.Group controlId="create-account-organisation">
                            <Form.Label>Organisation *</Form.Label>
                            <Form.Control name="organisation" size="md" type="text" value={this.state.organisation} onChange={this.handleInputChange} required />
                            <Form.Control.Feedback type="invalid">
                                Please provide a valid organisation.
                            </Form.Control.Feedback>
                        </Form.Group>

                        <Form.Group controlId="create-account-affiliation">
                            <Form.Label>Affiliation *</Form.Label>
                            <Form.Control name="affiliation" size="md" type="text" value={this.state.affiliation} onChange={this.handleInputChange} required />
                            <Form.Control.Feedback type="invalid">
                                Please provide a valid affiliation.
                            </Form.Control.Feedback>
                        </Form.Group>

                        <Form.Group controlId="create-account-organisation-type">
                            <Form.Label>Organisation Type *</Form.Label>
                            <Form.Control name="organisationType" size="md" as="select" value={this.state.organisationType} onChange={this.handleInputChange} required>
                                <option>University/Higher Education</option>
                                <option>Research Institute</option>
                                <option>Public Health Agency</option>
                                <option>Private Company</option>
                                <option>Professional Network</option>
                                <option>Funding Agency</option>
                                <option>News Outlet/Journal/Publisher</option>
                                <option>Other</option>
                            </Form.Control>
                            <Form.Control.Feedback type="invalid">
                                Please provide a valid organisation Type.
                            </Form.Control.Feedback>
                        </Form.Group>

                        <Form.Group controlId="create-account-postcode">
                            <Form.Label>Post Code</Form.Label>
                            <Form.Control name="postCode" size="md" type="text" value={this.state.postCode} onChange={this.handleInputChange} />
                            <Form.Control.Feedback type="invalid">
                                Please provide a valid post code.
                            </Form.Control.Feedback>
                        </Form.Group>

                        <Form.Group controlId="create-account-city">
                            <Form.Label>City</Form.Label>
                            <Form.Control name="city" size="md" type="text" value={this.state.city} onChange={this.handleInputChange} />
                            <Form.Control.Feedback type="invalid">
                                Please provide a valid city.
                            </Form.Control.Feedback>
                        </Form.Group>

                        <Form.Group controlId="create-account-country">
                            <Form.Label>Country *</Form.Label>
                            <Form.Control name="country" size="md" as="select" value={this.state.country} onChange={this.handleInputChange} required>
                                <option value=""></option>
                                {this.state.countries.map(country => (
                                    <option value={country.id}>{country.title}</option>
                                ))}
                            </Form.Control>
                            <Form.Control.Feedback type="invalid">
                                Please provide a valid country.
                            </Form.Control.Feedback>
                        </Form.Group>

                        <Form.Group controlId="create-account-institution-information">
                            <Form.Label>Institution Information</Form.Label>
                            <Form.Control name="institutionInformation" size="md" rows="3" as="textarea" value={this.state.institutionInformation} onChange={this.handleInputChange} />
                            <Form.Control.Feedback type="invalid">
                                Please provide a valid institution information.
                            </Form.Control.Feedback>
                        </Form.Group>

                        <Form.Group controlId="create-account-unit-capabilities">
                            <Form.Label>Unit Capabilities</Form.Label>
                            <Form.Control name="unitCapabilities" size="md" rows="3" as="textarea" value={this.state.unitCapabilities} onChange={this.handleInputChange} />
                            <Form.Control.Feedback type="invalid">
                                Please provide a valid Unit capabilities.
                            </Form.Control.Feedback>
                        </Form.Group>

                        <Form.Group controlId="create-account-field-research-units" id="create-account-field-research-units">
                            <Form.Label>Field Research Units</Form.Label>
                            <Form.Check inline name="fieldResearchUnits" type="radio" label="Yes" value="1" onChange={this.handleInputChange} />
                            <Form.Check inline name="fieldResearchUnits" type="radio" label="No" value="0" onChange={this.handleInputChange} />
                        </Form.Group>

                        <Form.Group controlId="create-account-field-research-units-additional">
                            <Form.Label>Field Research Units Additional Information</Form.Label>
                            <Form.Control name="fieldResearchUnitsAdditional" size="md" rows="3" as="textarea" value={this.state.fieldResearchUnitsAdditional} onChange={this.handleInputChange} />
                            <Form.Control.Feedback type="invalid">
                                Please provide valid field research units additional information.
                            </Form.Control.Feedback>
                        </Form.Group>

                        <Form.Group controlId="create-account-lab-capabilities">
                            <Form.Label>Lab Capabilities for Diagnosis for Vector-borne Diseases</Form.Label>
                            <Form.Control name="labCapabilities" size="md" rows="3" as="textarea" value={this.state.labCapabilities} onChange={this.handleInputChange} />
                            <Form.Control.Feedback type="invalid">
                                Please provide valid lab capabilities.
                            </Form.Control.Feedback>
                        </Form.Group>

                        <Form.Group controlId="create-account-funder">
                            <Form.Label>Funder</Form.Label>
                            <Form.Control name="funder" size="md" type="text" value={this.state.funder} onChange={this.handleInputChange} />
                            <Form.Control.Feedback type="invalid">
                                Please provide a valid funder.
                            </Form.Control.Feedback>
                        </Form.Group>

                        <Form.Group controlId="create-account-expertise">
                            <Form.Label>Expertise *</Form.Label>
                            <Form.Control name="expertise" size="md" type="text" value={this.state.expertise} onChange={this.handleInputChange} required />
                            <Form.Control.Feedback type="invalid">
                                Please provide valid expertise.
                            </Form.Control.Feedback>
                        </Form.Group>

                        <Form.Group controlId="create-account-field-vectors-pathogen-access">
                            <Form.Label>Which vectors and pathogens do you have access to?</Form.Label>
                            <Form.Control name="vectorsPathogenAccess" size="md" rows="3" as="textarea" value={this.state.vectorsPathogenAccess} onChange={this.handleInputChange} />
                            <Form.Control.Feedback type="invalid">
                                Please provide valid vectors and pathogens access information.
                            </Form.Control.Feedback>
                        </Form.Group>

                        <Form.Group controlId="create-account-type-of-profile">
                            <Form.Label>Type of Profile *</Form.Label>
                            <Form.Control name="typeOfProfile" size="md" as="select" value={this.state.typeOfProfile} onChange={this.handleInputChange} required>
                                <option>Policy maker</option>
                                <option>Student</option>
                                <option>Funder</option>
                                <option>Researcher</option>
                                <option>General</option>
                                
                            </Form.Control>
                            <Form.Control.Feedback type="invalid">
                                Please provide a valid profile type.
                            </Form.Control.Feedback>
                        </Form.Group>

                        <Form.Group controlId="create-account-other-profiles">
                            <Form.Label>Other Profiles</Form.Label>
                            <Form.Control name="otherProfiles" size="md" rows="3" as="textarea" value={this.state.otherProfiles} onChange={this.handleInputChange} />
                            <Form.Control.Feedback type="invalid">
                                Please provide valid other profiles.
                            </Form.Control.Feedback>
                        </Form.Group>

                        <Form.Group controlId="create-account-website">
                            <Form.Label>Website/URL</Form.Label>
                            <Form.Control name="website" size="md" type="text" value={this.state.website} onChange={this.handleInputChange} />
                            <Form.Control.Feedback type="invalid">
                                Please provide a valid website.
                            </Form.Control.Feedback>
                        </Form.Group>

                        <Form.Group controlId="create-account-researchgate">
                            <Form.Label>ResearchGate Profile URL</Form.Label>
                            <Form.Control name="researchgate" size="md" type="text" value={this.state.researchgate} onChange={this.handleInputChange} />
                            <Form.Control.Feedback type="invalid">
                                Please provide a valid ResearchGate URL.
                            </Form.Control.Feedback>
                        </Form.Group>

                        <Form.Group controlId="create-account-twitter">
                            <Form.Label>Twitter Profile URL</Form.Label>
                            <Form.Control name="twitter" size="md" type="text" value={this.state.twitter} onChange={this.handleInputChange} />
                            <Form.Control.Feedback type="invalid">
                                Please provide a valid Twitter URL.
                            </Form.Control.Feedback>
                        </Form.Group>

                        <Form.Group controlId="create-account-linkedin">
                            <Form.Label>LinkedIn Profile URL</Form.Label>
                            <Form.Control name="linkedin" size="md" type="text" value={this.state.linkedin} onChange={this.handleInputChange} />
                            <Form.Control.Feedback type="invalid">
                                Please provide a valid LinkedIn URL.
                            </Form.Control.Feedback>
                        </Form.Group>

                        <Form.Group controlId="create-account-orcid">
                            <Form.Label>ORCID Profile URL</Form.Label>
                            <Form.Control name="orcid" size="md" type="text" value={this.state.orcid} onChange={this.handleInputChange} />
                            <Form.Control.Feedback type="invalid">
                                Please provide a valid ORCID URL.
                            </Form.Control.Feedback>
                        </Form.Group>

                        <Form.Group controlId="create-account-about-me">
                            <Form.Label>About me</Form.Label>
                            <Form.Control name="aboutMe" size="md" rows="3" as="textarea" value={this.state.aboutMe} onChange={this.handleInputChange} />
                            <Form.Control.Feedback type="invalid">
                                Please provide vaild information about you.
                            </Form.Control.Feedback>
                        </Form.Group>

                        <Form.Group controlId="create-account-profile-picture">
                            <Form.Label>Profile Picture</Form.Label>
                            <Form.Control name="profilePicture" type="file" accept=".jpg,.jpeg,gif,.png" onChange={this.uploadFile} />
                            <Form.Control.Feedback type="invalid">
                                Please upload a valid .jpg, .jpeg, gif or .png file that is no larger than 1mb.
                            </Form.Control.Feedback>
                            <Form.Text id="profile-picture-help">
                                Please upload a valid .jpg, .jpeg, gif or .png file that is no larger than 1mb.
                            </Form.Text>
                        </Form.Group>
                        
                        <Form.Group controlId="create-account-password">
                            <Form.Label>Password *</Form.Label>
                            <Form.Control 
                                pattern="^(?=.{8,})(?=.*[a-z])(?=.*[0-9])(?=.*[A-Z])(?=.*[@#$%^&+=!?]).*$" 
                                name="password" 
                                size="md" 
                                type="password" 
                                value={this.state.password} 
                                onChange={this.handleInputChange} 
                                required 
                            />
                            <Form.Control.Feedback type="invalid">
                                {this.state.passwordRequirements}
                            </Form.Control.Feedback>
                        </Form.Group>

                        <h3>Your Location</h3>
                        <p>Show a set location, a dynamic location or hide your location from other users on your profile.</p>
                        <ul>
                            <li><strong>Show Set Location</strong> - Manually enter your location.</li>
                            <li><strong>Hide Location</strong> - Don't show any location on your profile.</li>
                            <li><strong>Show Dynamic Location</strong> - Every time you login, get your location using your IP address (not 100% accurate).</li>
                        </ul>


                        <Form.Group controlId="update-profile-geo-location">
                            <Form.Label>Geo Location *</Form.Label>
                            <Form.Control name="geoLocation" size="md" as="select" value={this.state.geoLocation} onChange={this.handleInputChange} required>
                                <option value="show">Show Set Location</option>
                                <option value="hide">Hide Location</option>
                                <option value="dynamic">Dynamic Location</option>

                            </Form.Control>
                            <Form.Control.Feedback type="invalid">
                                Please provide a valid selection.
                            </Form.Control.Feedback>
                        </Form.Group>

                        {this.state.geoLocation === 'show' &&
                            <Form.Group controlId="update-profile-current-location">
                                <Form.Label>Current Location</Form.Label>
                                <Form.Control name="currentLocation" size="md" type="text" value={this.state.currentLocation} onChange={this.handleInputChange} />
                                <Form.Control.Feedback type="invalid">
                                    Please provide a valid location.
                                </Form.Control.Feedback>
                            </Form.Group>
                        }

                        <div className="custom-control custom-checkbox">
                            <input name="consent" type="checkbox" className="custom-control-input" id={`${this.location}-create-account-terms-checkbox`} onChange={this.handleInputChange} required feedback="You must agree before submitting" />
                            <label className="custom-control-label" htmlFor={`${this.location}-create-account-terms-checkbox`}>
                                By signing up I consent to the terms and conditions of data collection as stated in the <a href="/privacy-policy" target="_blank">Privacy Policy</a>
                            </label>
                        </div>

                        <Button className="mx-0 mt-5 mb-4 font-smooth text-white"
                            variant="ternary"
                            size="lg"
                            type="submit"
                            block>
                                {this.state.loading === true && 
                                    <FontAwesomeIcon icon={faCircleNotch} size="6x" color="#FF7442" spin />
                                }
                                {this.state.loading === false && 
                                    <>Create Account</>
                                }
                        </Button>
                    </Form>
                }                
            </div>
        )
    }
}

export default CreateAccountForm;

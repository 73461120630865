import React from 'react';
import PopUp from './PopUp';

class CoursePopUp extends React.Component {
    render () {
        let course = this.props.content;
        let modalContent = (
            <div className="courses-data">
                <div>
                    {course.type &&
                        <>
                            <h4>Type</h4>
                            <p>{course.type}</p>
                        </>
                    }
                </div>
                <div>
                    {course.modality &&
                        <>
                            <h4>Modality</h4>
                            <p>{course.modality}</p>
                        </>
                    }
                </div>
                <div>
                    {course.country &&
                        <>
                            <h4>Country</h4>
                            <p>{course.country}</p>
                        </>
                    }
                </div>
                <div>
                    {course.name &&
                        <>
                            <h4>Name</h4>
                            <p>
                                {course.link &&
                                    <a href={course.link} target="_blank" rel="noopener noreferrer">
                                        {course.name}
                                    </a>
                                }

                                {!course.link &&
                                    <div dangerouslySetInnerHTML={{ __html: course.name }} />
                                }
                            </p>
                        </>
                    }
                </div>
                <div>
                    {course.institutionAddress &&
                        <>
                            <h4>Institution</h4>
                            <div dangerouslySetInnerHTML={{ __html: course.institutionAddress.replace(/\n/g, '<br />') }}/>
                        </>
                    } 
                </div>
                <div>
                    {course.language &&
                        <>
                            <h4>Course Language</h4>
                            <p>{course.language}</p>
                        </>
                    }
                </div>
                <div>
                    {course.courseOutline &&
                        <>
                            <h4>Course Outline</h4>
                            <div dangerouslySetInnerHTML={{ __html: course.courseOutline.replace(/\n/g, '<br />') }}/>
                        </>
                    }  
                </div>
                <div>
                    {course.observations &&
                        <>
                            <h4>Contacts</h4>
                            <div dangerouslySetInnerHTML={{ __html: course.observations.replace(/\n/g, '<br />') }}/>
                        </>
                    }
                </div>
                <div>
                    {course.instructor &&
                        <>
                            <h4>Instructor</h4>
                            <p>{course.instructor}</p>
                        </>
                    }
                </div>
                <div>
                    {course.date &&
                        <>
                            <h4>Date</h4>
                            <p>{course.date}</p>
                        </>
                    }
                </div>
                <div>
                    {course.duration &&
                        <>
                            <h4>Duration</h4>
                            <p>{course.duration}</p>
                        </>
                    }
                </div>
                <div>
                    {course.assessments &&
                        <>
                            <h4>Assessments</h4>
                            <div dangerouslySetInnerHTML={{ __html: course.assessments.replace(/\n/g, '<br />') }}/>
                        </>
                    }
                </div>
                <div>
                    {course.quota &&
                        <>
                            <h4>Class Limit</h4>
                            <p>{course.quota}</p>
                        </>
                    }
                    </div>
                <div>
                    {course.status &&
                        <>
                            <h4>Status</h4>
                            <p>{course.status}</p>
                        </>
                    }
                    </div>
                <div>
                    {course.cost &&
                        <>
                            <h4>Cost (course fee)</h4>
                            <p>{course.cost}</p>
                        </>
                    }
                </div>
                <div>
                    {course.keywords &&
                        <>
                            <h4>Keywords</h4>
                            <div dangerouslySetInnerHTML={{ __html: course.keywords.replace(/\n/g, '<br />') }}/>
                        </>
                    }
                </div>
            </div>
        );

        return (
            <PopUp size="lg" header={<h3>{course.modalTitle || course.name}</h3>} className="course-modal" content={modalContent} open={this.props.show} close={this.props.close} />
        )
    }
}

export default CoursePopUp;
import React, { useState, useEffect } from "react";

import { Link } from "react-router-dom";

const LEFT_ENABLED = false;

const NewsItem = props => {
    let [position, setPosition] = useState([0, 0, 0]);
    let [normalisedPosition, setNormalisedPosition] = useState([0, 0, 0]);

    useEffect(() => {
        props.registerPositionProvider(positionProvider => {
            // let [lat, long] = props.item.location;
            let lat = props.item.latitude;
            let long = props.item.longitude;
            let { screenPosition, normalisedPosition } = positionProvider(lat, long);

            setPosition(screenPosition);
            setNormalisedPosition(normalisedPosition);
        });

        return () => {
            props.removePositionProvider();
        };
    }, []);

    let left = LEFT_ENABLED && normalisedPosition[0] > 0;
    let transform = position[2] > 5 ?
        `translate(${left ? "-100%" : "0"}) scale(0.0)` :
        `translate(${left ? "-100%" : "0"}) scale(1.0)`;

    return (
        <>
        <div
            style={{
                left: 0,
                top: 0,
                transform: `translate(${position[0] - 5}px, ${position[1] - 5}px)`,
                width: position[2] > 5 ? 0: 10,
                height: position[2] > 5 ? 0: 10,
                visibility: position[2] > 5 ? 'hidden' : 'visible',
            }}
            className="position-fixed bg-primary rounded-circle pulse"
        >
        <Link
            style={{
                width: position[2] > 5 ? 0: 30,
                height: position[2] > 5 ? 0: 30,
                left: -10,
                top: -10,
                // opacity: 0.3,
            }}
            className="position-relative d-block rounded-circle no-select"
            to={`/news/${props.item.id}`}
        />
        </div>
        <div
            style={{
                left: position[0],
                top: position[1] - 60 / 2,
                marginLeft: left ? -20 : 20,
                width: '300px',
                height: 85,
                transform: transform,
                opacity: position[2] > 5 ? 0 : 1,
                transition: "all 0.2s ease",
                transitionProperty: "transform, opacity",
                transformOrigin: left ? "right" : "left",
            }}
            className="position-fixed text-decoration-none news-tag"
        >
            <Link
                className="news-item-link py-2 px-3 rounded d-block"
                style={{backgroundColor: "rgba(22, 26, 47, 0.8)"}}
                to={`/news/${props.item.id}`}
            >
                <h4 className="text-primary m-0">
                    {props.item.title}
                </h4>
                <div className="text-secondary">
                    {props.item.region}
                </div>
            </Link>
        </div>
        </>
    );
};

export default NewsItem;

import React from 'react';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimes } from "@fortawesome/pro-light-svg-icons";

class InstitutionListItem extends React.Component {

    handleClick = (id) => {
        this.props.manageDelete(id);
    }

    render() {
        let toDelete = false;

        if (this.props.toDelete.indexOf(this.props.institution.id) !== -1) {
            toDelete = true;
        }

        return (
            <div  className={"institutions__list-item" + (toDelete ? " to-delete" : "" )}>
                {this.props.institution.title}
                <div className="institutions__list-remove">
                    <FontAwesomeIcon icon={faTimes} onClick={() => this.handleClick(this.props.institution.id)} />
                </div>
            </div>
        );
    }
}

export default InstitutionListItem;
import React, { useEffect, useLayoutEffect, useState, createContext } from "react";
import { withRouter } from "react-router-dom";

import CountryProvider from "../GlobeDataContext";
import Globe from "./Globe";
import Loading from "./Loading";
import ProgressBar from "./ProgressBar";
import NotFoundPage from '../pages/NotFoundPage';

const WindowWidthContext = createContext();

const getGlobe = (props, width, usedWidth) => {
    if (Math.abs(usedWidth) >= width) {
        return null;
    }
   
    return <CountryProvider render={data => {
        if (!data.done) {
            return <div
            className="globe-progress-wrapper"
            style={{
                left: usedWidth > 0 ? usedWidth : 0,
                right: usedWidth < 0 ? -usedWidth : 0
            }}
            >
            {data.hasLoader ? <ProgressBar data={data} /> : <Loading />}
            </div>;
        } else {
            return <Globe
                {...props}
                setContinent={slug => props.history.push(slug)}
                globe={data.data}
                left={usedWidth > 0 ? usedWidth : 0}
                right={usedWidth < 0 ? -usedWidth : 0}
            />
        }
    }} />;
};

const ColumnManagerWithGlobe = props => {
    const [width, setWidth] = useState(window.innerWidth);
    const [loaded, setLoaded] = useState(false);
    const [usedWidth, setUsedWidth] = useState(null);
    const [shownRoutes, setShownRoutes] = useState({});

    useEffect(() => {
        const resize = () => { setWidth(window.innerWidth); };
        window.addEventListener("resize", resize);

        setLoaded(true);

        return () => {
            window.removeEventListener("resize", resize);
        };
    }, []);

    return <WindowWidthContext.Provider
        value={{
            width,
            reportWidth: newWidth => {
                if (newWidth === 0) {
                    return;
                }

                if (newWidth === usedWidth) {
                    return;
                }

                setUsedWidth(newWidth);
            },
            setDisplay: (route, display) => {
                shownRoutes[String(route)] = display;
                setShownRoutes(shownRoutes);
            }
        }}
    >
    {
        loaded !== false && (
            Object.values(shownRoutes).reduce((a, c) => a || c, false) ?
                getGlobe(props, width, usedWidth) :
                <NotFoundPage />
        )
    }
    {props.children}
    </WindowWidthContext.Provider>;
};

export { WindowWidthContext };

export default withRouter(ColumnManagerWithGlobe);

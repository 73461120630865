import { layer } from '@fortawesome/fontawesome-svg-core';
import React from 'react';
import DataMapKey from './DataMapKey';

class DataHeatmap extends React.Component {
    constructor(props) {
        super(props);

        // the mapquest map object
        this.map = false;

        // contains each layer we'll be adding to the map to allow us to add and remove them
        this.layers = {
            abundanceLayer: false,
            bloodmealHostLayer: [],
            insecticideResistanceLayer: [],
            pathogenDetectionLayer: [],
        }

        // contains layers that are hidden from the map
        this.hiddenLayers = [];

        // object used to determine the colour of any markers, per map type
        this.markerColours = {
            'bloodmeal-host': {
                'Human' : {
                    'primaryColor' : '#890000',
                    'secondaryColor' : '#FF6D6D',
                    'filter' : true,
                },
                'Canid' : {
                    'primaryColor' : '#b06b4d',
                    'secondaryColor' : '#ff996d',
                    'filter' : true,
                },
                'Felid' : {
                    'primaryColor' : '#a88647',
                    'secondaryColor' : '#ffcc6d',
                    'filter' : true,
                },
                'Rodent' : {
                    'primaryColor' : '#a0a345',
                    'secondaryColor' : '#faff6d',
                    'filter' : true,
                },
                'Marsupial' : {
                    'primaryColor' : '#84a346',
                    'secondaryColor' : '#ceff6d',
                    'filter' : true,
                },
                'Other mammal' : {
                    'primaryColor' : '#5b9e42',
                    'secondaryColor' : '#94ff6d',
                    'filter' : true,
                },
                'Other primate' : {
                    'primaryColor' : '#46a35b',
                    'secondaryColor' : '#68f7b4',
                    'filter' : true,
                },
                'Ungulate' : {
                    'primaryColor' : '#3f968c',
                    'secondaryColor' : '#6dffee',
                    'filter' : true,
                },
                'Reptile' : {
                    'primaryColor' : '#4777a8',
                    'secondaryColor' : '#6db6ff',
                    'filter' : true,
                },
                'Amphibian' : {
                    'primaryColor' : '#49469e',
                    'secondaryColor' : '#726dff',
                    'filter' : true,
                },
                'Galliforme' : {
                    'primaryColor' : '#7046a3',
                    'secondaryColor' : '#af6dff',
                    'filter' : true,
                },
                'Other bird' : {
                    'primaryColor' : '#9146a6',
                    'secondaryColor' : '#df6dff',
                    'filter' : true,
                },
                'Mixed' : {
                    'primaryColor' : '#994266',
                    'secondaryColor' : '#ff6daa',
                    'filter' : true,
                },
            },
            'insecticide-resistance' : {
                'Susceptible' : {
                    'primaryColor' : '#259e1c',
                    'secondaryColor' : '#259e1c',
                    'filter' : true,
                },
                'Suspected resistance' : {
                    'primaryColor' : '#f2ad24',
                    'secondaryColor' : '#f2ef24',
                    'filter' : true,
                },
                'Confirmed resistance' : {
                    'primaryColor' : '#cf1515',
                    'secondaryColor' : '#cf1515',
                    'filter' : true,
                }
            },
            'pathogen-detection' : {
                'Infected' : {
                    'secondaryColor' : '#cf1515',
                    'filter' : true,
                },
                'Not infected' : {
                    'secondaryColor' : '#259e1c',
                    'filter' : true,                }
            }
        }
    }

    componentDidMount() {
        if (this.props.reloadOnMount === true) {
            this.createMap();
            this.loadMap(this.props.coords);
        }
    }
    
    componentDidUpdate() {
        // reload the map
        if (this.props.show && !this.map) { 
            this.createMap();
            this.loadMap(this.props.coords);
        }      
    }

    // init the map
    createMap = () => {
        window.L.mapquest.key = 'jdUHXHS5pGJyUakR5iAZtbdGS3PiCivO';
        var baseLayer = window.L.mapquest.tileLayer('map');

        this.map = window.L.mapquest.map('map', {
            center: [51.0915595, -1.7861226],
            layers: baseLayer,
            zoom: 2
        });
    }

    // go through each layer and remove it from the map
    clearMap = () => {
        // clear the abundance layer if it exists
        if (this.layers.abundanceLayer) {
            this.map.removeLayer(this.layers.abundanceLayer);
        }

        // if we have a bloodmeal host layer
        if (this.layers.bloodmealHostLayer) {
            // loop through each layergroup
            for (const name in this.layers.bloodmealHostLayer) {
                // remove the layergroup from the map
                this.map.removeLayer(this.layers.bloodmealHostLayer[name]);
            }
        }

        if (this.layers.insecticideResistanceLayer) {
            // loop through each layergroup
            for (const name in this.layers.insecticideResistanceLayer) {
                // remove the layergroup from the map
                this.map.removeLayer(this.layers.insecticideResistanceLayer[name]);
            }
        }

        if (this.layers.pathogenDetectionLayer) {
            // loop through each layergroup
            for (const name in this.layers.pathogenDetectionLayer) {
                // remove the layergroup from the map
                this.map.removeLayer(this.layers.pathogenDetectionLayer[name]);
            }
        }

        // reset the variables
        this.layers.pathogenDetectionLayer = [];
        this.layers.insecticideResistanceLayer = [];
        this.layers.bloodmealHostLayer = [];
        this.layers.abundanceLayer = false;
        this.hiddenLayers = [];
    }

    createAbundanceLayer = coords => {
        // create the cluster group
        var markers = window.L.markerClusterGroup();

        // loop through each coord
        for (var i = 0; i < coords.length; i++) {
            var coord = coords[i];
            // generate the title
            var title = '<strong>Genus\' Species: </strong>' + coord[2]['species'] + 
                '<br /><strong>Date: </strong>' + coord[2]['date'] +
                '<br /><strong>Count</strong>: ' + coord[2]['count'] + 
                '<br /><strong>Reference</strong>: ' + coord[2]['reference']
            ;

            // create the marker
            var marker = window.L.marker(new window.L.LatLng(coord[0], coord[1]), {
                icon: window.L.mapquest.icons.marker({})
            });

            // bind the pop up with the title
            marker.bindPopup(title);
            // add it to the markers
            markers.addLayer(marker);
        }

        // set the abundance layer
        this.layers.abundanceLayer = markers;

        // add it to the map
        this.map.addLayer(this.layers.abundanceLayer);
    }

    createBloodmealHostLayer = coords => {
        // we will be grouping the markers by bloodmeal host, stored in this array
        let markerCollections = [];

        // loop through each object
        for (var i = 0; i < coords.length; i++) {
            var coord = coords[i];

            // if we have bloodmeal host data
            if (coord[3]) {
                // loop through each host
                for (const property in coord[3]) {
                    // create the marker for this host at this coordinate, getting the colours from the markerColours object
                    var marker = window.L.marker([coord[0], coord[1]], {
                        icon: window.L.mapquest.icons.circle({
                            primaryColor: this.getMarkerColour(property, 'primaryColor'),
                            secondaryColor: this.getMarkerColour(property, 'secondaryColor'),
                            shadow: true,
                            size: 'sm',
                            symbol: ''
                        }),
                        draggable: false
                    });

                    var title = '<strong>' + property + '</strong>: ' + coord[3][property] +
                        '<br /><strong>Genus\' Species: </strong>' + coord[2]['species'] + 
                        '<br /><strong>Date: </strong>' + coord[2]['date'] +
                        '<br /><strong>Count</strong>: ' + coord[2]['count'] + 
                        '<br /><strong>Reference</strong>: ' + coord[2]['reference']
                    ;

                    // bind the pop up
                    marker.bindPopup(title);

                    // init the array if it doesn't already exist
                    if (!markerCollections[property]) {
                        markerCollections[property] = [];
                    }

                    // push the marker to this collection of bloodhosts
                    markerCollections[property].push(marker);
                }
            }
        }

        var parentGroup = window.L.markerClusterGroup();
        
        // loop through each collection of markers
        for (const name in markerCollections) {
            var subGroup = window.L.featureGroup.subGroup(parentGroup, markerCollections[name])
            subGroup.addTo(this.map);
            this.layers.bloodmealHostLayer[name] = subGroup;
        }

        parentGroup.addTo(this.map)
    }

    createInsecticideResistanceLayer = coords => {
        // will contain collections of markers to filter by
        let markerCollections = [];

        // loop through each coordinate
        for (var i = 0; i < coords.length; i++) {
            var coord = coords[i];

            // get the property that we'll use to group markers by for the filtering
            let groupingProperty = coord[4]['Insecticide resistance status'];

            if (coord[4]) {
                // generate the marker
                var marker = window.L.marker([coord[0], coord[1]], {
                    icon: window.L.mapquest.icons.circle({
                        primaryColor: this.getMarkerColour(groupingProperty, 'primaryColor'),
                        secondaryColor: this.getMarkerColour(groupingProperty, 'secondaryColor'),
                        shadow: true,
                        size: 'sm',
                        symbol: ''
                    }),
                    draggable: false
                });
                
                //generate the marker content
                let popupContent = this.buildInsecticideResistancePopUp(coord[4]);

                // bind it
                if (popupContent) {
                    marker.bindPopup(popupContent);
                }

                // init the array in the markercollections if it doesn't exist
                if (!markerCollections[groupingProperty]) {
                    markerCollections[groupingProperty] = [];
                }

                // add the marker to the collection
                markerCollections[groupingProperty].push(marker);
            }
        }

        var parentGroup = window.L.markerClusterGroup();

        // loop through each collection
        for (const name in markerCollections) {
            var subGroup = window.L.featureGroup.subGroup(parentGroup, markerCollections[name])
            subGroup.addTo(this.map);
            this.layers.insecticideResistanceLayer[name] = subGroup;
        }

        parentGroup.addTo(this.map)
    }

    createPathogenDetectionLayer = coords => {
        let markerCollections = [];

        for (var i = 0; i < coords.length; i++) {
            var coord = coords[i];

            if (coord[5]) {
                let pathogen = coord[5]['Pathogen species'];
                const color = '#' + Math.floor(Math.random() * 16777215).toString(16).padStart(6, '0');
                this.markerColours['pathogen-detection'][pathogen] = { 'primaryColor': color, filter: false, };
            }
        }

        for (var i = 0; i < coords.length; i++) {
            var coord = coords[i];

            if (coord[5]) {
                let infected = (coord[5]['Infection status (Y/N)'] === 'Y' ? 'Infected' : 'Not infected');

                var marker = window.L.marker([coord[0], coord[1]], {
                    icon: window.L.mapquest.icons.circle({
                        secondaryColor: this.getMarkerColour(infected, 'secondaryColor'),
                        primaryColor: this.getMarkerColour(coord[5]['Pathogen species'], 'primaryColor'),
                        shadow: true,
                        size: 'sm',
                        symbol: ''
                    }),
                    draggable: false
                });

                var title = '<strong>Genus\' Species: </strong>' + coord[2]['species'] + 
                    '<br /><strong>Date: </strong>' + coord[2]['date'] +
                    '<br /><strong>Count</strong>: ' + coord[2]['count'] + 
                    '<br /><strong>Reference</strong>: ' + coord[2]['reference']
                ;
                
                marker.bindPopup(title);

                if (!markerCollections[infected]) {
                    markerCollections[infected] = [];
                }

                markerCollections[infected].push(marker);
            }
        }

        var parentGroup = window.L.markerClusterGroup();

        // loop through each collection
        for (const name in markerCollections) {
            var subGroup = window.L.featureGroup.subGroup(parentGroup, markerCollections[name])
            subGroup.addTo(this.map);
            this.layers.pathogenDetectionLayer[name] = subGroup;
        }

        parentGroup.addTo(this.map)
    }
    
    getMarkerColour = (property, colourType) => {
        if (this.markerColours[this.props.mapType][property]) {
            var colour = this.markerColours[this.props.mapType][property][colourType];
        } else {
            var colour = '#000000';
        }

        return colour;        
    }

    buildInsecticideResistancePopUp = data => {
        let popupContent = '';

        if (data['Insecticide name']) {
            popupContent += '<strong>Insecticide name</strong>: ' + data['Insecticide name'] + '<br />';
        }

        if (data['Insecticide class']) {
            popupContent += '<strong>Insecticide class</strong>: ' + data['Insecticide class'] + '<br />';
        }

        if (data['Concentration']) {
            popupContent += '<strong>Concentration</strong>: ' + data['Concentration'];

            if (data['Concentration unit']) {
                popupContent += ' ' + data['Concentration unit'];
            }
            
            popupContent += '<br />';
        }

        if (data['Exposure period']) {
            popupContent += '<strong>Exposure period</strong>: ' + data['Exposure period'];

            if (data['Exposure period unit']) {
                popupContent += ' ' + data['Exposure period unit'];
            }
            
            popupContent += '<br />';
        }

        if (data['Insecticide resistance value']) {
            popupContent += '<strong>Insecticide resistance value</strong>: ' + data['Insecticide resistance value'];

            if (data['Insecticide resistance value unit']) {
                popupContent += ' ' + data['Insecticide resistance value unit'];
            }
            
            popupContent += '<br />';
        }

        if (data['Developmental stage']) {
            popupContent += '<strong>Developmental stage</strong>: ' + data['Developmental stage'] + '<br />';
        }

        return popupContent;
    }

    toggleLayer = (layerName) => {
        if (this.props.mapType === 'bloodmeal-host') {
            var layerGroup = this.layers.bloodmealHostLayer[layerName];
        }  else if (this.props.mapType === 'insecticide-resistance') {
            var layerGroup = this.layers.insecticideResistanceLayer[layerName];
        } else if (this.props.mapType === 'pathogen-detection') {
            var layerGroup = this.layers.pathogenDetectionLayer[layerName];
        }

        if (layerGroup) {
            if (this.hiddenLayers.includes(layerName)) {
                this.hiddenLayers = this.hiddenLayers.filter(item => item !== layerName);
                layerGroup.addTo(this.map);
            } else {
                this.hiddenLayers.push(layerName);
    
                this.map.removeLayer(layerGroup);
            }
        }
    }

    loadMap = (coords) => {
        if (coords && coords.length > 0 && this.map) {
            this.clearMap();

            if (this.props.mapType === 'abundance') {
                this.createAbundanceLayer(coords);
            } else if (this.props.mapType === 'bloodmeal-host') {
                this.createBloodmealHostLayer(coords);
            } else if (this.props.mapType === 'insecticide-resistance') {
                this.createInsecticideResistanceLayer(coords);
            } else if (this.props.mapType === 'pathogen-detection') {
                this.createPathogenDetectionLayer(coords);
            }
        } else {
            this.clearMap();
        }
    }

    render() {
        if (this.props.show) {
            this.loadMap(this.props.coords);
        }

        return (
            <>
                <p>
                    {/* <span className="mobile-map-close" onClick={() => this.props.close()}>Close</span> */}
                </p>
                <div id="map"></div>
                <DataMapKey mapType={this.props.mapType} markerColours={this.markerColours} onClick={this.toggleLayer} />
            </>
        );
    }
}

export default DataHeatmap;
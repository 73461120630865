import { useContext } from 'react';
import { SettingsContext } from '../SettingsContext.js';
import { withRouter } from "react-router-dom";

const DocumentTitle = props => {
    const settings = useContext(SettingsContext);

    if (settings) {
        const titleSetting = settings.find(setting => setting.title === 'Website Title');
        if (titleSetting) {
            let pageTitle = props.location.pathname.substring(1).replace('-', ' ');
            pageTitle = pageTitle.replace(/(^\w{1})|(\s{1}\w{1})/g, match => match.toUpperCase());

            document.title = titleSetting.value + (pageTitle ? ' - ' + pageTitle : '' );
        }
    }

    return null;
}

export default withRouter(DocumentTitle);
import React from 'react';
import SearchInput from "../SearchInput";
import Loading from "../Loading";
import Api from "../../Api";
import DataHeatmap from './DataHeatmap';
import { Link } from "react-router-dom";

class Data extends React.Component {
    constructor(props) {
        super(props);

        this.criteria = props.profile ? [props.profile] : [];

        this.state = {
            loading: false,
            hasResults: false,
            title: false,
            resultCount: false,
            exportUrl: '',
            showHeatMap: false,
            showDataIndex: false,
            coords: false,
            mapType: 'abundance',
        };
    }

    refreshSearchCount = () => {
        this.setState({ loading: true });

        const ids = this.criteria
            .filter(criteria => criteria.id)
            .map(criteria => criteria.id)
            .join(",")
        ;

        Api.search({ resource_type: 'data', get_count: true, relationship_ids: ids })
            .then(response => {
                this.setState({ resultCount: response.total_items, loading: false });
            })
        ;
    }

    componentDidUpdate(prevProps) {
        const prevId = prevProps.profile && prevProps.profile.id;
        const currId = this.props.profile && this.props.profile.id;
        if (prevId !== currId) {
            this.criteria = [this.props.profile];
            this.search();
        }
    }

    onCriteriaChange = criteria => {
        this.criteria = criteria;

        if (this.criteria.length > 0) {
            this.search();
            this.setState({ title: 'Results' })
        } else {
            this.refreshSearchCount();
            this.setState({ title: false });
            this.resetHeatMap();
        }
    }

    handleInputChange = (event) => {
        const target = event.target;
        const fieldValue = target.value;
        const fieldName = target.name;

        this.setState({
            [fieldName]: fieldValue
        });
    }

    search() {
        if (this.criteria.length > 0) {
            const ids = this.criteria
                .filter(criteria => criteria.id)
                .map(criteria => criteria.id)
                .join(",")
            ;

            const query = {resource_type: "data", relationship_ids: ids, and_join: '1', get_count: true, search_type: 'dataSearchCount'};

            if (this.criteria.length > 0) {
                query['get_coords'] = true;
            }

            this.setState({ loading: true });

            Api.search(query)
                .then((response) => {
                    let count = (response.total_items ? response.total_items : false );
                    let coords = (response.coords ? response.coords : false );

                    let url = process.env.REACT_APP_API_ENTRYPOINT + '/export-csv-search?resource_type=data&relationship_ids=' + ids + '&and_join=1&export_csv=1';

                    this.setState({ loading: false, resultCount: count, exportUrl: url, coords: coords });

                    if (!count) {
                        this.resetHeatMap();
                    }
                })
            ;
        } else {
            this.refreshSearchCount();
        }
    }

    resetHeatMap = () => {
        this.setState({ showHeatMap: false });
        this.props.setDataClass(true);
    }

    showHeatMap = () => {
        this.setState({ showHeatMap: (this.state.showHeatMap ? false : true) });
        this.props.setDataClass();
    }

    render() {
        return (
            <div className="data-content-wrap">
                <div className={"data-heatmap" + (this.state.loading ? ' map-loading' : '')}>

                    <DataHeatmap 
                        show={this.state.showHeatMap} 
                        coords={this.state.coords} 
                        mapType={this.state.mapType}
                        close={this.showHeatMap} 
                    />
                    
                    {this.state.loading === true &&
                        <div className="heatmap-loader">
                            <Loading size="2x" />
                        </div>
                    }
                </div>

                <div className="data-search">
                    <div className="flex-grow-1 data-wrap">
                        {this.state.showHeatMap && 
                            <>
                                <h3 className="sidebar-page-title text-primary d-block show-heatmap">Data</h3>
                            </>
                        }
                        <SearchInput 
                            key={this.props.profile && this.props.profile.id} 
                            criteria={this.props.profile && [this.props.profile]} 
                            onChange={this.onCriteriaChange} 
                            onReset={() => this.resetHeatMap()}
                            showCommonSearches={true}
                            showDataIndex={true}
                            setDataIndexClass={this.props.setDataIndexClass}
                            setResourceTreeClass={this.props.setResourceTreeClass}
                            user={this.props.user}
                            searchType="data"
                            showCriteria={true}
                        />

                        <h4 className="mb-4">Can't find what you're looking for? Upload your own data <Link to="/account/upload-data">here</Link>.</h4>
                    </div>
                    
                    {this.state.loading === true && 
                        <Loading size="2x" />
                    }
                    
                    {this.state.loading === false && 
                        <div className="data-results">    
                            {this.state.resultCount && 
                                <h4 className="datasets-link mt-2 ml-0 mr-auto">
                                    {this.state.resultCount.toLocaleString('en-GB')} Data Entries
                                </h4>
                            }

                            {!this.state.resultCount && 
                                <h4>No datasets found</h4>
                            }
                        
                            {this.state.resultCount && this.criteria.length >= 1 &&   
                                <div className="data-functions-wrap">
                                    <a href={this.state.exportUrl} target="_blank" className="btn btn-primary text-white ml-auto mr-0" size="lg">
                                        Download
                                    </a>
                                    <div className="map-wrap">
                                        <select name="mapType" onChange={this.handleInputChange} >
                                            <option value="abundance">Abundance</option>
                                            <option value="bloodmeal-host">Bloodmeal host</option>
                                            <option value="insecticide-resistance">Insecticide resistance</option>
                                            <option value="pathogen-detection">Pathogen detection</option>
                                        </select>
                                        <div className="btn btn-primary text-white ml-1 mr-0 heatmap-btn" size="lg" onClick={() => this.showHeatMap()}>
                                            {(this.state.showHeatMap ? 'Hide' : 'Show' )} Map
                                        </div>
                                    </div>
                                </div>
                            }         
                        </div>   
                    }
                </div>
            </div>
        );
    }
}

export default Data;
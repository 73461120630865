import React, { useState } from 'react';
import Api from '../../Api';

const ApiInformation = props => {
    const [exampleFile, setExampleFile] = useState(false);
    const [dataFields, setDataFields] = useState(false);
    const entryPoint = process.env.REACT_APP_API_ENTRYPOINT;

    useState(() => {
        const requests = [Api.dataInstructions(), Api.dataFields()];

        Promise.all(requests).then(responses => {
            setExampleFile(responses[0][0].file.filePath);    
            setDataFields(responses[1]);
        });
    }, []);

    return <>
        <div dangerouslySetInnerHTML={{ __html: props.content.apiContent }}></div>

        <h3>Authentication</h3>
        <p>
            Calls to the API are authenticated using JSON Web Tokens (JWT). Add the authentication header with the below with every request you make to the API:
        </p>

        <div className="code">
            Bearer {window.localStorage.getItem("token")}
        </div>
        <br />

        <p>The domain for all requests is {entryPoint}</p>

        <h3>Endpoints - GET</h3>
        <div className="border p-3 mb-2">
            <p><div className="code">/api/export/profile-types</div></p>
            <p>Returns a list of profile types. These can be used to filter the profiles.</p>
            <p>
                <h4>Request</h4>
                None
            </p>

            <p>
                <h4>Response</h4>
                An array of profile type items with the following data:
            </p>
            <table className="table table-bordered table-sm">
                <thead>
                    <tr>
                        <th>Name</th>
                        <th>Type</th>
                        <th>Description</th>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <td>id</td>
                        <td>int</td>
                        <td>The id of the profile type</td>
                    </tr>
                    <tr>
                        <td>title</td>
                        <td>string</td>
                        <td>The title of the profile type</td>
                    </tr>
                </tbody>
            </table>
        </div>

        <div className="border p-3 mb-2">
            <p><div className="code">/api/export/profiles</div></p>
            <p>Returns a list of profiles that are used to filter down the data entries.</p>
            <p>
                <h4>Request</h4>
                <table className="table table-bordered table-sm">
                    <thead>
                        <tr>
                            <th>Name</th>
                            <th>Type</th>
                            <th>Default</th>
                            <th>Description</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td>profile_type</td>
                            <td>int</td>
                            <td>null</td>
                            <td>The id of the profile type to filter profiles by</td>
                        </tr>
                    </tbody>
                </table>
            </p>
            <p>
                <h4>Response</h4>
                Returns an array of profile items with the following data: 
            </p>

            <table className="table table-bordered table-sm">
                <thead>
                    <tr>
                        <th>Name</th>
                        <th>Type</th>
                        <th>Description</th>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <td>id</td>
                        <td>int</td>
                        <td>The id of the profile</td>
                    </tr>
                    <tr>
                        <td>title</td>
                        <td>string</td>
                        <td>The title of the profile</td>
                    </tr>
                </tbody>
            </table>
        </div>

        <div className="border p-3 mb-2">
            <p><div className="code">/api/export/data</div></p>
            <p>Returns a list of vector data in the system which can be filtered by a number of parameters.</p>
            <p>
                <h4>Request</h4>
                <table className="table table-bordered table-sm">
                    <thead>
                        <tr>
                            <th>Name</th>
                            <th>Type</th>
                            <th>Default</th>
                            <th>Description</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td>start_date</td>
                            <td>string (yyyy-mm-dd)</td>
                            <td>null</td>
                            <td>Collection date start date</td>
                        </tr>
                        <tr>
                            <td>end_date</td>
                            <td>string (yyyy-mm-dd)</td>
                            <td>null</td>
                            <td>Collection date end date</td>
                        </tr>
                        <tr>
                            <td>limit</td>
                            <td>int</td>
                            <td>2000</td>
                            <td>The number of data results to return, maximum of 2000 with each call</td>
                        </tr>
                        <tr>
                            <td>page</td>
                            <td>int</td>
                            <td>1</td>
                            <td>The page of results to return, use with limit to paginate results</td>
                        </tr>
                        <tr>
                            <td>relationship_ids</td>
                            <td>string</td>
                            <td>null</td>
                            <td>A comma separated list of profile ids that can be used to filter the data</td>
                        </tr>
                    </tbody>
                </table>
            </p>

            <p>
                <h4>Response</h4>
                Returns any data that matches the filtering criteria. Please review the data fields section below for the properties returned.
            </p>
        </div>

        <br />

        <h3>Endpoints - POST</h3>
        <div className="border p-3 mb-2">
            <p><div className="code">/api/import/data</div></p>
            <p>Submit data to be input into the system. All data will need be approved before appearing on the site. Any rows that fail validation will need to be resubmitted.</p>
            
            <p>
                <h4>Request</h4>
                <table className="table table-bordered table-sm">
                    <thead>
                        <tr>
                            <th>Name</th>
                            <th>Type</th>
                            <th>Default</th>
                            <th>Description</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td>project_types (required)</td>
                            <td>string</td>
                            <td>null</td>
                            <td>
                                A comma separated list of project type ids. You will need to use the /api/export/profile_types to receive the id of the "project type" category,
                                then another call to /api/export/profiles to receive the project type profiles    
                            </td>
                        </tr>
                        <tr>
                            <td>data (required)</td>
                            <td>array</td>
                            <td>null</td>
                            <td>
                                An array of data to be submitted into the system. Please see  
                                <a href="https://api.globalvectorhub.lshtm.ac.uk/resource/serve-file/551/5efcb71b2d3d2_GVH-SOP-040%20Creating%20a%20database%20for%20field%20collections%20of%20mosquitoes.pdf" target="_blank"> this link </a>
                                and the data fields section below for the properties available.
                            </td>
                        </tr>
                    </tbody>
                </table>
            </p>
            
            <p>
                <h4>Response</h4>
                A 200 response on success or a 500 on error/validation fail.
            </p>
        </div>

        {dataFields &&
            <>
                <br />
                <h3>Data Fields</h3>
                <div className="border p-3 mb-2">
                    <p>There are a large number of properties that on the data object. Please see a reference below.</p>
                    <table className="table table-bordered table-sm">
                        <thead>
                            <tr>
                                <th>Name</th>
                                <th>Type</th>
                            </tr>
                        </thead>
                        <tbody>
                            {dataFields.map(field => {
                                return <tr>
                                    <td>{field.name}</td>
                                    <td>{field.type}</td>
                                </tr>
                            })}
                        </tbody>
                    </table>
                </div>
            </>
        }
    </>;
}

export default ApiInformation;
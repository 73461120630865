import React from 'react';
import { Link } from "react-router-dom";
import FilterPageMenu from './FilterPage/FilterPageMenu';


class SideMenu extends React.Component {
     constructor(props) {
        super(props);

        this.state = {
            isOpen: false,
        };
    }

    componentDidMount() {
        this.props.onOpen();
    }

    render() {
        return (
            <>
                <div className="main-column-content-inner sidemenu">
                    <FilterPageMenu 
                        user={this.props.user} 
                        onClose={this.props.onClose} 
                        isMenuOpen={true} 
                    />

                    <div className="side-menu-content mt-5 ml-5">
                        <Link to="/resources" className="btn btn-primary mr-1 mb-1">Resources</Link>
                        <Link to="/data" className="btn btn-primary mr-1 mb-1">Data</Link>
                        <Link to="/network" className="btn btn-primary mr-1 mb-1">Network</Link>
                        {this.props.menuLinks &&
                            <>
                                <ol className="menu-list">
                                    <li key="menu-link-home">
                                        <Link to="/" onClick={this.props.onClose}>Home</Link>
                                    </li>
                                    <li key="menu-link-news">
                                        <Link to="/news-archive" onClick={this.props.onClose}>News</Link>
                                    </li>
                                    {this.props.menuLinks.map(menuLink => {
                                        let link, title;
                                        if (menuLink.page && menuLink.page.isEnabled === true) {
                                            link = `/${menuLink.page.slug}`;
                                            title = menuLink.title;
                                        } else {
                                            link = menuLink.link;
                                            title = menuLink.title;
                                        }

                                        return (
                                            <li key={`menu-link-${menuLink.id}`}>
                                                <Link to={link} onClick={this.props.onClose}>{title}</Link>
                                            </li>
                                        );
                                    })}
                                    <li key="menu-link-courses">
                                        <Link to="/courses" onClick={this.props.onClose}>Courses</Link>
                                    </li>
                                    <li key="menu-link-forum">
                                        <Link to="/forum-login" onClick={this.props.onClose}>Discussion Forum</Link>
                                    </li>
                                    <li key="menu-link-contact">
                                        <Link to="/contact" onClick={this.props.onClose}>Contact</Link>
                                    </li>
                                </ol>
                            </>
                        }

                        {this.props.socialMedia &&
                            <ul className="social-links-list list-inline d-flex justify-content-between">
                                {this.props.socialMedia.map((socialMedia, index) =>
                                    <li key={`social-media-${index}`} className="list-inline-item">
                                        <a href={socialMedia.link} target="_blank" rel="noopener nofollow noreferrer">{socialMedia.title}</a>
                                    </li>
                                )}
                            </ul>
                        }
                    </div>

                </div>
            </>
        );
    }
}

export default SideMenu;

import React from 'react';
import logo from '../assets/logo.png';
import { Link } from 'react-router-dom';
import ReactGA from 'react-ga4';

const NotFoundPage = (props) => {
    const [display, setDisplay] = React.useState(false);
    const [sentGa, setSentGa] = React.useState(false);

    if (!display) {
        setTimeout(() => setDisplay(true), 0);
        return null;
    }

    if (!sentGa) {
        ReactGA.pageview(window.location.pathname + window.location.search);
        setSentGa(true);
    }
    

    return (
        <div className="four-oh-four">
            <div className="d-flex align-items-center justify-content-center pt-5">
                <img className="title-page-logo pr-3" src={logo} alt="" />
                <h1 className="text-primary text-center main-title">Global Vector Hub</h1>
            </div>
            <div className="not-found-content d-flex align-items-center justify-content-center pt-5">
                <div>
                    <h2>Sorry, this page could not be found.</h2>
                    <h4><Link to="/">Return home</Link></h4>
                </div>
            </div>
        </div>
    )
}

export default NotFoundPage;

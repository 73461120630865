import React from 'react';
import SelectSearch from 'react-select-search';
import axios from 'axios';
import Api from '../../Api';
import PopUp from '../PopUp';
import Loading from '../Loading';
import { Button } from 'react-bootstrap';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faPlus } from "@fortawesome/pro-solid-svg-icons";
import { faTrafficLightSlow } from '@fortawesome/pro-light-svg-icons';

class RelationshipPopUp extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            show: false,
            profileTypes: [],
            profileTypesDisabled: false,
            selectedType: '',
            profiles: [],
            loading: true,
            profilesList: [],
            selectedProfiles: [],
        }

        this.handleOpen = () => {
            this.setState({ show: true, selectedProfiles: [] });
        }

        this.handleClose = () => {
            this.setState({ 
                show: false, 
                selectedType: '', 
                profiles: [], 
                profileTypesDisabled: false,
            });
        }
    }

    componentDidMount() {
        this.setState({ loading: true });

        Api.profileTypes(null, null, null, null, true)
            .then((response) => {
                let options = [];

                response.forEach((item, index) => {
                    let name = item.title;
                    let value = item.id

                    options.push({
                        name: name,
                        value: value.toString()
                    })
                })

                this.setState({ profileTypes: options, loading: false })
            })
        ;
    }

    handleProfileOnChange = (ids) => {
        this.setState({ selectedProfiles: ids });
        this.props.setSelectedProfiles(ids);
    }

    handleModalSubmit = () => {
        this.props.setRelationships(this.props.selectedProfiles); // ids to submit to the form
        this.handleClose();
    }

    handleOnChange = (id) => {
        this.setState({ selectedType: id, loading: true, selectedProfiles: [] });

        let entryPoint = process.env.REACT_APP_API_ENTRYPOINT + '/api';

        if (id === 'news_article') {
            var url = entryPoint + '/news_articles?isEnabled=true&isApproved=true&noRelationships=1';
        } else if (id === 'page') {
            var url = entryPoint + '/pages?isEnabled=true&isApproved=true&noRelationships=1';
        } else if (id === 'resource') {
            var url = entryPoint + '/resources?isEnabled=true&noRelationships=1';
        } else if (id === 'user') {
            var url = entryPoint + '/users?roles=["ROLE_USER"]&isVerified=true&isEnabled=true&noRelationships=1';
        } else {
            var url = entryPoint + '/profiles?isEnabled=true&isApproved=true&noRelationships=1&profileType=' + id;
        }

        axios.get(url, {
            headers: {
                authorization: 'Bearer ' + window.localStorage.getItem('token')
            }
        })
        .then(response => {
            let profiles = [];
            let profilesList = this.state.profilesList;

            response.data['hydra:member'].forEach((profile, index) => {
                if (!this.props.user || (this.props.relationships && !this.props.relationships.includes(profile.id))) {
                    if (id === 'user') {
                        var name = profile.firstName + ' ' + profile.lastName
                    } else {
                        var name = profile.title;
                    }

                    profiles.push({ value: profile.id.toString(), name: name })
                    profilesList[parseInt(profile.id)] = name;
                }
            });

            // profilesList = this.state.profilesList.concat(profilesList);

            if (profiles.length) {
                this.setState({ profileTypesDisabled: true })
            }
            
            this.setState({ profiles: profiles, loading: false, profilesList: profilesList });
            this.props.setProfilesList(profilesList);
        })
        .catch(error => {
            console.log(error);
        });
    }

    render() {
        let modalContent = (
            <div className="institutions-modal__content">
                <div className="institutions-modal__type">
                    {this.state.profileTypes.length > 0 && 
                        <SelectSearch 
                            search
                            placeholder="Select a key search term type to add"
                            options={this.state.profileTypes}
                            onChange={this.handleOnChange}
                            value={this.state.selectedType}
                            disabled={this.state.profileTypesDisabled}
                        />
                    }
                </div>

                {this.state.selectedType && this.state.profiles.length > 0 && 
                    <div className="institutions-modal__profiles">
                        <p>Select from the options below:</p>
                        <SelectSearch 
                            search
                            multiple
                            placeholder="Select key search terms to add"
                            options={this.state.profiles}
                            value={this.state.selectedProfiles}
                            onChange={this.handleProfileOnChange}
                        />
                    </div>
                }

                {this.state.selectedType && this.state.profiles.length === 0 && this.state.loading === false && 
                    <p>No key search terms found for this type</p>
                }

                {this.state.selectedProfiles && this.state.selectedProfiles.length > 0 && 
                    <Button onClick={() => this.handleModalSubmit()}>Select key search terms</Button>
                }

                {this.state.loading === true && 
                    <Loading size="1x" />
                }
            </div>
        ) 

        return (
            <>
                <Button onClick={this.handleOpen} className="relationships-add">
                    <FontAwesomeIcon icon={faPlus} />
                </Button>
                
                {modalContent && 
                    <PopUp size="lg" className="institutions-modal" content={modalContent} open={this.state.show} close={this.handleClose} />
                }
            </>
        );
    }
}

export default RelationshipPopUp;
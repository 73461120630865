import React from 'react';
import { Alert, Form, Button } from "react-bootstrap";
import { faCircleNotch } from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"

import Api from "../../Api";

class ForgottenPassword extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            email: '',
            success: null,
            message: '',
            loading: false,
        };

        this.location = (props.location) ? props.location : '' ;
    }

    handleInputChange = (event) => {
        const target = event.target;
        const fieldValue = target.value;
        const fieldName = target.name;

        this.setState({
            [fieldName]: fieldValue
        });
    }

    handleSubmit = (event) => {
        const thisForm = event.currentTarget;

        event.preventDefault();
        event.stopPropagation();

        if (thisForm.checkValidity() !== false) {
            this.setState({ loading: true });

            Api.sendPasswordResetEmail(this.state.email)
                .then((response) => {
                    this.setState({ success: response.success, message: response.message, loading: false, email: '' })
                })
                .catch(err => {
                    this.setState({ success: false, errorMessage: err.message, loading: false });
                })
            ;
        }
    }

    render() {
        return (
            <>
                {this.state.success === true &&
                    <Alert className="mb-4" variant="success" onClose={() => (this.setState({success: null}))}>
                        <Alert.Heading>Success!</Alert.Heading>
                        <p>{this.state.message}</p>
                    </Alert>
                } 

                {this.state.success === false &&
                    <Alert className="mb-4" variant="danger" onClose={() => (this.setState({success: null}))} dismissible>
                        <Alert.Heading>Oh snap! You got an error!</Alert.Heading>
                        <p>{this.state.message}</p>
                    </Alert>
                } 

                <p>Enter your email address to be sent an email to reset your password</p>

                <Form noValidate className="network-create-account-form my-auto needs-validation" onSubmit={this.handleSubmit} autoComplete="off">
                    <Form.Group controlId="forgotten-password-email">
                        <Form.Label>Email Address</Form.Label>
                        <Form.Control name="email" size="lg" type="text" value={this.state.email} onChange={this.handleInputChange} required />
                    </Form.Group>  

                    <Button className="mx-0 mt-5 mb-4 font-smooth text-white"
                        variant="ternary"
                        size="lg"
                        type="submit"
                        block
                    >
                        {this.state.loading === true && 
                            <FontAwesomeIcon icon={faCircleNotch} size="6x" color="#FF7442" spin />
                        }
                        {this.state.loading === false && 
                            <>Reset Password</>
                        }
                    </Button>
                </Form>
            </>
        );
    }
}

export default ForgottenPassword;
import { faBackward, faChevronLeft } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import { useState } from 'react';
import { useEffect } from 'react';
import { Col, Row, Accordion, Card } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { LoginOnly } from '../UserContext';

const PageAccordion = ({ data, id }) => {
    if (!data) {
        return null;
    }

    let accordion = null;
    data.forEach(item => {
        if (item.id === id) {
            accordion = item;
        }
    });

    const sections = [];

    accordion.titles.forEach((title, index) => {
        sections[index] = { title, content: "" };
    });

    accordion.contents.forEach((content, index) => {
        sections[index]['content'] = content;
    });

    return <Accordion className="page-accordion">
        {sections.map((section, index) => {
            return <Card key={`page-accordion-${index}`}>
                <Accordion.Toggle as={Card.Header} eventKey={index} style={{cursor: 'pointer'}} dangerouslySetInnerHTML={{ __html: section.title }} />
                <Accordion.Collapse eventKey={index}>
                    <Card.Body dangerouslySetInnerHTML={{ __html: section.content }} />
                </Accordion.Collapse>
            </Card>
        })}
    </Accordion>
}

const AccordionedContent = ({ content, accordionData }) => {
    const accordionedContent = content.split(/(\[accordion_[0-9]\])/g).map((part, index) => {
        const match = part.match(/\[accordion_[0-9]\]/);
        if (match) {
            // If it's the matched pattern, replace it with the desired React component
            return <PageAccordion data={accordionData} id={match[0].replace('[', '').replace(']', '')} />;
        } else {
            // If it's not the matched pattern, just return the string part
            return <div dangerouslySetInnerHTML={{ __html: part }} />;
        }
    });

    return <div>{accordionedContent}</div>;
}

const TextPage = ({ page }) => {
    const [bannerImage, setBannerImage] = useState(false);
    const [logo, setLogo] = useState(false);

    useEffect(() => {
        if (page?.bannerImage?.filePath) {
            setBannerImage(process.env.REACT_APP_API_ENTRYPOINT + '/media/' + page.bannerImage.filePath);
        }

        if (page?.logo?.filePath) {
            setLogo(process.env.REACT_APP_API_ENTRYPOINT + '/media/' + page.logo.filePath);
        }
    }, []);


    const getPageClass = () => {
        let className = "page-content-wrap d-flex flex-column h-100";

        if (logo) {
            className += " has-logo";
        }

        className += (page.width === 0.75) ? " width-75" : " width-50";

        if (!page?.parent) {
            className += " is-parent-page";
        }

        return className;
    }

    const pageContent = <div className={getPageClass()}>
        {page &&
            <>
                {logo &&
                    <div className="page-logo mb-4">
                        <img src={logo} />
                    </div>
                }

                {page?.parent &&
                    <Link to={`/${page.parent.slug}`} className="mb-2 page-back d-flex align-items-center justify-content-end">
                        <FontAwesomeIcon icon={faChevronLeft} className="mr-2" /> Back To Previous Page
                    </Link>
                }

                {bannerImage &&
                    <div className="page-banner-image mb-4">
                        <img src={bannerImage} className="w-100" />
                        {page.bannerCaption &&
                            <div className="page-banner-caption" style={{color: "#" + (page.bannerCaptionHex ?? '000')}}>
                                <h4>{page.bannerCaption}</h4>
                            </div>
                        }
                    </div>
                }

                {page.title && !page.parent && page.hideTitle !== true &&
                    <h2 className="text-center">{page.title}</h2>
                }

                <AccordionedContent content={page.frontEndContent} accordionData={page.accordionContent} />

                {page?.childPageData && page.childPageData.length > 0 &&
                    <div className="page-children mt-3">
                        <Row>
                            {page.childPageData.map((child, index) => {
                                return <>
                                    <Col key={child.id} md={4}>
                                        <div className="page-child-section">
                                            <Link to={`/${child.slug}`}>
                                                {child.sectionImage !== false &&
                                                    <img src={process.env.REACT_APP_API_ENTRYPOINT + "/media/" + child.sectionImage} />
                                                }
                                                <h3>{child.sectionTitle}</h3>
                                                <p>{child.sectionCaption}</p>
                                            </Link>
                                        </div>
                                    </Col>
                                </>
                            })}
                        </Row>
                    </div>
                }

                {page?.footerContent &&
                    <div className="page-footer-content mt-5">
                        <div dangerouslySetInnerHTML={{ __html: page.footerContent }} />
                    </div>
                }
            </>
        }
    </div>

    return (
        <div className={getPageClass()}>
            {page &&
                <>
                    {page.hasRestrictedAccess === true &&
                        <LoginOnly>
                            {pageContent}
                        </LoginOnly>
                    }

                    {page.hasRestrictedAccess !== true &&
                        <>
                            {pageContent}
                        </>
                    }
                </>
            }
        </div>
    )
}

export default TextPage;

import React from 'react';
import { Link } from "react-router-dom";
import { Button } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimes } from "@fortawesome/pro-light-svg-icons";
import { withRouter } from 'react-router-dom';

class SideBar extends React.Component {
    get side() {
        return this.props.side ? this.props.side : 'right' ;
    }

    get page() {
        return this.props.page ? this.props.page : false ;
    }

    get title() {
        return this.props.title ? this.props.title : false ;
    }

    onClose = () => {
        if (this.props.onClose) {
            this.props.onClose();
        }

        if (this.props.backOnClose) {
            this.props.history.goBack();
        }
    }

    render() {
        return (
            <div className={`main-column-content-inner sidebar-${this.side} position-relative px-5 pb-5`}>
                <div>
                    {this.props.backOnClose === true &&
                        <Button
                            className="rounded-circle close-button"
                            variant="light"
                            onClick={() => this.onClose()}
                        >
                            <FontAwesomeIcon icon={faTimes} />
                        </Button>
                    }

                    {!this.props.backOnClose &&
                        <Button
                            className="rounded-circle close-button"
                            variant="light"
                            as={Link}
                            to="/"
                            onClick={() => (this.props.onClose ? this.props.onClose() : '' )}
                        >
                            <FontAwesomeIcon icon={faTimes} />
                        </Button>   
                    }

                    {this.page &&
                        <h3 className="sidebar-page-title text-primary">
                            {this.page.charAt(0).toUpperCase() + this.page.slice(1)}
                        </h3>
                    }

                    {this.title &&
                        <h2 className="text-center mb-5">
                            {this.title}
                        </h2>
                    }
                    {this.props.children}
                </div>
                
            </div>
        );
    }
}

export default withRouter(SideBar);

import React from 'react';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEye, faTimes } from "@fortawesome/pro-regular-svg-icons";
import Api from '../../Api';

class Notification extends React.Component {
    markAsRead = () => {
        Api.markNotificationAsRead(this.props.notification.id)
            .then((response) => {
                this.props.refresh(true);
            })
        ;
    }

    delete = () => {
        Api.deleteNotification(this.props.notification.id)
            .then((response) => {
                this.props.refresh(true);
            })
        ;
    }

    render() {
        return (
            <div className={"notification" + (this.props.notification.readAt === null ? ' notification--unread' : '' )}>
                <div className="notification__top">
                    <div className="notification__date">
                        {this.props.notification.createdAt}
                    </div>
                    <div className="notification__actions">
                        {this.props.notification.readAt === null &&
                            <div className="notification__read" onClick={() => this.markAsRead()}>
                                Mark as read <FontAwesomeIcon icon={faEye} />
                            </div>   
                        }
                        <div className="notification__delete" onClick={() => this.delete()}>
                            <FontAwesomeIcon icon={faTimes} />
                        </div>
                    </div>
                </div>
                <div className="notification__message">
                    <div dangerouslySetInnerHTML={{__html: this.props.notification.message}}></div>
                </div>
            </div>
        );
    }
}

export default Notification;
import React from 'react';
import DataMapKey from './DataMapKey';
import Api from "../../Api";
import 'abortcontroller-polyfill/dist/abortcontroller-polyfill-only'

class DataHeatmapNew extends React.Component {
    constructor(props) {
        super(props);

        // the mapquest map object
        this.map = false;

        // contains each layer we'll be adding to the map to allow us to add and remove them
        this.layers = {
            abundanceLayer: false,
            bloodmealHostLayer: [],
            insecticideResistanceLayer: [],
            pathogenDetectionLayer: [],
        }

        // contains layers that are hidden from the map
        this.hiddenLayers = [];
        
        // active search request so we can cancel it if another one is made
        this.activeRequest = null;

        // object used to determine the colour of any markers, per map type
        this.markerColours = {
            'bloodmeal-host': {
                'Human' : {
                    'primaryColor' : '#890000',
                    'secondaryColor' : '#FF6D6D',
                    'filter' : true,
                },
                'Canid' : {
                    'primaryColor' : '#b06b4d',
                    'secondaryColor' : '#ff996d',
                    'filter' : true,
                },
                'Felid' : {
                    'primaryColor' : '#a88647',
                    'secondaryColor' : '#ffcc6d',
                    'filter' : true,
                },
                'Rodent' : {
                    'primaryColor' : '#a0a345',
                    'secondaryColor' : '#faff6d',
                    'filter' : true,
                },
                'Marsupial' : {
                    'primaryColor' : '#84a346',
                    'secondaryColor' : '#ceff6d',
                    'filter' : true,
                },
                'Other mammal' : {
                    'primaryColor' : '#5b9e42',
                    'secondaryColor' : '#94ff6d',
                    'filter' : true,
                },
                'Other primate' : {
                    'primaryColor' : '#46a35b',
                    'secondaryColor' : '#68f7b4',
                    'filter' : true,
                },
                'Ungulate' : {
                    'primaryColor' : '#3f968c',
                    'secondaryColor' : '#6dffee',
                    'filter' : true,
                },
                'Reptile' : {
                    'primaryColor' : '#4777a8',
                    'secondaryColor' : '#6db6ff',
                    'filter' : true,
                },
                'Amphibian' : {
                    'primaryColor' : '#49469e',
                    'secondaryColor' : '#726dff',
                    'filter' : true,
                },
                'Galliforme' : {
                    'primaryColor' : '#7046a3',
                    'secondaryColor' : '#af6dff',
                    'filter' : true,
                },
                'Other bird' : {
                    'primaryColor' : '#9146a6',
                    'secondaryColor' : '#df6dff',
                    'filter' : true,
                },
                'Mixed' : {
                    'primaryColor' : '#994266',
                    'secondaryColor' : '#ff6daa',
                    'filter' : true,
                },
            },
            'insecticide-resistance' : {
                'Susceptible' : {
                    'primaryColor' : '#259e1c',
                    'secondaryColor' : '#259e1c',
                    'filter' : true,
                },
                'Suspected resistance' : {
                    'primaryColor' : '#f2ad24',
                    'secondaryColor' : '#f2ef24',
                    'filter' : true,
                },
                'Confirmed resistance' : {
                    'primaryColor' : '#cf1515',
                    'secondaryColor' : '#cf1515',
                    'filter' : true,
                },
                'No Data': {
                    'primaryColor' : '#000000',
                    'secondaryColor' : '#000000',
                    'filter' : true,
                }
            },
            'pathogen-detection' : {
                'Infected' : {
                    'secondaryColor' : '#cf1515',
                    'filter' : true,
                },
                'Not infected' : {
                    'secondaryColor' : '#259e1c',
                    'filter' : true,                }
            }
        }
    }

    componentDidMount() {
        if (this.props.reloadOnMount === true) {
            this.createMap();
        }
    }
    
    componentDidUpdate() {
        // reload the map
        if (this.props.show && !this.map) { 
            this.createMap();
        }
    }

    // init the map
    createMap = () => {
        window.L.mapquest.key = 'jdUHXHS5pGJyUakR5iAZtbdGS3PiCivO';
        var baseLayer = window.L.mapquest.tileLayer('map');

        this.map = window.L.mapquest.map('map', {
            center: [51.0915595, -1.7861226],
            layers: baseLayer,
            zoom: 2,
            preferCanvas: true
        });

        const that = this;     
       
        this.map.on('dragend', () => {
            const zoom = that.map.getZoom();
            if (zoom > 2) {
                this.makeSearchRequest(that);
            }
        })

        this.map.on('zoomend', () => {
            this.makeSearchRequest(that);
        })
    }

    makeSearchRequest = (that) => {
        if (this.activeRequest) {
            this.activeRequest.abort();
            this.activeRequest = null;
        }

        this.activeRequest = new AbortController();

        const zoom = that.map.getZoom();
        const mapBounds = that.map.getBounds();
        const {lat, lng} = mapBounds.getCenter();

        this.props.setMapData(zoom, lat, lng);
        
        this.props.search(true, zoom, lat, lng, this.activeRequest.signal)
            .catch(err => {
                if (err.name !== "AbortError") {
                    throw err;
                }
            })
        ;
    }

    clearMap = () => {
        // clear the abundance layer if it exists
        if (this.layers.abundanceLayer) {
            this.map.removeLayer(this.layers.abundanceLayer);
        }

        // if we have a bloodmeal host layer
        if (this.layers.bloodmealHostLayer) {
            // loop through each layergroup
            for (const name in this.layers.bloodmealHostLayer) {
                // remove the layergroup from the map
                this.map.removeLayer(this.layers.bloodmealHostLayer[name]);
            }
        }

        if (this.layers.insecticideResistanceLayer) {
            // loop through each layergroup
            for (const name in this.layers.insecticideResistanceLayer) {
                // remove the layergroup from the map
                this.map.removeLayer(this.layers.insecticideResistanceLayer[name]);
            }
        }

        if (this.layers.pathogenDetectionLayer) {
            // loop through each layergroup
            for (const name in this.layers.pathogenDetectionLayer) {
                // remove the layergroup from the map
                this.map.removeLayer(this.layers.pathogenDetectionLayer[name]);
            }
        }

        // reset the variables
        this.layers.pathogenDetectionLayer = [];
        this.layers.insecticideResistanceLayer = [];
        this.layers.bloodmealHostLayer = [];
        this.layers.abundanceLayer = false;
        this.hiddenLayers = [];
    }

    createAbundanceLayer = coords => {
        const markers = [];
        
        coords.forEach(coord => {
            let marker;

            if (coord['count'] && coord['count'] > 1) {
                marker = this.createCountMarker(coord['centre'][0], coord['centre'][1], coord['count']);
            } else {
                marker = this.createCircleMarker(coord['centre'][0], coord['centre'][1], '#ff7442')

                marker.bindPopup('Loading');
                marker.on('click', (e) => {
                    Api.markerPopup({ id: e.target.id, mapType: this.props.mapType })
                    .then(response => {
                        const popup = e.target.getPopup();

                        popup.setContent(response.markup);
                        popup.update();
                    })
                });

                marker.id = coord['id'];
            }

            markers.push(marker);
        });

        this.layers.abundanceLayer = new window.L.LayerGroup(markers);
        this.map.addLayer(this.layers.abundanceLayer);
    }

    createBloodmealHostLayer = coords => {
        let markerCollections = {counts: []};

        coords.forEach(coord => {
            if (coord['count'] && coord['count'] > 1) {
                const countMarker = this.createCountMarker(coord['centre'][0], coord['centre'][1], coord['count']);
                markerCollections.counts.push(countMarker);
            } else {
                for (const property in coord['data']) {
                    const marker = this.createMultiColouredCircleMarker(
                        coord['centre'][0], 
                        coord['centre'][1],
                        this.getMarkerColour(property, 'primaryColor'),
                        this.getMarkerColour(property, 'secondaryColor')
                    );

                    marker.bindPopup('Loading');
                    marker.on('click', (e) => {
                        Api.markerPopup({ id: e.target.id, mapType: this.props.mapType, property })
                        .then(response => {
                            const popup = e.target.getPopup();
    
                            popup.setContent(response.markup);
                            popup.update();
                        })
                    });

                    marker.id = coord['id'];

                    if (!markerCollections[property]) {
                        markerCollections[property] = [];
                    }

                    markerCollections[property].push(marker);
                }
            }
        });

        for (const name in markerCollections) {
            const layerGroup = new window.L.LayerGroup(markerCollections[name]);
            this.layers.bloodmealHostLayer[name] = layerGroup;
            this.map.addLayer(layerGroup);
        }
    }

    createInsecticideResistanceLayer = coords => {
        let markerCollections = {counts: [], 'No Data': []};

        coords.forEach(coord => {
            if (coord['count'] && coord['count'] > 1) {
                const countMarker = this.createCountMarker(coord['centre'][0], coord['centre'][1], coord['count']);
                markerCollections.counts.push(countMarker);
            } else {
                const groupingProperty = coord['data'];
                const marker = this.createMultiColouredCircleMarker(
                    coord['centre'][0], 
                    coord['centre'][1],
                    this.getMarkerColour(groupingProperty, 'primaryColor'),
                    this.getMarkerColour(groupingProperty, 'secondaryColor')
                );

                marker.bindPopup('Loading');
                marker.on('click', (e) => {
                    Api.markerPopup({ id: e.target.id, mapType: this.props.mapType })
                    .then(response => {
                        const popup = e.target.getPopup();

                        popup.setContent(response.markup);
                        popup.update();
                    })
                });

                marker.id = coord['id'];

                if (!groupingProperty) {
                    markerCollections['No Data'].push(marker);
                }
 
                if (!markerCollections[groupingProperty]) {
                    markerCollections[groupingProperty] = [];
                }

                markerCollections[groupingProperty].push(marker);
            }
        });

        for (const name in markerCollections) {
            const layerGroup = new window.L.LayerGroup(markerCollections[name]);
            this.layers.insecticideResistanceLayer[name] = layerGroup;
            this.map.addLayer(layerGroup);
        }
    }

    createPathogenDetectionLayer = coords => {
        let markerCollections = {counts: [], 'No Data': []};

        coords.forEach(coord => {
            if (coord['count'] && coord['count'] > 1) {
                const countMarker = this.createCountMarker(coord['centre'][0], coord['centre'][1], coord['count']);
                markerCollections.counts.push(countMarker);
            } else {
                const label = coord['data']?.['label'];
                const colour = coord['data']?.['colour'];
                const infected = coord['data']?.['infected'];

                if (colour && label) {
                    this.markerColours['pathogen-detection'][label] = { 'primaryColor': colour, filter: false };
                }

                const marker = this.createMultiColouredCircleMarker(
                    coord['centre'][0], 
                    coord['centre'][1],
                    this.getMarkerColour(label, 'primaryColor'),
                    this.getMarkerColour(infected, 'secondaryColor')
                );

                marker.bindPopup('Loading');
                marker.on('click', (e) => {
                    Api.markerPopup({ id: e.target.id, mapType: this.props.mapType })
                    .then(response => {
                        const popup = e.target.getPopup();

                        popup.setContent(response.markup);
                        popup.update();
                    })
                });

                marker.id = coord['id'];

                if (!label) {
                    markerCollections['No Data'].push(marker);
                } else {
                    if (!markerCollections[infected]) {
                        markerCollections[infected] = [];
                    }

                    markerCollections[infected].push(marker);
                }
            }
        });

        for (const name in markerCollections) {
            const layerGroup = new window.L.LayerGroup(markerCollections[name]);
            this.layers.pathogenDetectionLayer[name] = layerGroup;
            this.map.addLayer(layerGroup);
        }
    }

    getMarkerColour = (property, colourType) => {
        let colour;

        if (this.markerColours[this.props.mapType][property]) {
            colour = this.markerColours[this.props.mapType][property][colourType];
        } else {
            colour = '#000000';
        }

        return colour;        
    }

    createCircleMarker = (lat, long, color) => {
        return window.L.circleMarker(new window.L.LatLng(lat, long), {
            radius: 6,
            color,
        });
    }

    createMultiColouredCircleMarker = (lat, long, primaryColor, secondaryColor) => {
        const icon = window.L.mapquest.icons.circle({
            primaryColor,
            secondaryColor,
            shadow: true,
            size: 'sm',
            symbol: ''
        });

        return window.L.marker([lat, long], {
            icon,
            draggable: false
        });
    }

    createCountMarker = (lat, long, count) => {
        const pos = new window.L.LatLng(lat, long);
        const icon = window.L.divIcon({
            className: 'map-marker',
            iconSize: null,
            html: '<div class="icon">' + count + '</div>'
        });

        return window.L.marker(pos, { icon }).addTo(this.map);
    }

    toggleLayer = (layerName) => {
        let layerGroup;

        if (this.props.mapType === 'bloodmeal-host') {
            layerGroup = this.layers.bloodmealHostLayer[layerName];
        }  else if (this.props.mapType === 'insecticide-resistance') {
            layerGroup = this.layers.insecticideResistanceLayer[layerName];
        } else if (this.props.mapType === 'pathogen-detection') {
            layerGroup = this.layers.pathogenDetectionLayer[layerName];
        }

        if (layerGroup) {
            if (this.hiddenLayers.includes(layerName)) {
                this.hiddenLayers = this.hiddenLayers.filter(item => item !== layerName);
                layerGroup.addTo(this.map);
            } else {
                this.hiddenLayers.push(layerName);
                this.map.removeLayer(layerGroup);
            }
        }
    }

    loadMap = (coords) => {
        if (coords && coords.length > 0 && this.map) {
            this.clearMap();

            if (this.props.mapType === 'abundance') {
                this.createAbundanceLayer(coords);
            } else if (this.props.mapType === 'bloodmeal-host') {
                this.createBloodmealHostLayer(coords);
            } else if (this.props.mapType === 'insecticide-resistance') {
                this.createInsecticideResistanceLayer(coords);
            } else if (this.props.mapType === 'pathogen-detection') {
                this.createPathogenDetectionLayer(coords);
            }
        } else {
            this.clearMap();
        }
    }

    render() {
        if (this.props.show) {
            this.loadMap(this.props.coords);
        }

        return (
            <>
                <div className={"map-container" + (this.props.loading === true ? ' is-locked' : '')}>
                    <div id="map"></div>
                </div>

                <DataMapKey mapType={this.props.mapType} markerColours={this.markerColours} onClick={this.toggleLayer} />
            </>
        );
    }
}

export default DataHeatmapNew;
import React, { useState, useEffect, useContext } from 'react';

import Api from "../Api";

import { Link } from "react-router-dom";
import { Button, Row, Col } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faArrowRight } from "@fortawesome/pro-solid-svg-icons"

import Loading from '../components/Loading';

import ContinentSelect from "../components/ContinentSelect";

import logo from '../assets/logo.png';

const TitlePage = props => {

    const homeContent = props.homeContent[0];
    const [advertBanner, setAdvertBanner] = useState(null);
    let entryPoint = process.env.REACT_APP_API_ENTRYPOINT;

    useEffect(() => {
        Api.advertBanners(true, 'home')
            .then(data => {
                if (data.length > 0) {
                    setAdvertBanner(data[0]);
                }
                
            }, (error) => {
                console.error(error);
            })
        ;
    }, []);

    if (homeContent === null) {
        return <Loading />;
    }

    return (
        <div className="title-page p-4 p-5">
            {homeContent &&
                <>
                    <h1 className="text-primary main-title py-2 mb-2">{homeContent.pageTitle}</h1>
                    <div className="d-flex align-items-center mb-4 title-content">
                        <img className="mr-4 title-page-logo" src={logo} alt="" />
                        <p className="lead text-secondary mb-0">
                            {homeContent.pageCaption}
                        </p>
                    </div>
                    <Row className="button-wrap">
                        <Col className="pr-sm-1 py-1 py-sm-0" xs={12} sm={4}>
                            <Button
                                as={Link}
                                to={homeContent.button1Link}
                                variant="dark"
                                className="d-flex justify-content-between align-items-center"
                                block={true}
                            >
                                {homeContent.button1Label}
                                <FontAwesomeIcon icon={faArrowRight} />
                            </Button>
                        </Col>

                        <Col className="px-sm-1 py-1 py-sm-0" xs={12} sm={4}>
                            <Button
                                as={Link}
                                to={homeContent.button2Link}
                                variant="dark"
                                className="d-flex justify-content-between align-items-center"
                                block={true}
                            >
                                {homeContent.button2Label}
                                <FontAwesomeIcon icon={faArrowRight} />
                            </Button>
                        </Col>
                        <Col className="pl-sm-1 py-1 py-sm-0" xs={12} sm={4}>
                            <Button
                                as={Link}
                                to={homeContent.button3Link}
                                variant="dark"
                                className="d-flex justify-content-between align-items-center"
                                block={true}
                            >
                                {homeContent.button3Label}
                                <FontAwesomeIcon icon={faArrowRight} />
                            </Button>
                        </Col>
                    </Row>

                    <Row className="d-block d-md-none py-3 continent-select">
                        <Col>
                            <ContinentSelect data={props.data} />
                        </Col>
                    </Row>

                    <div className="home-banner-wrap">
                        {advertBanner !== null && advertBanner.url && 
                            <Row className="home-banner">
                                <Col>
                                    <div>
                                        <a href={entryPoint + '/home-banner/view/' + advertBanner.id} target="_blank" title={advertBanner.title}>
                                            <img src={entryPoint + '/media/' + advertBanner.file.filePath} alt={advertBanner.title} />
                                        </a>
                                    </div>
                                </Col>
                            </Row>
                        }
                    </div>

                    <Row className="blue-frontier">
                        <Col>
                            Created by: <a href="https://www.bluefrontier.co.uk/" target="_blank">Blue Frontier</a>
                        </Col>
                    </Row>  
                </>
            }
        </div>
    );
};

export default TitlePage;

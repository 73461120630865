import React from 'react';
import PopUp from './PopUp';
import Loading from './Loading';
import { Alert, Form, Button } from "react-bootstrap";
import axios from "axios";

class MessageUserPopUp extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            message: '',
            loading: false,
            success: null,
        }
    }

    handleInputChange = (event) => {
        const target = event.target;
        const fieldValue = target.value;
        const fieldName = target.name;

        this.setState({
            [fieldName]: fieldValue
        });
    }

    handleSubmit = (event) => {
        this.setState({ loading: true, success: null });

        const thisForm = event.currentTarget;

        event.preventDefault();
        event.stopPropagation();

        thisForm.className += " was-validated";

        if (this.state.message) {
            if (thisForm.checkValidity() !== false) {
                const FormData = require('form-data');
                const form = new FormData();

                form.append('message', this.state.message);
                form.append('user_id', this.props.user.id);

                let entryPoint = process.env.REACT_APP_API_ENTRYPOINT;

                axios.post(entryPoint + '/network/send-user-message', form, {
                    headers: {
                        authorization: 'Bearer ' + window.localStorage.getItem('token')
                    }
                })
                .then(response => {
                    this.setState({
                        message: '',
                        loading: false,
                        success: true,
                    });
                });
            } else {
                this.setState({ loading: false });
            }
        } else {
            this.setState({ success: false, loading: false });
        }
    }

    render() {
        let modalHeader = (
            <div>
                <h3>Send Message</h3>
                <p><strong>Name:</strong> {this.props.user.fullName}</p>
            </div>
            
        );

        let modalContent;

        if (this.state.success === true) {
            modalContent = (
                <Alert className="mb-4" variant="success" onClose={() => (this.setState({success: null}))}>
                    <Alert.Heading>Success!</Alert.Heading>
                    <h4>Your message has been sent.</h4>
                </Alert>
            );
        } else {
            modalContent = (
                <Form noValidate className="message-form my-auto needs-validation" onSubmit={this.handleSubmit} autoComplete="off" id="message-form">
                    <Form.Group controlId="message-form-message">
                        <Form.Control name="message" size="md" rows="5" as="textarea" value={this.state.message} onChange={this.handleInputChange} required />
                        <Form.Control.Feedback type="invalid">
                            Please provide a message.
                        </Form.Control.Feedback>
                    </Form.Group>
    
                    <Button className="mx-0 mt-5 mb-4 font-smooth text-white"
                        variant="ternary"
                        size="md"
                        type="submit"
                        block
                    >
                            {this.state.loading === true && 
                                <Loading size="2x"  />
                            }
                            {this.state.loading === false && 
                                <>Send Message</>
                            }
                    </Button>
                </Form>
            );
        }

        return (
            <PopUp size="lg" className="message-modal" header={modalHeader} content={modalContent} open={this.props.show} close={this.props.close} />
        );
    }
}

export default MessageUserPopUp;
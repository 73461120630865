import React from 'react';
import { withRouter } from "react-router";
import { Link} from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faPencilAlt, faSignOutAlt, faStar, faUserCircle, faKey, faDatabase, faUniversity, faIdBadge, faEnvelope, faSearch, faUsers, faCode } from "@fortawesome/pro-solid-svg-icons";

class AccountHome extends React.Component {

    constructor(props) {
        super(props);

        this.handleLogout = this.handleLogout.bind(this);
    }

    handleLogout() {
        window.localStorage.removeItem('token');
        this.props.loginRefresh();
    }

    render () {
        let entrypoint = process.env.REACT_APP_API_ENTRYPOINT;

        return (
            <div className="account-home">
                <div className="account-home__profile">
                    <div className="account-home__profile__icon">
                        {!this.props.user.profilePicture && 
                            <FontAwesomeIcon icon={faUserCircle} className="no-image"/> 
                        }

                        {this.props.user.profilePicture &&
                            <div 
                                className="profile-picture" 
                                style={{ backgroundImage: "url(" + entrypoint + "/media/" + encodeURI(this.props.user.profilePicture.filePath) + ")" }} 
                            />
                        }
                    </div>
                    <div className="account-home__profile__info">
                        <div>
                            <div>
                                <p>Logged in as </p>
                                <span>{this.props.user.first_name} {this.props.user.surname}</span>
                            </div>
                            <div>
                                <p>Last accessed </p>
                                <span>{this.props.user.last_login}</span>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="account-home__menu">
                    <Link to="/account/update-details">
                        <FontAwesomeIcon icon={faPencilAlt} />
                        Update Account Details
                    </Link>
                    <Link to="/account/update-password">
                        <FontAwesomeIcon icon={faKey} />
                        Update Password
                    </Link>
                    <Link to="/account/update-profile">
                        <FontAwesomeIcon icon={faIdBadge} />
                        Update Profile
                    </Link>
                    <Link to="/account/favourites">
                        <FontAwesomeIcon icon={faStar} />
                        View Favourites
                    </Link>
                    <Link to="/account/saved-searches">
                        <FontAwesomeIcon icon={faSearch} />
                        Saved Searches
                    </Link>
                    <Link to="/account/institutions">
                        <FontAwesomeIcon icon={faUniversity} />
                        Manage Institutions
                    </Link>
                    <Link to="/account/collaborators">
                        <FontAwesomeIcon icon={faUsers} />
                        Manage Collaborators
                    </Link>
                    <Link to="/account/upload-data">
                        <FontAwesomeIcon icon={faDatabase} />
                        Upload Data
                    </Link>
                    <Link to="/account/api-information">
                        <FontAwesomeIcon icon={faCode} />
                        API Information
                    </Link>
                    <Link to="/account/notifications">
                        <FontAwesomeIcon icon={faEnvelope} />
                        Notifications
                        {this.props.user && this.props.user.unreadNotificationsCount > 0 &&
                            <span className="notifications-count">{this.props.user.unreadNotificationsCount}</span>
                        }
                    </Link>
                    <div className="link" onClick={this.handleLogout} to="/logout">
                        <FontAwesomeIcon icon={faSignOutAlt} />
                        Logout
                    </div>
                </div>
            </div>
        )
    }
}
    
export default withRouter(AccountHome);
import React, { useState, useEffect } from 'react';
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faExternalLink } from "@fortawesome/pro-light-svg-icons";
import Api from '../Api';

const NewsArticle = props => {
    const article = props.article;
    const [related, setRelated] = useState([]);

    useEffect(() => {
        Api.newsArticlesRelated(article.id)
            .then(data => {
                setRelated(data);
            });
    }, [article.id]);
    
    return (
        <>
            <div className="flex-grow-1">
                <div className="news-item mb-5">
                    <h3 className="news-page-heading">{article.title}</h3>
                    <h3 className="news-page-region mb-5">{article.region}</h3>

                    <div className="news-page-text"
                        dangerouslySetInnerHTML={{ __html: article.shortDescription }}
                    >
                    </div>

                    {article.url &&
                        <>
                            <FontAwesomeIcon className="text-primary mr-2" icon={faExternalLink} />
                            <a className="news-page-link" href={article.url} target="_blank" rel="noopener nofollow noreferrer">Learn More</a>
                        </>
                    }
                </div>
            </div>

            {related && related.length > 0 &&
                <div>
                    <h4 className="mb-4">Related items:</h4>
                    <ul className="related-articles-list list-inline d-flex flex-wrap">
                        {related.map((relatedArticle, index) =>
                            <li key={index} className="related-article d-flex flex-column col-md-5 mr-4 mb-4">
                                <Link className="stretched-link mt-auto mb-0" to={relatedArticle.url}>
                                    <h5>{relatedArticle.type}</h5>
                                    <h3>{relatedArticle.title}</h3>
                                </Link>
                            </li>
                        )}
                    </ul>
                </div>
            }

            <div class="mb-5">
                <Link to="/news-archive">Back to News Archive</Link>
            </div>
            
        </>
    )
};

export default NewsArticle;

import React from 'react';
import { Button } from "react-bootstrap";
import CollaboratorListItem from './CollaboratorListItem';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTrash } from "@fortawesome/pro-solid-svg-icons";

const CollaboratorList = (props) => {
    return (
        <div className="collaborators__list">
            <h3>Your Collaborators</h3>
            {props.collaborators.length === 0 &&
                <p>You're not assigned to any collaborators. You can add any collaborators you belong too below.</p>
            }
            {props.collaborators.length > 0 && 
                <>
                    {props.toDelete.length > 0 && 
                        <Button className="collaborators__delete-btn" variant="danger" onClick={props.handleDelete}>
                            <FontAwesomeIcon icon={faTrash} /> Confirm Deletion
                        </Button>
                    }

                    <p>You are currently assigned to the following collaborators:</p>

                    {props.collaborators.map((collaborator, index) => (
                        <CollaboratorListItem 
                            key={index} 
                            collaborator={collaborator} 
                            manageDelete={props.manageDelete} 
                            toDelete={props.toDelete} 
                        />
                    ))}
                </>
            }
        </div>
    );
}

export default CollaboratorList;
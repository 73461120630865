import React from 'react';
import { Row, Col, ProgressBar as Progress } from "react-bootstrap";

const ProgressBar = props => {
    const percent = 100 * props.data.received / (props.data.total || 1);
    return <Col>
        <Row>
            <Col className="px-5 d-flex justify-content-center">
                <Progress
                    className="w-50"
                    now={percent}
                    label={`${Math.floor(percent)}%`}
                    animated
                />
            </Col>
        </Row>
        <Row>
            <Col className="text-primary text-center">
                Loading globe data
            </Col>
        </Row>
    </Col>;
};

export default ProgressBar;

import React from 'react';
import { Col } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCloudDownloadAlt } from "@fortawesome/pro-solid-svg-icons";
import { faGlobe } from "@fortawesome/pro-light-svg-icons";
import { faStar } from "@fortawesome/pro-solid-svg-icons";
import { faCartPlus } from "@fortawesome/pro-solid-svg-icons";
import { faFile } from "@fortawesome/pro-light-svg-icons";

class ResourcesListItem extends React.Component {
    render() {
        let entryPoint = process.env.REACT_APP_API_ENTRYPOINT;
        let resource = this.props.resource;

        if (!resource.file && !resource.url) {
            return null;
        }
        
        let hasUrl = (resource.url ? true : false);
        let fileUrl = (hasUrl ? resource.url : entryPoint + "/resource/serve-file/" + resource.id + "/" + resource.file.filePath)

        let coverImage = false;

        if (resource.coverImage && resource.coverImage.filePath) {
            coverImage = entryPoint + '/media/' + resource.coverImage.filePath;
        }

        return (
            <Col key={`resource-${resource.id}`} xl="4" md="6" xs="12">
                <div className="filter-page-resource-item">
                    <div className={"resource-item__image" + (coverImage ? " has-image" : "" )}>
                        <a href={fileUrl} target="_blank">
                            {coverImage !== false && 
                                <>
                                    <img src={coverImage} />
                                </>
                            }

                            {coverImage === false && 
                                <div className="resource-item__no-image">
                                    <FontAwesomeIcon icon={(hasUrl ? faGlobe : faCloudDownloadAlt)} />
                                </div>
                            }
                        </a>
                    </div>
                    <div className="resource-item__details">
                        <div className="resource-item__text">
                            <div className="resource-item__list-icon">
                                <FontAwesomeIcon icon={(hasUrl ? faGlobe : faFile)} />
                            </div>
                            <div className="resource-item__title">
                                <a href={fileUrl} target="_blank">
                                    {resource.title}
                                </a>
                            </div>
                            <div className="resource-item__category">
                                {(resource.resourceCategoryTitle ? resource.resourceCategoryTitle : '' )}
                            </div>
                        </div>
                        <div className="resource-item__buttons">
                            {this.props.user && 
                                <div 
                                    className={'resource-item__button button-fav' + (this.props.isFavourite === true ? ' is-fav' : '' )} 
                                    onClick={() => this.props.onFavourite(resource.id)}
                                >
                                    <FontAwesomeIcon icon={faStar} />
                                </div>
                            }

                            {hasUrl === true && 
                                <div className="resource-item__button">
                                    <a href={resource.url} target="_blank">
                                        <FontAwesomeIcon icon={faGlobe} />
                                    </a>
                                </div>
                            }

                            {hasUrl === false && 
                                <>
                                    <div className="resource-item__button">
                                        <a href={fileUrl} target="_blank">
                                            <FontAwesomeIcon icon={faCloudDownloadAlt} />
                                        </a>
                                    </div>
                                    
                                    <div className="resource-item__button" onClick={() => this.props.onAddToBasket(resource)}>
                                        <FontAwesomeIcon icon={faCartPlus} />
                                    </div>
                                </>
                            }
                        </div>
                    </div>
                </div>
            </Col>
        );
    }
}

export default ResourcesListItem;
import React from 'react';
import SearchInput from "../SearchInput";
import FilterPageDateFilter from './FilterPageDateFilter';
import FilterPageProfileTypes from "./FilterPageProfileTypes"

class FilterPageFilters extends React.Component {
    render() {
        const entryPoint = process.env.REACT_APP_API_ENTRYPOINT;

        return (
            <div className="filter-page-filters">
                <div className="filter-page-filters__input">
                    <SearchInput 
                        placeholder="Enter a search term"
                        inputSize="md"
                        showCriteria={false}
                        onChange={this.props.onCriteriaChange}
                        ref={this.props.searchInputRef}
                        withTitleSearch={true}
                        titleSearchTitle={this.props.titleSearchTitle}
                        user={this.props.user}
                        searchType={this.props.searchType}
                    />
                </div>
                {this.props.showDateFilter === true &&
                    <div className="filter-page-filters__input filter-page-date-filters">
                        <FilterPageDateFilter 
                            setStartDate={this.props.setStartDate} 
                            setEndDate={this.props.setEndDate} 
                            onDateSubmit={this.props.onDateSubmit}
                            startDate={this.props.startDate}
                            endDate={this.props.endDate}
                        />
                    </div>
                }
                <div className="filter-page-filters__input">
                    <FilterPageProfileTypes 
                        criteria={this.props.criteria} 
                        onProfileClick={this.props.onProfileClick} 
                        isResourceIndex={this.props.isResourceIndex}
                        isDataIndex={this.props.isDataIndex}
                        isNetworkIndex={this.props.isNetworkIndex}
                        selectableIds={this.props.selectableIds}
                        additionalProfileSearch={this.props.additionalProfileSearch}
                    />
                </div>
                {this.props?.advertBanner?.file?.filePath &&
                    <div class="filter-page__advert">
                        <a 
                            href={entryPoint + '/home-banner/view/' + this.props.advertBanner.id} 
                            target="_blank"
                            title={this.props.advertBanner.title}
                        >
                            <img 
                                src={entryPoint + '/media/' + this.props.advertBanner.file.filePath} 
                                alt={this.props.advertBanner.title} 
                            />
                        </a>
                    </div>
                }
            </div>
        );
    }
}

export default FilterPageFilters;